@import '../faq';

body.page-bitcoin-lightning-network {
	h1 {
		margin-bottom: rem(8);
	}

	.section-standard {
		&__text {
			margin-bottom: rem(48);
		}
	}

	.bitcoin-ln {
		&__box {
			border-radius: rem(24);
			font-size: rem($px-extra-small);
			font-weight: $font-light;
			padding: rem(24);
			background: $color-white;
			color: $color-dark-blue-900;
			@include shadow-2();
			transition: all 0.3s ease-out;
			gap: rem(8);

			&:hover {
				@include shadow-24();
			}

			&:active {
				box-shadow: none;
			}

			.box {
				&__img,
				&__text {
					width: 100%;
				}

				&__img {
					text-align: center;
					padding-bottom: rem(8);
					border-bottom: 1px solid $color-grey-100;
				}
			}
		}

		&__title {
			font-size: rem($px-small);
			font-weight: $font-semibold;
			margin: 0;
			line-height: 32px;
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: rem(18);
			width: 100%;

			> div {
				&:nth-child(2) {
					font-weight: $font-semibold;
					font-family: $font-poppins;
					font-size: rem($px-small);
				}
			}

			&--button {
				justify-content: center;
				margin-bottom: 0;
				padding-top: rem(16);
			}
		}

		&__text {
			width: 100%;
		}

		&__info-box {
			border-radius: rem(16);
			padding: rem(24);
			background: $color-grey-100;
			margin: 0;
			overflow: auto;
		}

		&__long-description-section {
			transition: all 0.7s;
			max-height: rem(4000);
			opacity: 1;
			overflow: hidden;

			&.hidden {
				max-height: 0;
				opacity: 0;
			}

			.section-white {
				margin-top: rem(24);
			}
		}

		&__btn-wrapper {
			text-align: center;
			margin: rem(24 0);
		}

		&__read-more-btn {
			&.active {
				svg {
					transform: rotate(180deg);
				}

				.bitcoin-ln {
					&__read-more-text {
						display: none;
					}

					&__close-text {
						display: inline-flex;
					}
				}
			}
		}

		&__close-text {
			display: none;
		}
	}

	.section-faq {
		&__heading {
			margin-bottom: rem(16);
		}
	}
}

@import '../select';
@import '../partials/filters-modal';

body.page-payments .main {
	img {
		max-width: 100%;
		max-height: 100%;
	}

	[hidden],
	.display-none {
		display: none !important;
	}

	.chips {
		text-transform: uppercase;
	}

	.payments-heading {
		.section-standard {
			&__img {
				max-width: rem(150 + 35 * 2);

				@include device((tablet-up)) {
					margin-left: rem(75);
					padding: rem(50 35 0 0);
					max-width: rem(400 + 75 * 2);
				}
			}
		}
	}

	.payments-methods {
		text-align: center;

		@include device((tablet-up)) {
			text-align: left;
			margin-bottom: 0;
		}

		&__img {
			align-self: center;
			max-width: rem(150 + 35 * 2);

			@include device((tablet-up)) {
				max-width: rem(255);
				min-width: rem(255);
			}
		}

		&__multicurrency-accounts {
			display: grid;
			grid-gap: rem(16);
			justify-items: center;

			@include device((tablet-up)) {
				grid-template-columns: 40px 1fr;
				margin-top: rem(32);
				justify-items: initial;
			}
		}
	}

	.payments-table {
		display: grid;
		grid-gap: 8px;

		&:not(.tab__content--active) {
			display: none;
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}

		&--withdrawals {
			@include device((desktop-up)) {
				.payments-table {
					&__methods-details,
					&__methods-header {
						grid-template-columns: 1fr repeat(3, 125px) 115px 170px 110px;
					}
				}
			}

			.payments-table__cell {
				&--currency {
					grid-row-start: 3;

					@include device((tablet-up)) {
						grid-row-start: unset;
					}
				}

				&--fee {
					grid-column-start: 3;
					grid-column-end: span 2;
					grid-row-start: 3;

					br {
						display: none;

						@include device((tablet-up)) {
							display: inline;
						}
					}

					&::before {
						min-height: unset !important;
					}

					@include device((tablet-up)) {
						grid-column-start: unset;
						grid-column-end: unset;
						grid-row-start: unset;
					}
				}

				&--jurisdiction {
					grid-column-start: 4;
					grid-row-start: 2;

					&::before {
						min-height: 30px;
					}

					@include device((tablet-up)) {
						grid-column-start: unset;
						grid-row-start: unset;
					}
				}
			}
		}
		&--deposits {
			@include device((desktop-up)) {
				.payments-table {
					&__methods-details,
					&__methods-header {
						grid-template-columns: 1fr repeat(3, 130px) 80px 170px 110px;
					}
				}
			}
		}

		&__groups-wrapper {
			display: grid;
			grid-gap: 8px;
		}

		&__group-details,
		&__show-details,
		&__methods-details {
			font-family: $font-poppins;
			font-weight: $font-semibold;
		}

		&__group-details {
			align-items: center;
			min-height: rem(60);
			pointer-events: none;
			justify-content: center;

			@include device((tablet-up)) {
				grid-template-columns: 330px 1fr 330px;
				justify-content: unset;
				pointer-events: unset;
			}
		}

		&__group {
			display: grid;
			padding: 16px;
			border-radius: 24px;
			background: $color-white;
			box-shadow:
				0 1px 2px 0 rgba(16, 24, 40, 0.18),
				0 2px 4px 0 rgba(16, 24, 40, 0.18);
			grid-auto-rows: auto;

			@include device((tablet)) {
				padding: 16px 8px;
			}

			@include device((desktop-up)) {
				padding: 18px 24px;
			}
		}

		&__methods {
			display: grid;
			grid-gap: rem(32);
			max-height: 0;
			overflow: hidden;
			opacity: 0;
			transition: $transition-standard;

			&.active {
				margin-top: rem(16);
			}

			@include device((tablet-up)) {
				grid-gap: rem(24);

				&.active {
					margin-top: unset;
				}
			}
		}

		&__methods-details {
			grid-template-columns: repeat(4, 1fr);
			font-size: rem(12);

			@include device((tablet-up)) {
				font-size: rem(14);
			}
		}

		&__methods-details,
		&__methods-header,
		&__group-details {
			display: grid;
			grid-gap: 10px;

			@include device((tablet)) {
				grid-gap: 2px;
			}

			@include device((desktop-up)) {
				grid-gap: 5px;
			}
		}

		&__methods-details,
		&__methods-header {
			@include device((tablet-up)) {
				align-items: center;

				div {
					&:first-child {
						padding-left: rem(16);
					}

					&:last-child {
						padding-right: rem(16);
					}
				}
			}
		}

		&__methods-header {
			display: none;
			font-size: rem(12);
			color: $color-grey-600;
			font-weight: $font-light;

			@include device((tablet-up)) {
				display: grid;
			}
		}

		&__show-details {
			display: none;
			grid-gap: rem(8);
			grid-template-columns: auto 20px;
			color: $color-primary-blue-500;
			justify-content: center;

			@include device((tablet-up)) {
				justify-content: flex-end;
			}

			.chevron {
				&::before,
				&::after {
					background: $color-primary-blue-500;
				}
			}

			&--mobile {
				display: inline-grid;
				padding-top: rem(16);

				@include device((tablet-up)) {
					display: none;
				}
			}

			&--desktop {
				display: none;

				@include device((tablet-up)) {
					display: inline-grid;
				}
			}
		}

		&__cell {
			text-align: left;

			&::before {
				display: block;
				content: attr(data-header);
				font-size: rem(10);
				font-weight: $font-regular;
				color: $color-grey-600;
				line-height: 1.5;
			}

			&:nth-child(-n + 5)::before {
				min-height: 30px;
			}

			@include device((tablet-up)) {
				text-align: center;

				&::before {
					display: none;
				}
			}

			&--method-name {
				grid-column-start: 1;
				grid-column-end: span 4;
				font-size: rem(20);
				text-align: left;

				&::before {
					display: none;
				}

				@include device((tablet)) {
					font-size: rem(18);
				}

				@include device((tablet-up)) {
					grid-column-start: unset;
					grid-column-end: unset;
				}
			}

			&--currency {
				grid-column-start: 1;
				grid-column-end: span 2;

				@include device((tablet-up)) {
					grid-column-start: unset;
					grid-column-end: unset;
				}
			}

			&--group-name {
				display: grid;
				align-items: center;
				grid-gap: rem(8);
				order: 1;
				max-width: 150px;
				justify-self: center;

				img {
					width: 150px;
				}

				@include device((tablet-up)) {
					grid-gap: rem(12);
					grid-template-columns: 150px 1fr;
					order: unset;
					max-width: unset;
					justify-self: unset;

					img {
						width: unset;
					}
				}
			}
		}

		&__wrapper {
			border-radius: 0 0 16px 16px;
			background: $color-white;
			padding: rem(16);
			box-shadow:
				0 12px 24px 6px rgba(16, 24, 40, 0.08),
				0 24px 48px 6px rgba(16, 24, 40, 0.08);
			margin-bottom: rem(70);

			@include device((tablet-up)) {
				padding: rem(24);
			}
		}

		&__header {
			display: grid;
			align-items: center;
			grid-gap: rem(16);
			font-size: rem(24);
			font-weight: $font-semibold;
			font-family: $font-poppins;
			margin-bottom: rem(32);
			margin-top: rem(8);
			line-height: 1;

			@include device((tablet-up)) {
				grid-template-columns: 1fr 1fr;
				margin-top: unset;
				font-size: rem(32);
			}
		}

		&__methods-number-wrapper {
			display: flex;
			gap: rem(15);
			align-items: baseline;
			justify-content: space-between;
			font-size: rem(14);
			color: $color-grey-600;
			margin: rem(40 14 20);

			@include device((tablet-up)) {
				margin: rem(50 14 20);
			}

			.btn {
				min-width: unset;
				width: unset;
				padding: rem(12 30);

				@include device((tablet-up)) {
					display: none;
				}

				svg {
					margin-right: rem(10);
				}
			}

			.payments-table__sort-by {
				display: none;

				@include device((tablet-up)) {
					display: block;
				}
			}
		}

		&__search-wrapper {
			justify-self: start;
			width: 100%;

			@include device((tablet-up)) {
				margin-bottom: rem(40);
				min-width: rem(405);
				width: unset;
			}
		}

		&__filters {
			display: grid;
			grid-gap: rem(8);
			margin-top: rem(8);

			@include device((tablet-up)) {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		&__filters-wrapper {
			display: none;

			@include device((tablet-up)) {
				display: block;
			}

			&--open-modal {
				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				display: block;
				background-color: $color-grey-100;
				padding: rem(24);
				z-index: z-index(paymentsModal);
			}
		}

		&__chips-filters {
			display: flex;
			flex-flow: wrap;
			gap: rem(15);
			min-height: rem(27);

			&--title {
				font-size: rem(20);
				font-family: $font-poppins;
				font-weight: $font-semibold;
				text-transform: uppercase;
				order: -2;
			}

			&--clear-all {
				@extend %chips--filter;
				border: none;
				padding: rem(4 16 4 30);
				font-size: rem(12);
				font-weight: $font-semibold;
				font-family: $font-poppins;
				text-transform: initial;
				order: -1;

				&::before,
				&::after {
					left: 10px;
				}
			}

			&--title,
			&--clear-all {
				width: calc(50vw - 50px);

				@include device((tablet-up)) {
					width: unset;
					order: unset;
				}
			}
		}

		&__sort-by {
			.placeholder {
				color: $color-dark-blue-900;
			}
		}

		&__filter-btn[data-active-filters] {
			&::after {
				content: '(' attr(data-active-filters) ')';
				margin-left: rem(5);
			}
		}

		.pagination__wrapper {
			max-width: 100%;
			overflow: hidden;
		}
	}

	.accordion {
		cursor: pointer;

		&__panel.active {
			opacity: 1;
		}

		&.active {
			.chevron {
				transform: rotate(180deg);
			}
		}
	}

	.payments {
		&__note {
			font-size: rem(14);
			margin: rem(24 0);
			font-weight: $font-light;
		}
	}

	.tab {
		&__wrapper {
			justify-content: flex-start;
			align-items: flex-end;
			overflow: unset;
		}

		&__item {
			&--active,
			&:hover {
				position: relative;

				&::after {
					content: '';
					position: absolute;
					background: $color-white;
					height: 15px;
					width: 100%;
					top: calc(100% - 3px);
				}
			}
		}
	}

	.payments-modal {
		display: none;

		@include device((tablet-up)) {
			display: block;

			.payments-table__sort-by {
				display: none;
			}
		}

		&--open {
			@include modal-open();

			.payments-modal__btn-wrapper {
				display: block;
			}

			.payments-table__filters,
			.payments-table__chips-filters {
				padding: 0;
			}

			.payments-table__sort-by .custom-select {
				&__wrapper {
					margin-bottom: rem(15);
				}

				&__value {
					border: 0;
				}

				&__value,
				&__list {
					padding-left: 0;
					padding-right: 0;
				}

				&__list {
					position: relative;
					background: none;
					box-shadow: none;
				}
			}

			.payments-table__filters {
				.chevron {
					display: none;
				}

				@include modal-select-reset();
			}
		}

		&__btn-wrapper {
			display: none;
		}

		@include device((mobile, large-mobile)) {
			&__close {
				@include modal-close();
			}

			&__content {
				@include modal-content();
				overflow-y: scroll;
			}

			&__btn-wrapper {
				padding: rem(24);
			}
		}
	}

	#payments-table {
		margin-top: 0;
		padding-top: rem(80);

		@include device((tablet-up)) {
			padding-top: rem(100);
			margin-bottom: rem(24);
		}
	}

	.earn-link {
		pointer-events: auto;

		@include device((tablet-up)) {
			pointer-events: none;
		}
	}

	.custom-select {
		&__search {
			padding: rem(0 16);
			margin-bottom: rem(16);
		}

		&__list-title {
			display: flex;
			justify-content: space-between;

			@include device((tablet-up)) {
				display: block;
			}

			> .chevron {
				display: block !important;

				@include device((tablet-up)) {
					display: none !important;
				}
			}

			&.open {
				.chevron {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}

	.payments-filters {
		&__select-title {
			align-items: center;
			margin-bottom: rem(0);
			transition: $transition-standard;

			&.active {
				margin-bottom: rem(16);
			}
		}

		&__select-list {
			overflow-y: hidden;
			display: flex;
			flex-direction: column;

			@include device((mobile, large-mobile)) {
				max-height: 0;
				opacity: 0;
				pointer-events: none;
				transition: $transition-standard;
			}

			&.active {
				pointer-events: auto;
				opacity: 1;
			}
		}
	}
	.payments-chips {
		&__methods {
			display: inline-block;
		}
	}
}

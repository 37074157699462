$logoSize: 24px;

.instruments-table {
	$parent: &;

	@include device((tablet-up)) {
		min-height: 450px;
	}

	&__header,
	&__item {
		display: grid;
		grid-template-columns: 0.4fr 1fr 1fr 1fr;
		padding: rem(12 8 12 16);

		@include device((tablet-up)) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__header {
		font-family: $font-poppins;
		font-weight: $font-regular;
		font-size: rem($px-extra-small);
		color: $color-grey-400;
		padding-top: rem(8);
		padding-bottom: rem(8);

		> div {
			&:first-child {
				display: flex;
				align-items: center;
				visibility: hidden;
				overflow: hidden;

				@include device((tablet-up)) {
					visibility: visible;
				}
			}
		}

		#{$parent} {
			&__price,
			&__change {
				font-weight: $font-regular;
			}
		}
	}

	&__item {
		font-weight: $font-medium;
		color: $color-dark-blue-900;
		font-size: rem($px-small);
		border-bottom: 1px solid $color-grey-100;
		transition: all 0.3s ease-in;

		&.search-display-none,
		&.pagination-display-none {
			display: none;
		}

		div {
			&:last-child {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		&:hover {
			#{$parent} {
				&__price {
					color: $color-dark-blue-900;
				}
			}
		}

		&:hover {
			border-bottom: 1px solid $color-grey-50;
			background: $color-grey-50;
		}

		&:active {
			border-bottom: 1px solid $color-grey-100;
		}
	}

	&__symbol {
		display: flex;
		align-items: center;

		a {
			display: grid;
			align-items: center;
			grid-gap: rem(8);
			grid-template-columns: $logoSize minmax(max-content, auto);

			&:hover,
			&:active {
				#{$parent} {
					&__symbol-name {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__symbol-name {
		color: $color-dark-blue-900;
		display: none;

		@include device((tablet-up)) {
			display: inline-block;
		}
	}

	&__instrument-logo {
		position: relative;
		width: $logoSize;
		height: $logoSize;

		.instrument-logo {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scale(#{scaleLogo($logoSize)});
		}
	}

	&__price {
		font-weight: $font-semibold;
		text-align: center;
	}

	&__change {
		text-align: center;
		font-weight: $font-semibold;
	}

	&__button {
		svg {
			margin-left: rem(3);

			@include rightToLeft {
				margin-right: rem(3);
				transform: scaleX(-1);
			}
		}

		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			text-align: right;
			font-size: rem($px-extra-small);
			font-weight: $font-medium;
			line-height: 110%;
			color: $color-primary-blue-500;

			@include device((tablet-up)) {
				white-space: nowrap;
				padding-left: rem(30);
			}

			&:hover {
				color: $color-primary-blue-600;

				path {
					stroke: $color-primary-blue-600;
				}
			}

			&:active {
				color: $color-primary-blue-700;

				path {
					stroke: $color-primary-blue-700;
				}
			}
		}
	}

	&__trade-text {
		display: block;

		@include rightToLeft {
			text-align: left;
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tab {
	&__wrapper {
		display: flex;
		align-items: flex-end;
		margin: 0;
		overflow: auto;
	}

	&__item {
		text-align: center;
		font-family: $font-poppins;
		font-weight: $font-semibold;
		font-size: rem($px-small);
		color: $color-white;
		padding: rem(10);
		cursor: pointer;
		white-space: nowrap;
		border-radius: 12px 12px 0 0;
		background: $color-dark-blue-900;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: rem(50);
		height: rem(36);
		transition:
			background 0.1s ease-out,
			color 0.2s ease-out;

		@include device((tablet-up)) {
			height: rem(40);
			padding: rem(12 14);
		}

		svg {
			width: 16px;
			height: 16px;
		}

		&:hover,
		&--active {
			padding: rem(8 16);
			height: rem(44);
			background: $color-white;
			box-shadow: 4px 2px 8px 0 rgba(0, 0, 0, 0.15);
			color: $color-primary-blue-500;

			svg {
				width: 20px;
				height: 20px;
			}

			@include device((tablet-up)) {
				padding: rem(8 14);
				height: rem(50);
			}
		}
	}

	&__content {
		display: none;

		&--active {
			display: block;
		}
	}
}

.footer {
	background: $color-dark-blue-900;
	font-size: rem($px-small);
	color: $color-white;
	line-height: 1.2;
	padding: rem(48 0);

	&--iframe {
		padding: 0;
		margin: 0;
	}

	&__logo {
		width: rem(155);
		margin: 0;

		img {
			width: 100%;
		}
	}

	&__column-wrapper {
		@include device((tablet-up)) {
			display: flex;
		}
	}

	&__left-column {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__platforms {
		margin-top: rem(16);
	}

	&__platforms-links {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: flex-start;
		gap: rem(16);
		transition: 0.35s ease all;

		a {
			position: relative;
			transition: $transition-standard;

			&:hover {
				transform: scale(1.1);
			}

			&:active {
				path {
					fill: $color-grey-25;
				}
			}
		}
	}

	&__platforms-text,
	&__menu-block-title,
	&__app-title {
		font-family: $font-poppins;
		font-weight: $font-semibold;
	}

	&__platforms-text,
	&__app-title {
		margin-bottom: rem(8);
		line-height: 2;
	}

	&__platforms-text {
		&--community {
			margin-top: rem(24);
		}
	}

	&__app {
		margin-top: rem(24);

		@include device((tablet-up)) {
			margin-top: 0;
		}
	}

	&__app-buttons {
		display: flex;
		gap: rem(8);

		@include device((small-mobile, mobile, large-mobile, tablet)) {
			.app-store-button,
			.google-play-button {
				svg {
					width: 67px;
				}

				rect {
					width: 65px;
				}

				.app-store-button-text,
				.google-button-text {
					display: none;
				}
			}
		}
	}

	&__menu {
		margin-top: rem(24);

		@include device((tablet-up)) {
			margin-top: rem(16);
			display: grid;
			grid-gap: 16px;
			grid-template-columns: repeat(2, 1fr);
		}

		@include device((desktop-up)) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__menu-block-title {
		padding: rem(8 10);
		color: $color-grey-100;
		margin-bottom: 0;

		@include device((tablet-up)) {
			margin-bottom: rem(8);

			.chevron {
				display: none;
			}
		}

		&:hover {
			color: $color-grey-25;
			text-decoration: underline;
		}

		&:active {
			color: $color-grey-300;
			text-decoration: underline;
		}

		&--mobile {
			@include device((tablet-up)) {
				display: none !important;
			}
		}

		&--desktop {
			display: none;

			@include device((tablet-up)) {
				display: block;
			}
		}
	}

	&__menu-item {
		display: block;
		font-size: rem($px-extra-small);
		font-family: $font-poppins;
		font-weight: $font-medium;
		padding: rem(6 10);
		margin-bottom: rem(4);
		color: $color-grey-100;

		&:hover {
			color: $color-grey-25;
			text-decoration: underline;

			path {
				stroke: $color-grey-25;
			}
		}

		&:active {
			color: $color-grey-300;
			text-decoration: underline;

			path {
				stroke: $color-grey-300;
			}
		}

		&.disabled {
			cursor: not-allowed;
			pointer-events: none;
			opacity: 0.8;
		}

		&.new-window {
			svg {
				margin-left: rem(4);
			}
		}

		&--mobile {
			color: $color-white !important;

			svg {
				stroke: $color-white !important;
			}

			@include device((tablet-up)) {
				display: none !important;
			}
		}
	}

	&__services {
		font-weight: $font-light;
		color: $color-grey-200;
		font-size: rem($px-extra-small);
		overflow: hidden;
	}

	&__copyright {
		font-weight: $font-light;
		color: $color-grey-100;
	}

	&__divider {
		border-top: 1px solid $color-grey-300;

		&--top {
			margin: rem(24 0 12);
		}

		&--bottom {
			margin: rem(12 0 24);
		}
	}

	.collapsible {
		border-bottom: 1px solid $color-grey-600;
		padding-bottom: rem(8);
		margin-bottom: rem(8);

		@include device((tablet-up)) {
			border-bottom: 0;
		}

		&:last-child {
			border-bottom: 0;
		}

		&__toggle {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include device((tablet-up)) {
				pointer-events: none;
				cursor: default;

				.chevron {
					display: none;
				}
			}
		}

		&__content {
			display: grid;
			padding: rem(0 16);

			@include device((tablet-up)) {
				max-height: unset;
				display: block;
				padding: 0;
			}

			&.active {
				margin-top: rem(8);
			}
		}
	}

	.wrapper {
		padding-top: 0;
		padding-bottom: 0;
	}

	#footer-iframe {
		width: 100%;
	}
}

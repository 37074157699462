@import './variables';
@import './partials/section-download-footer';

*,
::after,
::before {
	box-sizing: border-box;
}

body {
	font-family: $font-roboto;
	font-size: #{$px-normal}px;
	font-weight: $font-regular;
	font-variant-ligatures: none;
	background: rgb(244, 244, 246); // #F4F4F6
	color: $color-dark-blue-900;
	line-height: 140%;
	margin: 0;

	&.mobile-menu-opened {
		position: fixed;
		overflow: hidden;
		max-width: 100vw;
	}

	&.scroll-top-hidden {
		.livechat {
			&__wrapper {
				bottom: 16px;
				transition: all 0.2s ease-out;
			}
		}
	}

	&.signup-disabled {
		.livechat {
			&__wrapper {
				z-index: z-index(livechatWithGeoBlocking);
			}
		}
	}
}

.main {
	position: relative;
	overflow: hidden;
	padding-top: rem($grid-padding);
	margin-bottom: rem(81);

	@include device((tablet-up)) {
		margin-bottom: rem(118);
	}
}

.wrapper {
	margin: 0 auto;
	position: relative;
	max-width: 100%;
	padding-left: $grid-padding;
	padding-right: $grid-padding;

	@include device((tablet-up)) {
		padding-left: $tablet-side-padding;
		padding-right: $tablet-side-padding;
	}

	@include device((large-desktop)) {
		padding-left: 0;
		padding-right: 0;
		max-width: 1240px;
	}
}

.section-standard {
	$parent: &;

	display: flex;
	flex-direction: column-reverse;
	border-radius: rem(24);
	background: $color-white;
	padding: 0;
	margin-bottom: rem(24);
	overflow: hidden;

	@include device((tablet-up)) {
		flex-direction: row;
	}

	&__content {
		padding: rem(0 22 24 22);

		@include device((tablet-up)) {
			flex: 7;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
			padding: rem(70 0 70 104);

			@include rightToLeft {
				padding: rem(70 104 70 0);
			}
		}
	}

	&__img {
		picture {
			display: flex;
			justify-content: left;
		}

		@include device((tablet-up)) {
			flex: 5;
			display: flex;
			align-items: flex-end;
			justify-content: right;
		}

		&:not(.section-standard__img--no-flip) {
			@include rightToLeft {
				transform: scaleX(-1);
			}
		}

		&--ltr {
			@include rightToLeft {
				display: none;
			}
		}

		&--rtl {
			transform: scaleX(-1);

			@include leftToRight {
				display: none;
			}
		}
	}

	&__text {
		color: $color-grey-900;
		margin-bottom: rem(24);
	}

	h1,
	h2 {
		color: $color-grey-900;
		display: inline-block;
		margin-top: 0;

		&:first-letter {
			text-transform: uppercase;
		}

		span {
			background-image: $gradient-blue-green;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
		}
	}

	&__subheader {
		font-family: $font-poppins;
		font-size: rem(18);
		font-weight: $font-semibold;
		margin-bottom: rem(24);
		line-height: 120%;
	}

	p {
		margin-top: 0;
	}

	&--reverse {
		@include device((tablet-up)) {
			flex-direction: row-reverse;
		}

		#{$parent} {
			&__content {
				@include device((tablet-up)) {
					padding-left: 0;
					padding-right: rem(75);

					@include rightToLeft {
						padding-left: rem(75);
						padding-right: 0;
					}
				}
			}

			&__img {
				@include device((tablet-up)) {
					justify-content: left;
				}
			}
		}
	}

	&--dark {
		background: $color-dark-blue-800;

		#{$parent} {
			&__text {
				color: $color-grey-25;
			}
		}

		h1,
		h2 {
			span {
				background-image: linear-gradient(
					96deg,
					#20b2dd 68.84%,
					#cbf3ff 82.96%,
					#9eff00 98.05%
				);
			}
		}
	}

	&--gradient {
		background: $gradient-primary-blue;

		#{$parent} {
			&__text {
				color: $color-white;
			}
		}

		h1,
		h2 {
			span {
				background-image: $gradient-blue-white-green;
			}
		}
	}

	&--dark,
	&--gradient {
		#{$parent} {
			&__content {
				h1,
				h2 {
					color: $color-white;
				}
			}
		}
	}

	&--with-subtitle {
		h1,
		h2 {
			margin-bottom: rem(8);
		}
	}

	&--last {
		margin-bottom: 0;
	}

	&--with-margin {
		margin: rem(64 0 32);

		@include device((tablet-up)) {
			margin: rem(100 0 50);
		}
	}
}

.section-row {
	display: flex;
	flex-direction: column;
	gap: 64px;

	@include device((tablet-up)) {
		flex-direction: row;
	}

	&__content {
		@include device((tablet-up)) {
			flex: 3;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
		}

		&--half {
			flex: 2;
		}
	}

	&__img,
	&__link,
	&__buttons {
		@include device((tablet-up)) {
			flex: 1;
		}
	}
}

.section-title {
	margin: rem(0 0 16);
	font-size: rem(32);

	@include device((tablet-up)) {
		font-size: rem(40);
		margin-bottom: rem(24);
	}
}

.section-white {
	border-radius: 24px;
	background: $color-white;
	padding: rem(64 24);
	margin: rem(64 0 32);

	@include device((tablet-up)) {
		padding: rem(64 50);
		margin: rem(100 0 50);
	}

	&--no-margin {
		margin: 0;
	}
}

.section-transparent {
	margin: rem(64 0 32);

	@include device((tablet-up)) {
		padding: rem(0 50);
		margin: rem(100 0 50);
	}
}

.section-content {
	margin: rem(64 0 32);

	@include device((tablet-up)) {
		margin: rem(100 0 50);
	}

	&--no-bottom-margin {
		margin-bottom: 0;
	}

	&--with-padding {
		padding: rem(48 0);

		@include device((tablet-up)) {
			padding: rem(64 50);
		}
	}

	&--with-vertical-padding {
		padding: rem(48 0);

		@include device((tablet-up)) {
			padding: rem(64 0);
		}
	}

	&--reverse {
		display: flex;
		flex-direction: column-reverse;

		@include device((tablet-up)) {
			flex-direction: column;
		}

		.section-row {
			flex-direction: column;

			@include device((tablet-up)) {
				flex-direction: row-reverse;
			}
		}
	}
}

.chips,
%chips {
	display: inline-flex;
	padding: rem(4 8);
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: $color-dark-blue-900;
	font-family: $font-poppins;
	font-size: rem($px-extra-small);
	font-style: normal;
	font-weight: $font-medium;
	line-height: normal;
	border-radius: 4px;
	background: $color-grey-200;

	&--with-margin {
		margin-bottom: rem(16);
	}

	&__wrapper,
	&--lime {
		&:has(+ h2, + h3, + h4, + h5) {
			margin-bottom: rem(16);
		}

		+ h2,
		+ h3,
		+ h4,
		+ h5 {
			margin-top: 0 !important;
		}
	}

	&__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&--lime {
		border-radius: 8px;
		background: $color-lime-green-200;
		font-weight: $font-regular;
		letter-spacing: 0.24px;
	}

	&--light-blue {
		background: $color-primary-blue-100;
	}

	&--filter {
		display: flex;
		gap: rem(8);
		padding: rem(4 30 4 16);
		border-radius: rem(20);
		border: 1px solid $color-primary-blue-500;
		color: $color-primary-blue-500;
		font-size: rem($px-extra-small);
		font-weight: $font-medium;
		font-family: $font-roboto;
		background: transparent;
		line-height: normal;
		position: relative;
		align-items: center;
		cursor: pointer;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 10px;
			display: block;
			width: 10px;
			height: 2px;
			background: $color-primary-blue-500;
		}

		&::before {
			transform: translateY(-50%) rotate(45deg);
		}

		&::after {
			transform: translateY(-50%) rotate(-45deg);
		}

		&:hover {
			border-color: $color-primary-blue-600;
			color: $color-primary-blue-600;

			&::before,
			&::after {
				background: $color-primary-blue-600;
			}
		}
	}

	&--hot {
		background: $color-red-500;
		color: $color-white;
		font-family: $font-roboto;
	}

	&--blue {
		background: $color-primary-blue-500;
		color: $color-white;
		font-family: $font-roboto;
	}

	&--lime,
	&--hot,
	&--filter,
	&--lime,
	&--blue {
		text-transform: uppercase;
	}

	&--new {
		background: $color-primary-blue-500;
		color: $color-white;
		font-family: $font-roboto;
		text-transform: uppercase;
	}
}

.box {
	display: flex;
	padding: rem(24);
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;
	border-radius: 24px;
	background: $color-dark-blue-800;
	color: $color-white;

	@include device((tablet-up)) {
		padding: rem(50);
	}

	&--white-bg {
		background: $color-white;
		color: unset;
	}

	&__wrapper {
		display: grid;
		gap: 16px;
		align-self: stretch;
		flex-wrap: wrap;
		margin: rem(24 0);

		&--small-margin {
			margin: rem(16 0);
		}

		&--no-margin {
			margin: 0;
		}

		@include device((tablet-up)) {
			grid-template-columns: repeat(3, 1fr);
		}

		&--2x {
			@include device((tablet-up)) {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&--with-right-wide-box {
			@include device((tablet-up)) {
				grid-template-columns: calc((100% / 3) - 11px) auto;
			}
		}

		&--with-left-wide-box {
			@include device((tablet-up)) {
				grid-template-columns: auto calc((100% / 3) - 10px);
			}
		}
	}

	&__title {
		@include headers-h5();
		font-size: rem(24);
	}

	&__content {
		display: grid;
		grid-gap: rem($grid-padding);

		p {
			margin: 0;
		}
	}

	&__description {
		margin: 0;
	}

	&__number {
		font-size: rem(56);
		line-height: 1;
		font-weight: $font-semibold;

		&--big {
			@include device((tablet-up)) {
				font-size: rem(74);
			}
		}
	}
}

.list {
	&--lime {
		list-style-type: none;
		padding: 0;

		li {
			position: relative;
			padding-left: rem(20);

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 6px;
				border-radius: 50%;
				background: $color-lime-green-200;
				height: 8px;
				width: 8px;
			}
		}
	}
}

.custom-scroll {
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		background: $color-grey-300;
		border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		background: $color-primary-blue-500;
		border-radius: 100px;

		&:hover {
			background: $color-primary-blue-600;
		}
	}
}

.section-animated {
	transition:
		opacity 850ms ease 150ms,
		transform 850ms ease 0s;
	opacity: 0;
	transform: translateY(75px);

	&.section-content,
	&.section-white {
		&:not(.active) {
			margin-top: 0;

			@include device((tablet-up)) {
				margin-top: rem(25);
			}
		}
	}

	&.active {
		opacity: 1;
		transform: translateY(0);
	}
}

.no-search-data {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: rem(16);
	color: $color-grey-400;
	font-family: $font-roboto;
	padding: rem(24 0 32);
	text-align: center;
	line-height: 1.4;

	@include device((tablet-up)) {
		padding: rem(16 0 48);
	}
}

.livechat {
	display: flex;
	align-items: center;
	gap: 0;
	border-radius: 100px;
	padding: rem(7 12);
	background: $color-lime-green-200;
	box-shadow:
		0px 4px 8px 0px rgba(16, 24, 40, 0.14),
		0px 8px 16px 0px rgba(16, 24, 40, 0.14);
	justify-content: center;
	position: relative;
	color: $color-dark-blue-900;
	font-family: $font-poppins;
	font-size: rem($px-normal);
	font-weight: $font-semibold;
	line-height: 120%;
	overflow: hidden;
	-webkit-tap-highlight-color: transparent;

	@include device((tablet-up)) {
		padding: rem(16);
	}

	svg {
		@include device((tablet-up)) {
			width: 38px;
			height: 38px;
		}
	}

	&__wrapper {
		position: fixed;
		bottom: 75px;
		right: 0.5rem;
		z-index: z-index(livechat);
		transition: all 0.3s linear;

		@include device((tablet-up)) {
			bottom: 96px;
		}
	}

	&__path2 {
		opacity: 0;
	}

	&__text {
		opacity: 0;
		width: 0;
		color: $color-dark-blue-900;
		transition: width 0.4s linear;
		white-space: nowrap;
	}

	&:hover,
	&.mobile-active {
		box-shadow:
			0px 4px 4px 0px rgba(0, 0, 0, 0.25),
			0px 12px 24px 6px rgba(16, 24, 40, 0.08),
			0px 24px 48px 6px rgba(16, 24, 40, 0.08);

		.livechat {
			&__path2 {
				opacity: 1;
			}

			&__text {
				padding-left: 10px;
				opacity: 1;
				width: auto;
				min-width: 180px;
				transition: all 0.4s linear;

				@include device((small-mobile, mobile)) {
					white-space: normal;
					max-width: 250px;
				}
			}
		}
	}
}

.scroll-to-top {
	&__wrapper {
		position: fixed;
		bottom: 16px;
		right: 0.5rem;
		z-index: z-index(scroll-to-top);
		display: flex;
		padding: rem(14);
		justify-content: center;
		align-items: center;
		border-radius: 100px;
		background: $color-white;
		cursor: pointer;
		@include shadow-8();

		@include device((tablet-up)) {
			padding: rem(19);
		}

		&:hover {
			@include shadow-16();
		}

		&:active {
			box-shadow: none;
		}

		&[hidden] {
			display: none !important;
		}

		svg {
			@include device((tablet-up)) {
				width: 32px;
				height: 32px;
			}
		}
	}
}

@import '../select';
@import '../partials/pagination';
@import '../partials/instrument-tile';
@import '../partials/collection-filters';

body.page-instruments {
	.instruments-search {
		&__wrapper {
			display: grid;
			grid-gap: rem(16);
			grid-template-columns: repeat(2, 1fr);

			@include device((tablet-up)) {
				grid-gap: rem(20);
			}

			@include device((desktop)) {
				grid-template-columns: repeat(3, 1fr);
			}

			@include device((large-desktop)) {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		&__close-text {
			display: none;
		}

		&__long-description {
			transition: all 0.7s;
			max-height: rem(4000);
			opacity: 1;
			overflow: hidden;
			padding-top: rem(16);

			&.hidden {
				max-height: 0;
				opacity: 0;
			}
		}

		&__read-more-text {
			display: inline-flex;
		}

		&__read-more-text,
		&__close-text {
			align-items: center;
		}
	}

	.section-white {
		&--bottom {
			margin: 0;
		}
	}

	.pagination {
		&__wrapper {
			margin: rem(16 0 64);
		}
	}

	#read-long-description-btn {
		&.active {
			.instruments-search {
				&__read-more-text {
					display: none;
				}

				&__close-text {
					display: inline-flex;
				}
			}
		}
	}
}

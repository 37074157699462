@use 'sass:math';

$logoMobileSize: 27px;
$logoDesktopSize: 48px;

.instrument-tile {
	&__item {
		border-radius: 24px;
		padding: rem(18);
		position: relative;
		min-width: rem(164);
		animation: tileAnimate 0.3s linear;
		transition: all 0.3s ease-out;
		animation-delay: 0s;
		background: $color-white;
		color: $color-dark-blue-900;
		box-shadow:
			0 1px 2px 0 rgba(16, 24, 40, 0.18),
			0 2px 4px 0 rgba(16, 24, 40, 0.18);
		display: block;

		@include device((tablet-up)) {
			min-width: rem(282);
			padding: rem(32);
		}

		&:hover {
			color: $color-dark-blue-900;
			box-shadow:
				0 12px 24px 6px rgba(16, 24, 40, 0.08),
				0 24px 48px 6px rgba(16, 24, 40, 0.08);

			svg {
				stroke-width: 2px;
			}
		}

		&:active {
			box-shadow: none;
		}

		&:last-child {
			margin-right: 0;
		}

		&.display-none,
		&.search-display-none,
		&.pagination-display-none {
			display: none;
		}

		&--no-animation {
			animation: none;
		}
	}

	&__logo {
		position: relative;
		margin: rem(9 0);
		height: $logoMobileSize;
		width: $logoMobileSize;

		@include device((tablet-up)) {
			margin: rem(16 0);
			height: $logoDesktopSize;
			width: $logoDesktopSize;
		}

		.instrument-logo {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%)
				scale(#{scaleLogo($logoMobileSize)});

			@include device((tablet-up)) {
				transform: translate(-50%, -50%)
					scale(#{scaleLogo($logoDesktopSize)});
			}
		}
	}

	&__arrow-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include device((small-mobile, mobile, large-mobile)) {
			.chips {
				padding: rem(3 6);
				font-size: rem(10);
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}

		@include rightToLeft {
			transform: scaleX(-1);
		}
	}

	&__name {
		@include headers-h4();

		@include device((small-mobile, mobile, large-mobile)) {
			font-size: rem($px-bigger);
		}
	}

	&__price {
		@include headers-h5();
		color: $color-grey-600;
		margin: rem(7 0);

		@include device((small-mobile, mobile, large-mobile)) {
			font-size: rem($px-small);
		}

		@include device((tablet-up)) {
			margin: rem(12 0);
		}
	}

	&__change {
		line-height: 1.2;

		@include device((small-mobile, mobile, large-mobile)) {
			font-size: rem($px-extra-small);
		}
	}
}

@keyframes tileAnimate {
	from {
		opacity: 0.4;
	}
	to {
		opacity: 1;
	}
}

@import '../partials/platforms';
@import '../partials/desktop-app-note';

body.page-desktop-app {
	h2 {
		margin-bottom: rem(16);
	}

	.section-standard {
		&__img {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem(43 16 36);

			@include rightToLeft {
				transform: scaleX(1);
			}

			@include device((tablet-up)) {
				justify-content: flex-start;
				padding: 0;
			}

			@include device((small-mobile, mobile)) {
				img {
					width: 100%;
				}
			}
		}

		&__button {
			&--desktop-app {
				@include device((small-mobile, mobile, large-mobile)) {
					.btn {
						pointer-events: none;
						background: $color-grey-400;
						box-shadow: none;

						svg {
							display: none;
						}
					}
				}
			}
		}
	}
}

@import 'variables';

// default sizes
.instrument-logo {
	width: $instrument-logo-default-size;
	height: $instrument-logo-default-size;
}

.language-flag {
	display: block;
	width: 29px;
	height: 29px;
	background-image: url('/assets/images/language-flag-sprite.png');

	&.ar {
		background-position: -0 -0;
	}

	&.bg {
		background-position: -29px -0;
	}

	&.cs {
		background-position: -0 -29px;
	}

	&.el {
		background-position: -29px -29px;
	}

	&.en {
		background-position: -58px -0;
	}

	&.es {
		background-position: -58px -29px;
	}

	&.de {
		background-position: -0 -58px;
	}

	&.id {
		background-position: -29px -58px;
	}

	&.fr {
		background-position: -58px -58px;
	}

	&.fi {
		background-position: -87px -0;
	}

	&.hi {
		background-position: -87px -29px;
	}

	&.hr {
		background-position: -87px -58px;
	}

	&.hu {
		background-position: -0 -87px;
	}

	&.is {
		background-position: -29px -87px;
	}

	&.ja {
		background-position: -58px -87px;
	}

	&.ka {
		background-position: -87px -87px;
	}

	&.it {
		background-position: -116px -0;
	}

	&.ko {
		background-position: -116px -29px;
	}

	&.nl {
		background-position: -116px -58px;
	}

	&.my {
		background-position: -116px -87px;
	}

	&.ph {
		background-position: -0 -116px;
	}

	&.ro {
		background-position: -29px -116px;
	}

	&.no {
		background-position: -58px -116px;
	}

	&.pl {
		background-position: -87px -116px;
	}

	&.pt {
		background-position: -116px -116px;
	}

	&.sl {
		background-position: -145px -0;
	}

	&.sk {
		background-position: -145px -29px;
	}

	&.sv {
		background-position: -145px -58px;
	}

	&.tr {
		background-position: -145px -87px;
	}

	&.th {
		background-position: -145px -116px;
	}

	&.tw {
		background-position: -0 -145px;
	}

	&.uk {
		background-position: -29px -145px;
	}

	&.ru {
		background-position: -58px -145px;
	}

	&.vi {
		background-position: -87px -145px;
	}

	&.zh {
		background-position: -116px -145px;
	}

	&.xs {
		background-position: -145px -145px;
	}
}

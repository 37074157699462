.similar-instruments {
	&__wrapper {
		display: flex;
		gap: rem(8);
		transition: all 0.2s ease-in 0s;

		@include device((tablet-up)) {
			gap: rem(16);
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

@import '../partials/app-store-buttons';
@import '../partials/desktop-app-note';

%responsive-column-layout {
	@include device((small-mobile, mobile)) {
		flex-direction: column;
		padding-top: rem(24);

		.section-standard__content {
			text-align: center;
		}
	}
}

%section-standard-mobile-adjustments {
	&.section-standard {
		@include device((small-mobile, mobile)) {
			padding-top: rem(66);
		}
	}

	.section-standard__content {
		@include device((small-mobile, mobile)) {
			padding-bottom: rem(0);
		}
	}
}

body.page-zero-fees {
	.section-standard {
		margin-bottom: rem(100);

		@include device((small-mobile, mobile, large-mobile)) {
			margin-bottom: rem(40);
		}

		&__img {
			@include device((small-mobile, mobile, large-mobile)) {
				margin: rem(16 0);
			}

			img {
				@include device((small-mobile, mobile, large-mobile)) {
					width: 100%;
				}
			}
		}

		&__content {
			@include device((small-mobile, mobile, large-mobile)) {
				padding-bottom: rem(40);
			}

			h3 {
				margin-bottom: rem(16);
			}
		}

		&__text {
			span {
				font-weight: $font-bold;
			}
		}

		&--top {
			img {
				@include device((small-mobile, mobile, large-mobile)) {
					position: relative;
					left: rem(-62);
					top: rem(-10);
				}
			}
		}

		&--reverse {
			@extend %responsive-column-layout;
			gap: rem(40);

			@include device((small-mobile, mobile, large-mobile)) {
				gap: 0;
			}

			@extend %section-standard-mobile-adjustments;

			.section-standard__img {
				align-items: center;
				justify-content: right;

				@include device((small-mobile, mobile, large-mobile)) {
					margin-bottom: rem(40);
				}
			}
		}

		&--trade {
			@extend %responsive-column-layout;
			margin-top: rem(100);

			@extend %section-standard-mobile-adjustments;

			.section-standard__content {
				@include device((small-mobile, mobile, large-mobile)) {
					padding-bottom: rem(0);
				}
			}

			.section-standard {
				&__img {
					justify-content: right;

					@include device((small-mobile, mobile, large-mobile)) {
						padding: rem(20 28 48);
					}
				}
			}
		}

		&--award {
			background-color: transparent;
			margin-bottom: 0;

			@include device((small-mobile, mobile, large-mobile)) {
				flex-direction: column;
			}

			.section-standard__img {
				flex: 4;
				align-items: center;
			}

			.section-standard__img,
			.section-standard__content {
				@include device((small-mobile, mobile, large-mobile)) {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding-bottom: 0;
				}
			}

			.section-standard__text {
				@include device((small-mobile, mobile, large-mobile)) {
					margin-top: rem(16);
				}
			}

			.section-standard__text,
			.section-standard__content {
				@include device((small-mobile, mobile, large-mobile)) {
					text-align: center;
				}
			}
		}

		&--download {
			overflow: visible;

			img {
				width: 100%;
			}
		}
	}

	.trade-without-fee {
		margin-top: rem(48);

		@include device((tablet-up)) {
			margin: rem(24 0 0);
			display: grid;
			grid-gap: rem(16);
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		.section-standard__img {
			justify-content: center;
		}
	}

	.bullet-list {
		&__wrapper,
		&__item {
			display: flex;
		}

		&__wrapper {
			flex-direction: column;
			justify-content: center;

			&:first-child {
				margin-left: rem(24);
			}

			&:last-child {
				margin-right: rem(24);

				@include device((small-mobile, mobile, large-mobile)) {
					margin: rem(0 0 0 24);
				}
			}
		}

		&__item {
			margin-bottom: rem(40);
		}

		&__text {
			font-weight: $font-semibold;
			margin-left: rem(8);
		}
	}

	.box {
		padding: rem(24 50);

		@include device((small-mobile, mobile, large-mobile)) {
			padding: rem(24);
		}
	}

	.knowledge-subtitle {
		width: 70%;
		margin: 0 auto;
		padding-top: rem(16);

		@include device((small-mobile, mobile, large-mobile)) {
			width: 100%;
		}
	}

	.download-app {
		display: flex;
		align-items: flex-start;
		margin-top: rem(36);

		@include device((small-mobile, mobile, large-mobile)) {
			flex-direction: column;
			align-items: center;
		}

		&__title {
			padding-bottom: rem(16);
		}

		.platform__store-buttons-row {
			margin-right: rem(36);

			@include device((small-mobile, mobile, large-mobile)) {
				margin: rem(0 0 24 0);
			}

			.store-button__qr-wrapper--google-play {
				left: rem(50);
				top: rem(50);
				border-radius: rem(0 16 16 16);
				z-index: z-index(dropdown);
			}
		}

		.section-standard__button {
			&--desktop-app {
				@include device((small-mobile, mobile, large-mobile)) {
					.btn {
						pointer-events: none;
						background: $color-grey-400;
						border-color: $color-grey-400;
						color: $color-white;
						box-shadow: none;

						svg {
							display: none;
						}
					}
				}
			}

			a {
				padding: rem(16 64);
			}

			span {
				padding-right: rem(8);
			}
		}
	}
}

@import '../partials/filters-modal';

.collection-filters {
	&__filters-wrapper {
		margin-top: rem(24);
		display: flex;
		flex-flow: wrap;
		gap: rem(25);

		@include device((tablet-up)) {
			margin-top: rem(24);
		}
	}

	&__filter-title {
		font-family: $font-poppins;
		font-weight: $font-semibold;
		line-height: 1.6;
		font-size: rem($px-bigger);
		text-transform: uppercase;

		@include device((small-mobile, mobile, large-mobile)) {
			order: -2;
		}
	}

	&__filters-clear-all {
		padding: rem(0 12);
		line-height: rem(24);
		font-size: rem(12);
		min-width: unset;
		display: none;

		&.active {
			display: flex;
		}

		@include device((small-mobile, mobile, large-mobile)) {
			font-size: rem(14);
			order: -1;
			padding: 0;
		}
	}

	&__filter-title,
	&__filters-clear-all {
		width: calc(50vw - 50px);

		@include device((tablet-up)) {
			width: unset;
			order: unset;
		}
	}

	&__filters-chips-wrapper {
		display: flex;
		flex-flow: wrap;
		align-items: center;
		gap: rem(8);

		> div:not(.btn) {
			display: flex;
			flex-flow: wrap;
			align-items: center;
			gap: rem(8);
		}
	}

	&__select-wrapper {
		margin: rem(16 0);
		display: flex;
		flex-direction: column;
		gap: rem(8);

		@include device((tablet-up)) {
			flex-direction: row;
			margin: rem(8 0);
		}
	}

	&__select {
		flex: 1;
		max-width: rem(304);
	}

	&__sort-wrapper {
		margin-bottom: rem(16);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__filter-btn {
		min-width: unset;
		width: unset;
		padding: rem(12 30);

		@include device((tablet-up)) {
			display: none;
		}
	}

	&__modal {
		display: none;

		@include device((tablet-up)) {
			display: block;
		}

		#sort-select-mobile {
			display: none;
		}

		&--open {
			@include modal-open();

			.collection-filters {
				&__modal-close {
					display: block;
				}

				&__modal-btn-wrapper {
					display: block;
				}

				&__select-wrapper {
					@include modal-select-reset();

					.chevron {
						display: none;
					}
				}
			}

			#sort-select-mobile {
				display: block;

				.custom-select {
					&__wrapper {
						margin-bottom: rem(15);
					}

					&__value {
						border: 0;
					}

					&__list {
						position: relative;
						background: none;
						box-shadow: none;
					}

					&__value,
					&__list {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}

	&__modal-close {
		display: none;
		@include modal-close();
	}

	&__modal-content {
		@include device((small-mobile, mobile, large-mobile)) {
			@include modal-content();
		}
	}

	&__modal-btn-wrapper {
		display: none;
		padding: rem(24);
	}

	&__results {
		font-size: $px-small;
		color: $color-grey-600;
		line-height: 1.2;
	}

	&__no-data {
		&[hidden] {
			display: none;
		}
	}

	.form {
		&__group {
			&--search {
				@include device((tablet-up)) {
					min-width: 345px;
				}
			}
		}
	}

	#sort-select-desktop {
		display: none;

		@include device((tablet-up)) {
			display: block;
		}
	}

	@include device((small-mobile, mobile, large-mobile)) {
		.pagination {
			&__items-placeholder {
				text-align: center;

				.pagination {
					&__item {
						display: inline-flex;
					}
				}
			}
		}
	}
}

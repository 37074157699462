@mixin modal-open() {
	position: fixed;
	top: 0;
	bottom: 0;
	bottom: env(safe-area-inset-bottom);
	left: 0;
	right: 0;
	display: block;
	background-color: $color-grey-100;
	padding: rem(24);
	z-index: z-index(filtersModal);
}

@mixin modal-close() {
	position: relative;
	width: rem(24);
	height: rem(24);
	margin-bottom: rem(24);
	margin-left: auto;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 50%;
		display: block;
		width: 24px;
		height: 2px;
		background: $color-dark-blue-900;
	}

	&::before {
		transform: translate(50%, -50%) rotate(45deg);
	}

	&::after {
		transform: translate(50%, -50%) rotate(-45deg);
	}
}

@mixin modal-content() {
	overflow-y: auto;
	// 5 * 24px => 1. padding top, 2. padding bottom, 3 close button height, 4 close button margin, 5. filter button top margin
	// 50px => filter button height
	max-height: calc(100vh - 24px * 5 - 50px);

	@supports (height: 100dvh) {
		height: calc(100dvh - 24px * 5 - 50px);
	}
}

@mixin modal-select-reset() {
	.custom-select {
		&__list,
		&__wrapper {
			position: unset;
		}

		&__list {
			display: block;
			background: unset;
			max-height: unset;
			box-shadow: none;
			padding-left: 0;
			padding-right: 0;
		}

		&__list,
		&__list-content {
			overflow: visible;
		}

		&__value,
		&__list-button {
			display: none;
		}
	}
}

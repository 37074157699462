@import '../partials/platforms';
@import '../partials/app-store-buttons';

body.page-mobile-app {
	h2 {
		margin-bottom: rem(16);
	}

	.section-standard {
		&--first-section {
			overflow: unset;
		}

		&__img {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem(43 16 36);

			@include device((tablet-up)) {
				padding: 0;
			}
		}
	}

	.section-top {
		&__buttons {
			display: flex;
			gap: rem(16);
			margin-top: rem(48);

			&--mobile {
				@include device((tablet-up)) {
					display: none;
				}
			}

			&--desktop {
				display: none;

				@include device((tablet-up)) {
					display: flex;
				}
			}
		}

		&__info {
			@include text-note-small();
			color: $color-grey-500;
			margin-top: rem(8);
		}
	}

	.section-boxes {
		&__info {
			color: $color-grey-300;
			font-size: rem($px-extra-small);
			font-weight: $font-light;
			margin: 0;
		}
	}

	.features {
		&__info {
			color: $color-grey-600;
			font-size: rem($px-extra-small);
			font-weight: $font-light;
		}
	}

	.platform {
		&__description {
			text-align: center;

			@include device((tablet-up)) {
				text-align: left;
			}
		}
	}

	.section-white {
		.section-row {
			&__content {
				text-align: center;

				@include device((tablet-up)) {
					text-align: left;
				}
			}
		}
	}

	.section-reviews {
		&__wrapper {
			margin: rem(24 0 0);
			gap: rem(16);
			display: grid;

			@include device((tablet-up)) {
				margin: rem(24 36 0);
				grid-template-columns: min-content 1fr;
			}

			@include device((large-desktop)) {
				margin: rem(24 100 0);
			}
		}

		&__tabs {
			display: flex;
			flex-direction: column;
			gap: rem(16);
		}

		&__tab {
			border-radius: rem(16);
			background: $color-white;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: rem(24);
			padding: rem(24);
			min-width: rem(350);

			picture {
				display: flex;
				justify-content: center;
			}
		}

		&__points-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		&__points {
			font-family: $font-poppins;
			font-size: rem(40);
			color: $color-primary-blue-500;
			font-weight: $font-semibold;
			line-height: 1;
		}

		&__items {
			@include device((tablet-up)) {
				padding-right: rem(16);
				height: 0;
				min-height: 100%;
				overflow: auto;
			}
		}

		&__item {
			border-radius: rem(16);
			background: $color-white;
			padding: rem(24);
			box-shadow:
				0 1px 2px 0 rgba(16, 24, 40, 0.18),
				0px 2px 4px 0px rgba(16, 24, 40, 0.18);
			margin-bottom: rem(16);
		}

		&__username {
			font-weight: $font-medium;
			line-height: 1.2;
			display: flex;
			align-items: center;
			gap: rem(8);
		}

		&__rating {
			margin-top: rem(12);
			display: flex;
			align-items: center;
			gap: rem(16);
			font-weight: $font-light;
			font-size: rem($px-small);
			line-height: 1.2;
			color: $color-dark-blue-600;
		}

		&__stars {
			display: flex;
		}

		&__text {
			line-height: 1.4;
			margin-top: rem(8);
			color: $color-grey-600;
		}

		&__star {
			&--inactive {
				path {
					fill: $color-grey-300;
				}
			}
		}

		&__average-text {
			font-family: $font-poppins;
			font-size: rem(13);
			color: $color-grey-500;
			line-height: 2;
		}
	}

	.section-about {
		@include device((small-mobile, mobile)) {
			img {
				width: 100%;
			}
		}

		@include device((small-mobile, mobile, large-mobile)) {
			.section-row {
				&__img {
					text-align: center;
				}
			}
		}
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: rem(8);
	margin: 0;
	padding: 0;
	list-style: none;

	[hidden] {
		display: none !important;
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__items-placeholder {
		margin-left: rem(16);
		margin-right: rem(16);
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background: transparent;
		color: $color-grey-400;
		font-family: $font-poppins;
		margin: 0;
		padding: 0;
		min-width: rem(36);
		transition: all 0.1s ease-out;
		user-select: none;

		&:hover,
		&:active {
			.pagination {
				&__arrow-mask {
					visibility: visible;
				}
			}
		}

		&:hover {
			color: $color-grey-400;
			font-weight: $font-semibold;
		}

		&:active {
			color: $color-grey-500;
			font-weight: $font-semibold;

			path {
				stroke-width: rem(1);
				stroke: $color-primary-blue-500;
			}
		}

		@include rightToLeft {
			svg {
				transform: scaleX(-1);
			}
		}

		&--disabled {
			pointer-events: none;
			cursor: not-allowed;

			path {
				fill: $color-grey-300;
			}
		}

		&--active {
			color: $color-primary-blue-500;
			font-weight: $font-semibold;
			pointer-events: none;
		}

		&--ellipsis {
			margin-left: rem(-8);
			margin-right: rem(-8);
			min-width: rem(20);
		}
	}

	&__arrow-mask {
		transition: all 0.1s ease-out;
		visibility: hidden;
	}
}

$padding: 24px;
$standard-icon: 124px;
$small-icon: 48px;
$component-height: 350px;
$title-margin: 16px;
$smart-animation: cubic-bezier(0.47, 0, 0.23, 1);
$smart-transition: all 0.6s $smart-animation;

@mixin activeState() {
	flex: 2.3;
	box-shadow:
		0 12px 24px 6px rgba(16, 24, 40, 0.08),
		0 24px 48px 6px rgba(16, 24, 40, 0.08);

	.offer {
		&__item-title {
			@include device((desktop-up)) {
				transform: translateY(
					calc(
						-#{$component-height} + (#{$padding} * 2) + #{$small-icon} +
							#{$title-margin} + 100%
					)
				);
			}
		}

		&__item-icon {
			width: $small-icon;
			height: $small-icon;
		}

		&__item-arrow {
			stroke-width: 2px;
		}

		&__item-text {
			opacity: 1;
			transition:
				opacity 0.6s linear,
				max-height 0.6s $smart-animation;

			@include device((small-mobile, mobile, large-mobile, tablet)) {
				max-height: 800px;
			}
		}
	}
}

body.page-offer {
	.main {
		padding-bottom: rem(40);
	}

	.offer {
		&__wrapper {
			width: 100%;
			gap: rem(16);
			margin: rem(16 0);
			display: flex;
			flex-direction: column;
			transition: $smart-transition;

			@include device((desktop-up)) {
				flex-direction: row;
			}

			&--top {
				.offer {
					&__item {
						&:first-child {
							@include device((desktop-up)) {
								flex: 2.3;
							}
						}
					}
				}

				&:has(.right:hover),
				&:has(.center:hover) {
					.offer {
						&__item {
							&:first-child {
								@include device((desktop-up)) {
									flex: 1;
								}
							}
						}
					}
				}
			}
		}

		&__item {
			display: flex;
			flex: 1;
			transition: $smart-transition;
			overflow: hidden;
			position: relative;
			padding: rem($padding);
			flex-direction: column;
			justify-content: space-between;
			border-radius: 32px;
			color: $color-dark-blue-900;
			box-shadow:
				0 12px 24px 6px rgba(16, 24, 40, 0.08),
				0 24px 48px 6px rgba(16, 24, 40, 0.08);

			@include device((desktop-up)) {
				height: $component-height;
				box-shadow:
					0 1px 2px 0 rgba(16, 24, 40, 0.18),
					0 2px 4px 0 rgba(16, 24, 40, 0.18);
			}

			@include device((small-mobile, mobile, large-mobile, tablet)) {
				&.active {
					@include activeState();

					.offer {
						&__box-arrow {
							.chevron {
								transform: rotate(180deg);
							}
						}
					}
				}
			}

			@include device((desktop-up)) {
				&:hover,
				&.active {
					@include activeState();
				}
			}

			&:active {
				box-shadow: none;
				transition: all 0.3s ease-out;
			}

			&--green,
			&--white,
			&--dark {
				.offer {
					&__item-arrow {
						path {
							stroke: $color-dark-blue-900;
						}
					}
				}
			}

			&--green {
				background: $color-lime-green-200;
			}

			&--white {
				background: $color-grey-25;
			}

			&--red {
				color: $color-white;
				background: $color-red-500;
			}

			&--blue {
				color: $color-white;
				background: $gradient-primary-blue;
			}

			&--dark {
				color: $color-white;
				background: $color-dark-blue-800;
			}
		}

		&__item-icons {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
		}

		&__item-icon,
		&__item-arrow,
		&__item-title {
			transition: $smart-transition;
		}

		&__item-arrow {
			display: none;

			@include device((desktop-up)) {
				display: block;

				@include rightToLeft {
					transform: scaleX(-1);
				}
			}
		}

		&__item-icon {
			width: $standard-icon;
			height: $standard-icon;
		}

		&__item-title {
			@include headers-h4();
			margin: rem(16 0 0);
			transform: translateY(0);

			@include device((tablet-up)) {
				margin: 0;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__item-text {
			font-size: rem($px-small);
			line-height: 140%;
			opacity: 0;
			transition:
				opacity 0.2s linear,
				max-height 0.6s $smart-animation;

			@include device((small-mobile, mobile, large-mobile, tablet)) {
				max-height: 0;
				overflow: hidden;
			}

			@include device((desktop-up)) {
				width: 430px;
				position: absolute;
				bottom: $padding;
				left: $padding;
				right: $padding;
			}

			@include device((desktop)) {
				font-size: rem($px-extra-small);
			}

			@include device((large-desktop)) {
				width: 570px;
			}
		}

		&__item-button {
			display: inline-flex;

			@include device((desktop-up)) {
				display: none;
			}
		}

		&__box-arrow {
			display: block;
			padding-top: rem(12);

			@include device((desktop-up)) {
				display: none;
			}

			.chevron {
				margin: auto;
			}
		}
	}
}

.section-instruments {
	display: flex;
	flex-direction: column-reverse;
	gap: 24px;
	margin: rem(100 0);

	@include device((desktop-up)) {
		flex-direction: row;
	}

	&__left-column {
		@include device((tablet-up)) {
			min-width: rem(680);
		}
	}

	&__right-column {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@include device((small-mobile, mobile, large-mobile)) {
			.section-home {
				margin: 0;
			}

			.chips {
				display: table;
				margin: auto;
			}

			.section-home {
				&__heading {
					text-align: center;
				}
			}
		}

		@include device((tablet-up)) {
			padding: rem(0 50);

			.section-home {
				text-align: left !important;
			}
		}
	}

	&__table {
		padding: rem(16);
		border-radius: rem(0 0 16 16);
		background: $color-white;
		box-shadow:
			0 24px 48px 6px rgba(16, 24, 40, 0.08),
			0 12px 24px 6px rgba(16, 24, 40, 0.08);
	}

	&__table-top {
		padding: rem(8 16);
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}

	&__table-title {
		@include headers-h5();
		font-size: rem(24);
	}

	&__bottom-btn {
		padding: rem(16);
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.tab {
		&__item {
			gap: 4px;

			span {
				display: none;
			}

			&--active {
				pointer-events: none;

				@include device((tablet-up)) {
					span {
						display: block;
					}
				}
			}
		}
	}
}

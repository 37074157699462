.faq {
	&__wrapper {
		@include device((tablet-up)) {
			padding: rem(0 50);
		}
	}

	&__group {
		border-bottom: 1px solid $color-grey-400;
		transition: all 0.1s ease-out;

		&:hover,
		&:active {
			border-color: $color-primary-blue-400;
			box-shadow: 0 1px 0 $color-primary-blue-400;

			.faq {
				&__group-title {
					font-weight: $font-semibold;

					.chevron {
						&::before,
						&::after {
							background: $color-primary-blue-400;
						}
					}

					path {
						stroke: $color-primary-blue-400;
					}
				}
			}
		}

		&.active {
			border-bottom: 1px solid $color-primary-blue-500;

			&:hover {
				box-shadow: none;
			}

			.faq {
				&__group-title {
					font-weight: $font-semibold;
					border-bottom: none;

					svg:not(.faq__section-icon) {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	&__group-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-family: $font-poppins;
		color: $color-dark-blue-900;
		line-height: 1.2;
		padding: rem(16 32);
		transition: all 0.1s ease-out;
	}

	&__content {
		padding: rem(0 32);

		&.active {
			padding: rem(8 32 24);
		}
	}
}

@import '../row-link';

@mixin categories-wrapper() {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	align-content: flex-start;
	gap: 12px;
	align-self: stretch;
	flex-wrap: wrap;

	@include device((tablet-up)) {
		gap: 8px;
		justify-content: flex-start;
	}
}

@mixin category-button() {
	border-radius: 4px;
	border: 1px solid $color-grey-200;
	font-family: $font-poppins;
	font-weight: $font-medium;
	padding: 0;
	transition: all 0.3s ease-out;
	color: $color-grey-900;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 33px;

	&:hover {
		background: $color-grey-100;
		box-shadow:
			0 4px 8px 0 rgba(16, 24, 40, 0.14),
			0px 8px 16px 0px rgba(16, 24, 40, 0.14);
	}

	&.active,
	&:active {
		border: 1px solid $color-primary-blue-500;
		box-shadow: none;
	}
}

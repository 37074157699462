@use 'sass:math';

@import '../faq';
@import '../partials/section-features';

@mixin grid-center-last($columns) {
	$total_columns: $columns * 2;
	$span: math.div($total-columns, $columns);
	$offset: $columns - 1;
	$nth-child: if($columns % 2 == 0, 'odd', 'even');

	display: grid;
	grid-template-columns: repeat($total_columns, 1fr);

	& > * {
		grid-column: span $span;

		&:nth-last-child(#{$offset}):nth-child(#{$nth-child}) {
			grid-column: $span / span $span;
		}
	}
}

body.page-earn-stake-trade {
	h1 {
		margin-bottom: rem(8);
	}

	.section-about {
		p {
			margin: 0;
		}
	}

	.section-features {
		&__box-img {
			display: flex;
		}
	}

	.stake-info {
		&__wrapper {
			@include device((tablet-up)) {
				display: grid;
				justify-content: center;
				grid-gap: rem(16);

				.box {
					width: rem(300);
					margin: auto;
				}
			}

			@include device((tablet)) {
				@include grid-center-last(2);
			}

			@include device((desktop-up)) {
				@include grid-center-last(3);
			}
		}

		&__box {
			border-radius: rem(24);
			font-size: rem($px-extra-small);
			font-weight: $font-light;
			padding: rem(24 32);
			background: $color-white;
			color: $color-dark-blue-900;
			@include shadow-2();
			transition: all 0.3s ease-out;

			&:hover {
				@include shadow-24();
			}

			&:active {
				box-shadow: none;
			}

			&.disabled {
				pointer-events: none;

				&:hover {
					@include shadow-2();
				}
			}

			.box {
				&__img,
				&__text {
					width: 100%;
				}

				&__img {
					text-align: center;
				}
			}
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: rem(18);
			gap: rem(16);

			> div {
				&:nth-child(2) {
					font-weight: $font-semibold;
					font-family: $font-poppins;
					font-size: rem($px-small);
					text-align: right;
				}
			}

			&--button {
				justify-content: center;
			}
		}

		&__value {
			&--no-wrap {
				&:lang(es),
				&:lang(id) {
					white-space: nowrap;
				}
			}
		}

		&--red {
			color: $color-red-500;
		}

		&--green {
			color: $color-lime-green-500;
		}

		&__disclaimer {
			margin-top: rem(24);
			color: $color-grey-600;
			font-weight: $font-light;
			font-size: rem($px-extra-small);
		}

		&__terms-condition {
			font-weight: $font-light;
			font-size: rem($px-extra-small);
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}

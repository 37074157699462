/* *
 * PX TO REM CALCULATOR
 */

@use 'sass:math';

@function parseInt($n) {
	@return math.div($n, $n * 0 + 1);
}

@function rem($values) {
	$list: ();

	@each $value in $values {
		@if $value != 0 and type_of($value) == 'number' {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == 'px' or $unit == '' {
				$list: append($list, math.div($val, $px-normal) + rem);
			} @else {
				$list: append($list, $val + unquote($unit));
			}
		} @else {
			$list: append($list, $value);
		}
	}
	@return $list;
}

// Return z-index of element from list, return null if there is no element
@function z-index($element) {
	$z-index: index($z-index-list, $element);

	@if $z-index {
		@return $z-index;
	}

	@return null;
}

/* *
 * MEDIA QUERIES FOR DEVICES
 */

@mixin small-mobile {
	@media only screen and (max-width: #{$rwd-break-extra-small-device}) {
		@content;
	}
}

@mixin mobile {
	@media only screen and (max-width: #{$rwd-break-small-device}) {
		@content;
	}
}

@mixin large-mobile {
	@media only screen and (min-width: #{$rwd-break-small-device + 1}) and (max-width: #{$rwd-break-medium-device}) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (min-width: #{$rwd-break-medium-device + 1}) and (max-width: #{$rwd-break-standard-device}) {
		@content;
	}
}

// tablet + desktop + large-desktop
@mixin tablet-up {
	@media only screen and (min-width: #{$rwd-break-medium-device + 1}) {
		@content;
	}
}

@mixin desktop {
	@media only screen and (min-width: #{$rwd-break-standard-device + 1}) and (max-width: #{$rwd-break-large-device}) {
		@content;
	}
}

// desktop + large-desktop
@mixin desktop-up {
	@media only screen and (min-width: #{$rwd-break-standard-device + 1}) {
		@content;
	}
}

@mixin large-desktop {
	@media only screen and (min-width: #{$rwd-break-large-device + 1}) {
		@content;
	}
}

@mixin extra-large-desktop {
	@media only screen and (min-width: #{$rwd-break-extra-large-device + 1}) {
		@content;
	}
}

@mixin device($devices) {
	@each $device in $devices {
		@if $device == 'small-mobile' {
			@include small-mobile {
				@content;
			}
		}
		@if $device == 'mobile' {
			@include mobile {
				@content;
			}
		}
		@if $device == 'large-mobile' {
			@include large-mobile {
				@content;
			}
		}
		@if $device == 'tablet-up' {
			@include tablet-up {
				@content;
			}
		}
		@if $device == 'tablet' {
			@include tablet {
				@content;
			}
		}
		@if $device == 'desktop-up' {
			@include desktop-up {
				@content;
			}
		}
		@if $device == 'desktop' {
			@include desktop {
				@content;
			}
		}
		@if $device == 'large-desktop' {
			@include large-desktop {
				@content;
			}
		}
		@if $device == 'extra-large-desktop' {
			@include extra-large-desktop {
				@content;
			}
		}
	}
}

@mixin resetButton {
	padding: 0;
	overflow: visible;
	outline: none;
	border: none;
	margin: 0;
	background: transparent;
	color: inherit;
	font: inherit;
	line-height: normal;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
	cursor: pointer;

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	@include device((tablet-up)) {
		width: auto;
	}
}

@mixin leftToRight {
	[dir='ltr'] & {
		@content;
	}
}

@mixin rightToLeft {
	[dir='rtl'] & {
		@content;
	}
}

@function scaleLogo($containerSize) {
	@return calc(
		(
				#{parseInt($containerSize) * 100} / var(--logo-width, #{parseInt(
								$instrument-logo-default-size
							)})
			) / 100
	);
}

body.page-unilink {
	.section-standard {
		&__subheader {
			font-size: rem(20);
		}

		&--with-chips {
			.section-standard {
				&__content {
					@include device((tablet-up)) {
						flex: 8;
						padding: rem(49 48 49 0);
					}
				}

				&__title {
					margin-bottom: rem(16);
				}

				&__text {
					&:last-of-type {
						margin-bottom: rem(48);

						@include device((tablet-up)) {
							margin-bottom: rem(16);
						}
					}
				}
			}
		}
	}

	.section-content {
		@include device((tablet-up)) {
			margin: rem(64 0);
		}

		.section-title {
			text-align: center;

			&:not(.text--center) {
				@include device((tablet-up)) {
					text-align: left;
				}
			}
		}

		.section-row {
			&__content {
				display: flex;
				flex-direction: column;
				align-items: center;

				@include device((tablet-up)) {
					align-items: flex-start;
				}

				&--half {
					padding-top: rem(18);

					@include device((tablet-up)) {
						justify-content: flex-start;
						padding-top: 0;
					}
				}
			}
		}

		&__images {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: rem(24);

			@include device((tablet-up)) {
				margin: 0;
			}
		}

		&__img {
			margin: rem(8 8 0);

			@include device((tablet-up)) {
				margin: rem(32 8 0);
			}

			&:not(:only-child) {
				height: rem(84);
				margin: rem(8);
				border-radius: rem(8);
				@include shadow-2();

				@include device((tablet-up)) {
					margin-top: rem(32);
					height: rem(150);
					@include shadow-4();
				}
			}
		}

		&__text {
			max-width: rem(845);
			margin: 0 auto;

			&:not(:last-child) {
				margin-bottom: rem(24);

				@include device((tablet-up)) {
					margin-bottom: rem(32);
				}
			}
		}
	}

	.section-white {
		@include device((tablet-up)) {
			margin: rem(64 0);
		}

		.section-row {
			&__content {
				text-align: center;

				@include device((tablet-up)) {
					text-align: left;
				}

				.section-text {
					text-align: left;
				}
			}
		}

		&__text {
			text-align: left;
		}
	}

	.section-row {
		gap: 0;

		@include device((tablet-up)) {
			gap: rem(64);
		}

		&__circles {
			margin-bottom: rem(24);

			@include device((tablet-up)) {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 0;
			}
		}
	}

	.circle {
		position: absolute;
		left: 0;
		top: rem(60);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: rem(176);
		height: rem(176);
		padding: rem(35 32 30);
		border-radius: 50%;
		box-shadow:
			0px 8px 16px 2px rgba(16, 24, 40, 0.1),
			0px 16px 32px 2px rgba(16, 24, 40, 0.1);
		background-color: $color-white;
		font-family: $font-poppins;
		line-height: 1;

		@include device((desktop-up)) {
			left: rem(62);
		}

		&__container {
			position: relative;
			height: rem(244);

			@include device((tablet-up)) {
				width: rem(280);
			}

			@include device((desktop-up)) {
				width: rem(392);
			}
		}

		&__text {
			font-size: rem(12);
			text-transform: uppercase;
		}

		&__number {
			font-size: rem(55);
			font-weight: $font-semibold;
			color: $color-lime-green-200;
		}

		&--small {
			left: rem(148);
			top: rem(8);
			width: rem(140);
			height: rem(140);
			box-shadow:
				0px 4px 8px 0px rgba(16, 24, 40, 0.14),
				0px 8px 16px 0px rgba(16, 24, 40, 0.14);

			@include device((desktop-up)) {
				left: rem(210);
			}

			.circle {
				&__text {
					font-size: rem(10);
				}

				&__number {
					font-size: rem(40);
				}
			}
		}
	}
}

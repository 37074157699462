.section-cta-footer {
	display: flex;
	flex-direction: column;
	color: $color-white;
	font-size: rem($px-bigger);
	padding: rem(50 0);
	text-align: center;

	@include device((desktop-up)) {
		flex-direction: row;
		text-align: left;
		padding: rem(70 0 20);

		@include rightToLeft {
			text-align: right;
		}
	}

	&__bg {
		background: $gradient-02;
		overflow: hidden;
	}

	&__heading {
		@include headers-h3();
		font-size: rem(32);
		display: flex;
		flex-direction: column;

		@include device((tablet-up)) {
			font-size: rem(48);
			line-height: normal;
			letter-spacing: 0.96px;
		}

		&--line2 {
			@include device((small-mobile, mobile, large-mobile)) {
				padding-top: rem(24);
			}
		}
	}

	&__text {
		font-weight: $font-light;
		margin: rem(24 0 48);
	}

	&__form {
		display: flex;
		flex-direction: column;
		text-align: left;

		@include device((desktop-up)) {
			flex-direction: row;
			gap: $grid-padding;

			.section-cta-footer__form-input {
				width: 100%;
			}
		}

		@include rightToLeft {
			text-align: right;
		}

		input {
			&[type='email'] {
				min-height: rem(58);

				&:focus,
				&:not(:placeholder-shown) {
					+ label {
						transform: translateY(-40px);
					}
				}
			}
		}

		.form {
			&__group {
				&.error {
					.form {
						&__label {
							color: $color-red-500;
						}
					}

					input {
						&[type='email'] {
							&:invalid,
							&.error {
								border: 3px solid $color-red-500;
							}
						}
					}
				}
			}
		}
	}

	&__content {
		@include device((desktop-up)) {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&__img {
		margin-top: rem(64);

		@include device((desktop-up)) {
			margin-top: 0;
			max-width: 556px;
		}
	}

	&__checkbox-wrapper {
		margin-top: rem(15);
		margin-left: rem(11);
		padding-left: 0;
		display: flex;
		align-items: center;

		@include device((small-mobile, mobile, large-mobile, tablet)) {
			margin-bottom: rem(20);
		}
	}

	&__checkbox-input {
		visibility: hidden;
		position: absolute;

		&[type='checkbox'] {
			position: absolute;
		}

		+ label {
			position: relative;
			cursor: pointer;

			&::before,
			&::after {
				position: absolute;
				left: 0;
				top: 0;
				width: rem(18);
				height: rem(18);
				border-radius: 2px;
				transition: 0.2s all;
				padding-left: rem(2);
				padding-top: rem(2);
				background: transparent;
				font-size: 0.8rem;
				line-height: 1;
				color: $color-white;

				@include rightToLeft {
					left: unset;
					right: 0;
				}
			}

			&::before {
				content: '';
				border: 2px solid $color-white;
			}

			&::after {
				content: '\2714';
				border: 2px solid transparent;
				opacity: 0;
			}
		}

		&:checked {
			visibility: hidden;

			+ label {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	&__checkbox-label {
		color: $color-white;
		font-size: rem($px-extra-small);
		padding-left: rem(25);

		@include rightToLeft {
			padding-left: unset;
			padding-right: rem(25);
		}

		a {
			color: $color-white;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__button {
		position: relative;
	}

	&__field-required {
		font-size: rem(12);
		padding: rem(5 0);
		color: $color-red-500;
	}

	&__social-icons-wrapper {
		display: flex;
		flex-direction: column;
		gap: rem(8);
		align-items: center;
		margin-top: rem(16);
		font-size: rem($px-normal);

		@include device((desktop-up)) {
			flex-direction: row;
			margin: rem(15 0 30);
			padding-left: rem(16);
			gap: rem(16);
		}
	}

	&__social-icons {
		display: flex;
		flex-direction: row;
		gap: rem(8);
		align-items: center;
		position: relative;

		.loader {
			visibility: hidden;
		}

		&.active {
			.loader {
				visibility: visible;
			}

			.section-cta-footer {
				&__social-provider {
					visibility: hidden;
				}
			}
		}
	}

	&__social-provider {
		cursor: pointer;
		padding: rem(8);
		border: 1px solid $color-white;
		border-radius: 8px;

		&:hover {
			border-color: $color-grey-300;
		}

		&:active {
			border-color: $color-primary-blue-100;
		}
	}
}

.btn-loader,
.loader {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	z-index: 100;
	box-shadow: none;

	&.hide {
		visibility: hidden;
		opacity: 0;
	}

	&__inner {
		width: 100%;
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		color: $color-white;
		text-align: center;
	}

	&__dot {
		width: 10px;
		height: 10px;
		background: $color-white;
		border-radius: 100%;
		opacity: 0;
		display: inline-block;

		&--first {
			animation: btn-loader-animation 1.5s 0.3s infinite ease-in-out;
		}

		&--second {
			animation: btn-loader-animation 1.5s 0.2s infinite ease-in-out;
		}

		&--third {
			animation: btn-loader-animation 1.5s 0.1s infinite ease-in-out;
		}
	}
}

.register-social-icon {
	width: 24px;
	height: 24px;
	background-image: url('/assets/images/register-social-icon-providers.png');
	background-size: 200%;

	&--apple {
		background-position: -0 -0;
	}

	&--x {
		background-position: -72px -48px;
	}

	&--telegram {
		background-position: -0 -72px;
	}

	&--google {
		background-position: -72px -120px;
	}
}

@keyframes btn-loader-animation {
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}
	33% {
		opacity: 1;
		transform: translateX(0px);
	}
	66% {
		opacity: 1;
		transform: translateX(0px);
	}
	100% {
		opacity: 0;
		transform: translateX(100px);
	}
}

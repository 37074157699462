@import '../partials/platforms';

body.page-web-trader {
	h2 {
		margin-bottom: rem(16);
	}

	.section-standard {
		h1 {
			margin-bottom: rem(8);
		}

		&__img {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem(43 16 36);

			@include rightToLeft {
				transform: scaleX(1);
			}

			@include device((tablet-up)) {
				justify-content: flex-start;
				padding: 0;
			}

			@include device((small-mobile, mobile)) {
				img {
					width: 100%;
				}
			}
		}
	}

	.section-boxes {
		&__info {
			color: $color-grey-300;
			font-size: rem($px-extra-small);
			font-weight: $font-light;
			margin-bottom: 0;
		}
	}
}

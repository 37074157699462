@import 'variables';

input:not([type='radio']):not([type='checkbox']),
textarea {
	border-radius: 8px;
	border: 1px solid $color-grey-200;
	background: $color-grey-25;
	color: $color-grey-600;
	font-size: rem($px-normal);
	width: 100%;
	padding: rem(4 0 4 16);
	line-height: rem(40);

	&::placeholder {
		color: $color-grey-600;
	}

	&:hover {
		border-color: $color-grey-400;
	}

	&:focus {
		outline: 1px solid $color-primary-blue-500;
	}

	// filled state
	&:not(:placeholder-shown) {
		border: 1px solid $color-dark-blue-200;
	}

	&:disabled {
		border: 1px solid $color-grey-300;

		&::placeholder {
			color: $color-grey-300;
		}
	}

	&:invalid,
	&.error {
		border: 3px solid $color-red-700;
	}
}

input {
	&[type='radio'] {
		appearance: none;
		-webkit-appearance: none;
		border: 2px solid $color-grey-600;
		background-color: transparent;
		border-radius: 50%;
		outline: 2px solid transparent;
		padding: 4px;

		&:hover:not(:checked) {
			outline: 8px solid $color-grey-200;
			background-color: $color-grey-200;
		}

		&:focus-visible {
			outline-offset: 0;
		}

		&:checked {
			outline-color: $color-primary-blue-500;
			background-color: $color-primary-blue-500;
			border: 3px solid $color-white;
			animation: outlineChecked;
			animation-duration: 0.15s;
			animation-iteration-count: 2;
			animation-direction: alternate;
			animation-timing-function: linear;
		}

		&:disabled {
			opacity: 0.38;
			cursor: not-allowed;
		}
	}

	&[type='checkbox'] {
		-webkit-appearance: none;
		appearance: none;
		background-color: transparent;
		margin: 0;
		color: $color-grey-600;
		width: 18px;
		height: 18px;
		border: 2px solid $color-grey-600;
		border-radius: 2px;
		transform: translateY(-0.075em);
		display: grid;
		place-content: center;
		transition: all 0.15s cubic-bezier(0.1, 0.1, 0.25, 1);
		position: relative;

		&::before {
			position: absolute;
			content: '';
			display: block;
			top: 0;
			left: 4px;
			width: 6px;
			height: 11px;
			border-style: solid;
			border-color: $color-white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			opacity: 0;
		}

		&:checked {
			border-color: $color-primary-blue-500;
			background: $color-primary-blue-500;

			&::before {
				opacity: 1;
			}
		}

		&:hover {
			outline: 8px solid $color-grey-200;
		}
	}
}

.form {
	&__group {
		position: relative;

		&.error {
			input:not([type='radio']):not([type='checkbox']),
			textarea {
				border: 3px solid $color-red-700;

				&:focus {
					outline: none;
				}
			}

			.form {
				&__label {
					color: $color-red-700;
				}
			}
		}

		&--floating {
			input {
				&:focus,
				&:not(:placeholder-shown) {
					+ label {
						transform: translateY(-35px);
						color: $color-primary-blue-500;
						font-size: rem($px-extra-small);
					}
				}
			}

			label {
				display: flex;
				font-size: rem($px-normal);
				transition: 0.2s ease-in-out;
				color: $color-grey-600;
				cursor: text;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 16px;
				padding: 0;
				align-items: center;
			}

			// TODO: only for tests, remove after production ready
			&.form {
				&__group {
					&--search {
						input {
							&::placeholder {
								transition: all 0.2s ease 0.1s;
							}

							&:focus,
							&:not(:placeholder-shown) {
								+ label {
									transform: translateY(-30px);
									font-size: rem($px-extra-small);
									opacity: 1;
								}

								&::placeholder {
									color: transparent !important;
									transition: 0.2s ease;
								}
							}
						}

						label {
							transform: none;
							font-size: rem($px-small);
							transition: 0.2s ease;
							position: absolute;
							bottom: rem(10);
							left: rem(39);
							opacity: 0;
							width: 100%;
							padding: 0;
							top: unset;
						}
					}
				}
			}
		}

		&--search {
			vertical-align: middle;

			input:not([type='radio']):not([type='checkbox']) {
				padding: rem(10 37);
				line-height: rem(20);
				min-width: rem(230);
				font-size: rem($px-small);

				&::placeholder {
					color: $color-grey-400;
				}
			}

			.form {
				&__icon-search,
				&__icon-close {
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
				}

				&__icon-search {
					left: rem(10);

					@include rightToLeft {
						left: unset;
						right: rem(10);
						transform: scaleX(-1) translate(0, -50%);
					}
				}

				&__icon-close {
					cursor: pointer;
					right: rem(10);
					display: none;

					&.icon-active {
						display: block;
					}

					@include rightToLeft {
						left: rem(10);
						right: unset;
					}
				}
			}
		}
	}

	&__label {
		color: $color-grey-600;
		font-size: rem($px-extra-small);
		padding: rem(0 4);
	}
}

@include device((mobile, large-mobile)) {
	input[type='color'],
	input[type='date'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='email'],
	input[type='month'],
	input[type='number'],
	input[type='password'],
	input[type='search'],
	input[type='tel'],
	input[type='text'],
	input[type='time'],
	input[type='url'],
	input[type='week'],
	textarea {
		font-size: 16px !important;
	}
}

@keyframes outlineChecked {
	0% {
		outline-offset: 0;
	}

	100% {
		outline-offset: -0.2rem;
	}
}

.platform {
	&__info {
		margin: rem(12 0 0);

		@include device((tablet-up)) {
			margin: rem(100 0 0);
		}

		h4 {
			text-align: center;
			margin: rem(16 0);

			@include device((tablet-up)) {
				margin: 0;
				text-align: left;
			}
		}
	}

	&__info-row {
		padding: rem(24 0);
		margin-bottom: rem(32);

		@include device((tablet-up)) {
			display: flex;
			align-items: center;
			gap: rem(40);
			padding: rem(24 50);
		}

		&:last-child {
			margin-bottom: 0;
		}

		&--reverse {
			@include device((tablet-up)) {
				flex-direction: row-reverse;
			}
		}
	}

	&__info-img {
		text-align: center;

		@include device((small-mobile, mobile)) {
			img,
			video {
				width: 100%;
			}
		}
	}

	&__description {
		@include device((tablet-up)) {
			flex: 2;
		}
	}

	&__video-player {
		border-radius: rem(16);
		margin: auto;
		display: flex;
	}
}

@import '../partials/categories-button';

body.page-trading-glossary {
	.main {
		overflow: unset;

		.section-standard {
			position: relative;
			margin-bottom: rem(56);
		}
	}

	.glossary {
		&__top-section {
			display: none;
			top: calc(#{$header-disclaimer-height} + #{$header-height});
			position: fixed;
			width: 100%;
			left: 0;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.glossary {
				&__letters {
					display: none;
					margin-top: 0;

					@include device((tablet-up)) {
						display: flex;
					}
				}
			}

			&--visible {
				display: flex;
				z-index: z-index(sticky-letters);
			}

			&--mobile-open {
				.glossary {
					&__letters {
						display: flex;
					}

					&__mobile-letters-button {
						svg {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		&__letters-wrapper {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $color-white;
			padding: rem(11 0);
			@include shadow-4();
		}

		&__mobile-letters-button {
			background: $color-white;
			padding: rem(0 28);
			border-radius: 0 0 16px 16px;
			box-shadow:
				0 5px 4px rgba(16, 24, 40, 0.16),
				0 5px 8px rgba(16, 24, 40, 0.16);

			@include device((tablet-up)) {
				display: none;
			}
		}

		&__wrapper {
			@include device((tablet-up)) {
				margin: rem(0 100);
			}
		}

		&__group-title {
			@include headers-h3();
			color: $color-grey-900;
			margin-top: rem(8);
		}

		&__item {
			padding: rem(36);
			border-radius: 24px;
			background: $color-white;
			margin: rem($grid-padding 0);
		}

		&__title {
			color: $color-dark-blue-900;
			margin-bottom: rem($grid-padding);
		}

		&__content {
			color: $color-grey-600;
		}

		&__letters {
			@include categories-wrapper();
			margin: rem(24 0 0);

			@include device((tablet-up)) {
				margin: rem(28 0 0);
			}
		}

		&__letter {
			width: 33px;
			@include category-button();
		}

		&__form-group {
			margin-top: rem(24);

			@include device((tablet-up)) {
				min-width: rem(410);
			}
		}
	}

	.search-display-none {
		display: none;
	}
}

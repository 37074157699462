body.page-404 {
	.error-404 {
		padding: rem(84 0 25);
		text-align: center;

		&__content {
			margin-top: rem(45);
		}

		&__title {
			margin: rem(8 0 16);
			font-size: rem(40);
		}

		&__image-container {
			leading-trim: both;
			text-edge: cap;
			-webkit-text-fill-color: rgba(0, 0, 0, 0);
			-webkit-text-stroke: 5px $color-lime-green-200;
			font-family: $font-poppins;
			font-size: rem(150);
			font-weight: $font-superbold;
			line-height: 0.7;

			@include device((tablet-up)) {
				font-size: rem(350);
			}
		}

		&__button-wrapper {
			margin-top: rem(16);
		}
	}
}

@import '../pages/instruments';
@import '../partials/similar-instruments';
@import '../partials/asset-tutorials';
@import '../partials/related-articles';

body.page-how-to-trade,
body.page-how-to-invest-in {
	h1 {
		span {
			font-size: rem(32);
		}
	}

	.how-to {
		&__description {
			line-height: 1.2;

			p {
				color: $color-grey-600;
			}
		}
	}
}

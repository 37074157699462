/* *
 * FONT FAMILY
 */
$font-poppins: 'Poppins', sans-serif;
$font-roboto: 'Roboto', sans-serif;

/* *
 * FONT SIZE
 */
$px-big: 24;
$px-bigger: 20;
$px-normal: 16;
$px-small: 14;
$px-extra-small: 12;

/* *
 * FONT WEIGHT
 */
$font-light: 300; // only Roboto
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600; // only Poppins
$font-bold: 700;
$font-superbold: 800; // only Poppins

/* *
 * COLORS
 */
$color-white: rgba(255, 255, 255, 1);
$color-grey-25: rgba(255, 255, 255, 1);
$color-grey-50: rgba(249, 250, 251, 1);
$color-grey-100: rgba(242, 244, 247, 1);
$color-grey-200: rgba(228, 231, 236, 1);
$color-grey-300: rgba(208, 213, 221, 1);
$color-grey-400: rgba(152, 162, 179, 1); // #98A2B3
$color-grey-500: rgba(102, 112, 133, 1);
$color-grey-600: rgba(71, 84, 103, 1); // 475467FF
$color-grey-700: rgba(52, 64, 84, 1);
$color-grey-800: rgba(29, 41, 57, 1);
$color-grey-900: rgba(16, 24, 40, 1);
$color-primary-blue-25: rgba(228, 249, 255, 1);
$color-primary-blue-50: rgba(203, 243, 255, 1);
$color-primary-blue-100: rgba(170, 232, 251, 1); // #AAE8FB
$color-primary-blue-200: rgba(123, 216, 244, 1);
$color-primary-blue-300: rgba(72, 199, 238, 1);
$color-primary-blue-400: rgba(32, 178, 221, 1);
$color-primary-blue-500: rgba(14, 164, 209, 1); // #0EA4D1
$color-primary-blue-600: rgba(11, 132, 168, 1);
$color-primary-blue-700: rgba(13, 102, 142, 1);
$color-primary-blue-800: rgba(8, 75, 105, 1);
$color-primary-blue-900: rgba(6, 55, 77, 1);
$color-dark-blue-25: rgba(232, 237, 249, 1);
$color-dark-blue-50: rgba(197, 207, 230, 1);
$color-dark-blue-100: rgba(155, 169, 202, 1);
$color-dark-blue-200: rgba(131, 149, 190, 1);
$color-dark-blue-300: rgba(111, 129, 168, 1);
$color-dark-blue-400: rgba(84, 102, 143, 1);
$color-dark-blue-500: rgba(77, 94, 130, 1);
$color-dark-blue-600: rgba(64, 77, 107, 1);
$color-dark-blue-700: rgba(38, 48, 69, 1);
$color-dark-blue-800: rgba(29, 36, 52, 1);
$color-dark-blue-900: rgba(23, 28, 39, 1); // #171C27
$color-red-25: rgba(255, 233, 233, 1);
$color-red-50: rgba(253, 207, 207, 1);
$color-red-100: rgba(252, 180, 180, 1);
$color-red-200: rgba(249, 144, 144, 1);
$color-red-300: rgba(249, 111, 111, 1);
$color-red-400: rgba(252, 93, 93, 1);
$color-red-500: rgba(249, 76, 76, 1); // #F94C4C
$color-red-600: rgba(216, 69, 69, 1);
$color-red-700: rgba(184, 53, 53, 1);
$color-red-800: rgba(138, 41, 41, 1);
$color-red-900: rgba(85, 23, 23, 1);
$color-lime-green-25: rgba(235, 255, 202, 1);
$color-lime-green-50: rgba(218, 255, 158, 1);
$color-lime-green-100: rgba(188, 255, 79, 1);
$color-lime-green-200: rgba(158, 255, 0, 1);
$color-lime-green-300: rgba(144, 232, 0, 1);
$color-lime-green-400: rgba(129, 208, 0, 1);
$color-lime-green-500: rgba(111, 179, 0, 1);
$color-lime-green-600: rgba(91, 147, 0, 1);
$color-lime-green-700: rgba(58, 94, 0, 1);
$color-lime-green-800: rgba(38, 61, 0, 1);
$color-lime-green-900: rgba(28, 45, 0, 1);
$color-orange-25: rgba(255, 250, 245, 1);
$color-orange-50: rgba(255, 246, 237, 1);
$color-orange-100: rgba(255, 234, 213, 1);
$color-orange-200: rgba(253, 220, 171, 1);
$color-orange-300: rgba(254, 178, 115, 1);
$color-orange-400: rgba(253, 133, 58, 1);
$color-orange-500: rgba(251, 101, 20, 1);
$color-orange-600: rgba(236, 74, 10, 1);
$color-orange-700: rgba(196, 50, 10, 1);
$color-orange-800: rgba(156, 42, 16, 1);
$color-orange-900: rgba(126, 36, 16, 1);
$color-yellow-25: rgba(255, 253, 214, 1);
$color-yellow-50: rgba(255, 251, 162, 1);
$color-yellow-100: rgba(255, 249, 99, 1);
$color-yellow-200: rgba(255, 245, 0, 1);
$color-yellow-300: rgba(245, 235, 0, 1);
$color-yellow-400: rgba(229, 206, 0, 1);
$color-yellow-500: rgba(208, 187, 0, 1);
$color-yellow-600: rgba(183, 164, 0, 1);
$color-yellow-700: rgba(141, 118, 0, 1);
$color-yellow-800: rgba(100, 90, 0, 1);
$color-yellow-900: rgba(65, 59, 0, 1);

/* *
 * GRADIENTS
 */
$gradient-primary-blue: linear-gradient(90deg, #0d668e 0%, #20b2dd 100%);
$gradient-dark-blue: linear-gradient(
	to bottom,
	rgba(23, 28, 39, 0.09) 0%,
	rgba(38, 48, 69, 0.149) 100%
);
$gradient-red: linear-gradient(
	to bottom,
	rgba(184, 53, 53, 0.722) 0%,
	rgba(249, 76, 76, 0.976) 100%
);
$gradient-green: linear-gradient(
	to bottom,
	rgba(129, 208, 0, 0.506) 0%,
	rgba(158, 255, 0, 0.62) 100%
);
$gradient-blue-green: linear-gradient(102deg, #0ea4d1 1.95%, #9eff00 101.55%);
$gradient-blue-white-green: linear-gradient(
	102deg,
	#20b2dd 1.95%,
	#cbf3ff 50.09%,
	#9eff00 101.55%
);
$gradient-02: linear-gradient(270deg, #00c2ff 0%, #0c658d 100%);

/* *
 * SHADOWS
 */
@mixin shadow-2() {
	box-shadow:
		0 2px 4px 0px rgba(16, 24, 40, 0.18),
		0 1px 2px 0 rgba(16, 24, 40, 0.18);
}

@mixin shadow-4() {
	box-shadow:
		0px 2px 4px rgba(16, 24, 40, 0.16),
		0px 4px 8px rgba(16, 24, 40, 0.16);
}

@mixin shadow-8() {
	box-shadow:
		0px 4px 8px rgba(16, 24, 40, 0.14),
		0px 8px 16px rgba(16, 24, 40, 0.14);
}

@mixin shadow-16() {
	box-shadow:
		0px 8px 16px 2px rgba(16, 24, 40, 0.1),
		0px 16px 32px 2px rgba(16, 24, 40, 0.1);
}

@mixin shadow-24() {
	box-shadow:
		0px 12px 24px 6px rgba(16, 24, 40, 0.08),
		0px 24px 48px 6px rgba(16, 24, 40, 0.08);
}

@mixin shadow-32() {
	box-shadow:
		0px 24px 48px 10px rgba(16, 24, 40, 0.06),
		0px 32px 64px 10px rgba(16, 24, 40, 0.06);
}

/* *
 * Z-index
 */

// List of core elements used in project
$z-index-list: basic, store-buttons-qr, sticky-letters, chart-intervals,
	dropdown, cookies, livechat, scroll-to-top, mobileMenu, header,
	headerDisclaimer, headerDisclaimerMobile, headerDisclaimerButton,
	filtersModal, language-menu, lightbox, lightboxContent, modalBackdrop,
	livechatWithGeoBlocking, modal, special;

/* *
 * RWD
 */

$rwd-break-extra-large-device: 1860px;
$rwd-break-large-device: 1366px;
$rwd-break-standard-device: 1024px;
$rwd-break-medium-device: 765px;
$rwd-break-small-device: 525px;
$rwd-break-extra-small-device: 320px;

$grid-columns: 24;
$grid-padding: 16px;
$tablet-side-padding: 48px;

/* *
 * Elements sizes
 */

$header-disclaimer-height: 52px;
$header-height: 64px;
$instrument-logo-default-size: 90px;

$transition-standard: all 0.2s ease-out;

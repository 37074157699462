@import '../partials/similar-instruments';
@import '../partials/pagination';
@import '../partials/instrument-tile';
@import '../partials/related-articles';

$logoSize: 50px;

body.page-instrument-single-item,
body.page-how-to-trade,
body.page-how-to-invest-in {
	@include device((tablet-up)) {
		overflow-x: hidden;
	}

	h1 {
		font-family: $font-poppins;
		font-size: rem(32);
		font-weight: $font-semibold;
		line-height: 1.1;
		margin: rem(0 0 4);

		a {
			color: $color-dark-blue-900;
		}

		@include device((tablet-up)) {
			font-size: rem(48);
			line-height: 1.2;
			margin: 0;
		}
	}

	h2 {
		@include headers-h5();
		margin: rem(0 0 16);
	}

	h3 {
		font-size: rem(40);
	}

	.main {
		@include device((tablet-up)) {
			overflow: unset;
		}
	}

	.instrument {
		$instrument: &;

		&__columns {
			position: relative;

			@include device((tablet-up)) {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-direction: row;
				gap: 41px;
			}
		}

		&__main-column {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 64px;
		}

		&__main-column-bg {
			padding: rem(36 16);
			border-radius: 24px;
			background: $color-white;
			display: flex;
			flex-direction: column;
			gap: 24px;

			@include device((tablet-up)) {
				padding: rem(36);
			}
		}

		&__box-column,
		&__box-column-placeholder {
			@include device((tablet-up)) {
				width: rem(320);
			}
		}

		&__box-column {
			border-radius: 24px;
			background: $color-white;
			padding: rem(36 24);
			box-shadow:
				0 24px 48px 6px rgba(16, 24, 40, 0.08),
				0 12px 24px 6px rgba(16, 24, 40, 0.08);
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			gap: 36px;
			margin-top: rem(64);

			@include device((tablet-up)) {
				margin-top: 0;
				position: sticky;
				top: calc(
					#{$header-disclaimer-height} + #{$header-height} + 53px
				); // 53 - breadcrumbs
			}
		}

		&__top-content,
		&__top-content-wrapper {
			display: flex;

			align-items: flex-start;
			justify-content: space-between;
		}

		&__top-content {
			flex-direction: row;
			gap: 16px;
		}

		&__top-content-wrapper {
			flex-direction: column;
			flex: 1;
			gap: 12px;
		}

		&__logo {
			position: relative;
			width: $logoSize;
			height: $logoSize;
			margin-top: rem(5);

			.instrument-logo {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) scale(#{scaleLogo($logoSize)});
			}
		}

		&__prices-section {
			flex: 1;
			line-height: 1.2;

			@include device((tablet-up)) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
		}

		&__prices-row {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 12px;
		}

		&__price {
			font-family: $font-poppins;
			font-size: rem($px-big);
			font-weight: $font-semibold;
			color: $color-grey-600;
		}

		&__chart {
			height: 350px;
			position: relative;

			.chart-widget-container {
				height: 100%;
			}
		}

		&__intervals-wrapper {
			display: flex;
			gap: rem(8);
			position: absolute;
			top: rem(12);
			left: rem(12);
			z-index: z-index(chart-intervals);
		}

		&__chart-interval {
			display: flex;
			width: rem(28);
			height: rem(28);
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			border: 1px solid $color-grey-200;
			background: $color-white;
			font-family: $font-poppins;
			font-size: rem(12);
			font-weight: $font-medium;
			line-height: 1;
			cursor: pointer;

			&--active {
				cursor: default;
				background: $color-grey-100;
			}
		}

		&__symbol-section {
			color: $color-grey-300;
			font-family: $font-poppins;
			font-size: rem(24);
			line-height: 1.2;

			@include device((tablet-up)) {
				font-size: rem(48);
			}
		}

		&__statistics {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 16px;

			@include device((tablet-up)) {
				grid-template-columns: repeat(5, 1fr);
			}

			&--hours {
				grid-template-columns: repeat(2, 1fr);

				@include device((tablet-up)) {
					grid-template-columns: repeat(7, 1fr);
				}
			}
		}

		&__statistic-item {
			border-radius: 8px;
			background: $color-grey-100;
			display: flex;
			gap: 8px;
			padding: rem(16);
			line-height: 1.2;
			flex-direction: column;
			justify-content: space-between;

			&--span-2 {
				grid-column: span 2;
			}
		}

		&__statistic-title {
			color: $color-grey-500;
			font-size: rem(10);
		}

		&__buttons {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__section-description {
			border-radius: 24px;
			background: $color-white;
			padding: rem(36);
			margin: rem(24 0);
			line-height: 1.2;

			p {
				color: $color-grey-600;
			}
		}

		&__long-description {
			transition: all 0.7s;
			max-height: rem(4000);
			opacity: 1;
			overflow: hidden;

			&.hidden {
				max-height: 0;
				opacity: 0;
			}
		}

		&__close-text {
			display: none;
		}

		&__read-more-btn {
			text-align: center;
			font-family: $font-poppins;
			line-height: 2;

			a {
				&.active {
					.instrument {
						&__read-more-text {
							display: none;
						}

						&__close-text {
							display: inline-flex;
						}
					}
				}

				.btn {
					font-weight: $font-regular;
				}
			}
		}

		&__similar {
			position: relative;
		}

		&__similar-pagination {
			position: absolute;
			top: 0;
			right: 0;

			@include rightToLeft {
				left: 0;
				right: unset;
			}
		}

		&__similar-title {
			@include headers-h3();
			margin-bottom: rem(24);
		}

		&__calculator-form-row {
			display: flex;
			align-items: center;
			margin-bottom: rem(10);

			> div {
				flex: 1;
			}

			&--value {
				position: relative;

				input {
					text-align: right;
					padding-right: rem(35);
				}
			}
		}

		&__calculator-symbol-currency {
			position: absolute;
			right: 10px;
		}

		&__calculator-currency-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.custom-select__wrapper {
				min-width: rem(200);
				margin-left: rem(20);
			}
		}

		&__related-content {
			margin-top: rem(64);
			padding-bottom: rem(10);
		}

		&__related-head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: rem(24);

			.instrument {
				&__similar-title {
					margin-bottom: 0;
				}
			}
		}

		&__trading-hours-wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: rem(16);
			flex-direction: column;
			align-items: flex-start;
			gap: rem(16);

			@include device((tablet-up)) {
				flex-direction: row;
				align-items: center;
				gap: 0;
			}

			.custom-select {
				&__wrapper {
					min-width: rem(200);

					@include device((small-mobile, mobile, large-mobile)) {
						display: none;
					}
				}
			}

			&.active {
				.custom-select {
					&__wrapper {
						display: block;
					}
				}
			}
		}

		&__trading-hours-title {
			svg {
				@include device((tablet-up)) {
					display: none;
				}
			}

			@include device((small-mobile, mobile, large-mobile)) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;

				&.active {
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}

		&__trading-hours-section {
			@include device((small-mobile, mobile, large-mobile)) {
				transition: all 0.7s;
				max-height: rem(800);
				opacity: 1;
				overflow: hidden;

				&.hidden {
					max-height: 0;
					opacity: 0;
				}
			}
		}

		&__trading-hours-value {
			font-size: rem(13);
			line-height: 1.4;
		}

		&__trading-specification-title {
			display: flex;
			gap: 8px;
			cursor: pointer;
			justify-content: space-between;

			&.active {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		&__trading-specifications {
			transition: all 0.7s;
			max-height: rem(800);
			opacity: 1;
			overflow: hidden;
			padding-top: rem(16);

			&.hidden {
				max-height: 0;
				opacity: 0;
			}
		}
	}
}

body.page-instrument-single-item {
	.instrument {
		&__box-column {
			margin-bottom: rem(64);

			@include device((tablet-up)) {
				margin-bottom: rem(80);
			}
		}
	}
}

.platform {
	&__store-buttons-row {
		display: flex;
		gap: rem(16);
		justify-content: center;

		@include device((tablet-up)) {
			justify-content: left;
		}
	}

	&__button-wrapper {
		position: relative;
	}
}

.app-store-button {
	&__blue-border {
		display: block;
		width: 178px;
		height: 66.5px;
		background: url('/assets/mobile-app/app-store-blue-border.png') -0 -133px
			no-repeat;
		background-size: 100%;

		&:hover {
			background-position: 0 -66.5px;
		}

		&:active {
			background-position: 0 0;
		}

		&--small {
			width: 66px;
			background: url('/assets/mobile-app/app-store-blue-border-small.png')
				0 -133px no-repeat;
			background-size: 100%;

			&:hover {
				background-position: 0 -66.5px;
			}

			&:active {
				background-position: 0 0;
			}
		}
	}
}

.google-play-button {
	&__blue-border {
		display: block;
		width: 175px;
		height: 66px;
		background: url('/assets/mobile-app/google-play-blue-border.png') 0 -66px
			no-repeat;
		background-size: 100%;

		&:hover {
			background-position: 0 0;
		}

		&:active {
			background-position: -0 -132.5px;
			height: 66.5px;
		}

		&--small {
			width: 66px;
			background: url('/assets/mobile-app/google-play-blue-border-small.png')
				0 -132px no-repeat;
			background-size: 100%;

			&:hover {
				background-position: 0 0;
			}

			&:active {
				background-position: 0 -66px;
				height: 66px;
			}
		}
	}
}

@import '../partials/related-articles';
@import '../partials/collection-filters';
@import '../partials/categories-button';

body.page-education {
	.education {
		&__categories-wrapper {
			@include categories-wrapper();
			gap: 8px;
			justify-content: flex-start;
		}

		&__category-item {
			@include category-button();
			padding: rem(0 10);
		}
	}

	.section-standard {
		&__content {
			.form {
				&__group {
					margin: rem(24 0);

					@include device((tablet-up)) {
						margin: rem(40 0);
					}
				}
			}
		}

		&__text {
			&--no-margin {
				margin: 0;
			}

			&--padding {
				padding-top: rem(16);
			}
		}
	}

	.pagination {
		&__wrapper {
			margin: rem(16 0 64);
		}
	}

	.display-none {
		display: none;
	}
}

body.page-affiliate-calculator {
	.affiliate-calculator {
		&__wrapper {
			@include device((desktop-up)) {
				width: 40%;
				margin: rem(40 auto 20);
			}
		}

		&__summary {
			margin: rem(36 0 24);
			background: #012846;
			color: #f2f2f2;
			padding: rem(16);
			font-family: $font-poppins;
			border-radius: 4px;

			div {
				display: flex;
				justify-content: space-between;

				&:first-child {
					border-bottom: 1px solid #efefef;
					padding-bottom: rem(10);
					margin-bottom: rem(10);
				}
			}
		}

		&__form {
			div {
				&:first-child {
					.affiliate-calculator {
						&__remove-btn {
							display: none;
						}
					}
				}
			}
		}

		&__form-row {
			display: flex;
			align-items: center;
			margin: rem(9 0);

			> div {
				flex: 1;
				margin-right: rem(16);
			}
		}

		&__remove-btn {
			font-family: $font-poppins;
			font-size: rem($px-small);
			display: flex;
			align-items: center;

			svg {
				margin-right: rem(5);
			}
		}

		&__add-btn {
			margin: rem(16 0 24);

			.btn {
				transition: all 0.3s ease;

				&.hidden {
					visibility: hidden;
				}
			}
		}

		&__tier-level {
			@include device((desktop-up)) {
				max-width: 40%;
			}
		}
	}
}

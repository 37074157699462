button,
.btn {
	@include resetButton();
}

.btn,
a.btn,
%btn {
	position: relative;
	font-family: $font-poppins;
	font-weight: $font-semibold;
	font-size: rem($px-normal);
	text-align: center;
	background: $color-primary-blue-500;
	color: $color-white;
	border: none;
	box-shadow:
		0 1px 2px 0 rgba(16, 24, 40, 0.18),
		0 2px 4px 0 rgba(16, 24, 40, 0.18);
	border-radius: 100px;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: rem(32);
	padding: rem(12 64);
	min-width: rem(200);
	width: 100%;
	transition: all 0.3s ease-out;
	white-space: nowrap;
	-webkit-tap-highlight-color: transparent;

	@include device((tablet-up)) {
		width: auto;
	}

	&:hover {
		background: $color-primary-blue-600;
		box-shadow:
			0 4px 8px 0 rgba(16, 24, 40, 0.14),
			0 8px 16px 0 rgba(16, 24, 40, 0.14);
		color: $color-white;
	}

	&:active {
		color: $color-white;
		background: $color-primary-blue-700;
		box-shadow: none;
		transition: none;
	}

	&:disabled,
	&.disabled {
		background: $color-grey-400;
		box-shadow: none;
		cursor: not-allowed;
	}

	.new-window-icon {
		path {
			stroke: $color-white;
		}
	}

	svg {
		@include rightToLeft {
			transform: scaleX(-1);
		}
	}

	&--secondary {
		background: $color-white;
		color: $color-dark-blue-900;
		box-shadow:
			0 1px 2px 0 rgba(16, 24, 40, 0.18),
			0 2px 4px 0 rgba(16, 24, 40, 0.18);

		&:hover {
			background: $color-white;
			color: $color-dark-blue-700;
			box-shadow:
				0 4px 8px 0 rgba(16, 24, 40, 0.14),
				0 8px 16px 0 rgba(16, 24, 40, 0.14);
		}

		&:active {
			background: $color-white;
			color: $color-primary-blue-700;
			box-shadow:
				0 1px 2px 0 rgba(16, 24, 40, 0.18),
				0 2px 4px 0 rgba(16, 24, 40, 0.18);
		}

		&:disabled,
		&.disabled {
			background: $color-grey-300;
			background: $color-white;
			box-shadow: none;
		}
	}

	&--tertiary {
		background: transparent;
		color: $color-primary-blue-500;
		border: 1px solid $color-primary-blue-500;
		box-shadow: none;

		path {
			stroke: $color-primary-blue-500;
		}

		.new-window-icon {
			path {
				stroke: $color-primary-blue-500;
			}
		}

		&:hover {
			color: $color-primary-blue-600;
			border: 1px solid $color-primary-blue-600;
			background: $color-white;
			box-shadow:
				0 1px 2px 0 rgba(16, 24, 40, 0.18),
				0 2px 4px 0 rgba(16, 24, 40, 0.18);

			path {
				stroke: $color-primary-blue-600;
			}
		}

		&:active {
			background: $color-white;
			color: $color-primary-blue-700;
			border: 1px solid $color-primary-blue-700;
			outline: 1px solid $color-primary-blue-700;
			box-shadow: none;

			path {
				stroke: $color-primary-blue-700;
			}
		}

		&:disabled,
		&.disabled {
			border: 1px solid $color-grey-400;
			box-shadow: none;
			background: transparent;
			color: $color-grey-400;

			path {
				stroke: $color-primary-blue-400;
			}
		}
	}

	&--tertiary-on-dark {
		background: transparent;
		color: $color-white;
		border: 1px solid $color-white;
		box-shadow: none;

		&:hover {
			color: $color-white;
			border: 1px solid $color-white;
			background: rgba(255, 255, 255, 0.1);
			box-shadow: none;
		}

		&:active {
			background: rgba(255, 255, 255, 0.1);
			color: $color-primary-blue-100;
			border: 1px solid $color-primary-blue-100;
			outline: 1px solid $color-primary-blue-100;
			box-shadow: none;

			path {
				stroke: $color-primary-blue-100;
			}
		}

		&:disabled,
		&.disabled {
			background: transparent;
			border: 1px solid $color-grey-600;
			box-shadow: none;
			color: $color-grey-600;
		}
	}

	&--tertiary-on-lime {
		background: transparent;
		color: $color-dark-blue-900;
		border: 1px solid $color-dark-blue-900;
		box-shadow: none;

		&:hover {
			color: $color-dark-blue-700;
			border: 1px solid $color-dark-blue-700;
			background: transparent;
			box-shadow: none;
		}

		&:active {
			background: transparent;
			color: $color-dark-blue-700;
			border: 1px solid $color-dark-blue-700;
			outline: 1px solid $color-dark-blue-700;
			box-shadow: none;

			path {
				stroke: $color-dark-blue-700;
			}
		}

		&:disabled,
		&.disabled {
			background: transparent;
			border: 1px solid $color-grey-600;
			box-shadow: none;
			color: $color-grey-600;
		}
	}

	&--qaternary {
		background: transparent;
		box-shadow: none;
		color: $color-primary-blue-500;

		svg {
			stroke: $color-primary-blue-500;
		}

		.new-window-icon {
			path {
				stroke: $color-primary-blue-500;
			}
		}

		&:hover {
			background: transparent;
			box-shadow: none;
			color: $color-primary-blue-600;

			svg {
				stroke: $color-primary-blue-600;
			}
		}

		&:active {
			background: transparent;
			box-shadow: none;
			color: $color-primary-blue-700;

			svg {
				stroke: $color-primary-blue-700;
			}
		}

		&:disabled,
		&.disabled {
			background: transparent;
			box-shadow: none;
			color: $color-grey-400;

			svg {
				stroke: $color-grey-400;
			}
		}
	}

	&--red {
		background: $color-red-500;
		color: $color-white;
		box-shadow:
			0 1px 2px 0 rgba(16, 24, 40, 0.18),
			0 2px 4px 0 rgba(16, 24, 40, 0.18);

		&:hover {
			background: $color-red-600;
			box-shadow:
				0 4px 8px 0 rgba(16, 24, 40, 0.14),
				0 8px 16px 0 rgba(16, 24, 40, 0.14);
			color: $color-white;
		}

		&:active {
			color: $color-white;
			background: $color-red-700;
			box-shadow: none;
			transition: none;
		}

		&:disabled,
		&.disabled {
			background: $color-red-400;
			box-shadow: none;
			cursor: not-allowed;
		}
	}

	&--yellow {
		background: $color-yellow-300;
		color: $color-dark-blue-900;
		box-shadow:
			0 1px 2px 0 rgba(16, 24, 40, 0.18),
			0 2px 4px 0 rgba(16, 24, 40, 0.18);

		&:hover {
			background: $color-yellow-300;
			box-shadow:
				0 4px 8px 0 rgba(16, 24, 40, 0.14),
				0 8px 16px 0 rgba(16, 24, 40, 0.14);
			color: $color-dark-blue-900;
		}

		&:active {
			color: $color-dark-blue-900;
			background: $color-yellow-400;
			box-shadow: none;
			transition: none;
		}

		&:disabled,
		&.disabled {
			background: $color-yellow-500;
			box-shadow: none;
			cursor: not-allowed;
		}
	}

	&--big {
		padding: rem(16 64);
		font-size: rem(18);
	}

	&--small {
		padding: rem(8 64);
		font-size: rem($px-small);
	}

	&--icon-left {
		svg {
			margin-right: rem(10);

			@include rightToLeft {
				margin-right: unset;
				margin-left: rem(10);
			}
		}
	}

	&--icon,
	&--icon-right {
		align-items: center !important;
		gap: rem(10) !important;
	}
}

a.btn {
	&:disabled,
	&.disabled {
		pointer-events: none;
	}
}

.new-window-icon {
	@include rightToLeft {
		transform: scaleX(-1);
	}
}

@import 'variables';

.app-store-button,
.google-play-button {
	transition: all 0.1s ease-out;

	rect {
		stroke: $color-white;
	}

	&:hover {
		rect {
			fill: $color-white;
			fill-opacity: 0.1;
		}
	}

	&:active {
		rect {
			fill: $color-white;
			fill-opacity: 0.1;
			stroke: $color-primary-blue-100;
		}
	}

	&--small {
		svg {
			width: 67px;
		}

		rect {
			width: 65px;
		}

		.app-store-button-text,
		.google-button-text {
			display: none;
		}
	}
}

.app-store-button {
	&:hover {
		path {
			fill: $color-white;
		}
	}

	&:active {
		path {
			fill: $color-primary-blue-100;
		}
	}

	&--small {
		path {
			transform: translateX(1px);
		}
	}
}

.google-play-button {
	&:hover {
		.google-button-text {
			fill: $color-white;
		}
	}

	&:active {
		.google-button-text {
			fill: $color-primary-blue-100;
		}
	}
}

.store-button {
	&__wrapper {
		position: relative;
	}

	&__qr-wrapper {
		display: none;
		position: absolute;
		padding: rem(16);
		width: 250px;
		height: 250px;
		top: -235px; // height - 15px padding
		left: 154px; // button width - 15px padding
		visibility: hidden;
		opacity: 0;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: rem(10);
		border-radius: rem(16 16 16 0);
		background: $color-white;
		box-shadow:
			0 8px 16px 2px rgba(16, 24, 40, 0.1),
			0 16px 32px 2px rgba(16, 24, 40, 0.1);
		z-index: z-index(store-buttons-qr);
		pointer-events: none;

		@include device((desktop-up)) {
			display: flex;
		}

		&.active {
			visibility: visible;
			opacity: 1;
		}

		&--small {
			border-radius: rem(16 16 0 16);
			left: -240px; // wrapper width - 10px padding
		}

		&--top {
			transition: 0.2s ease-in-out;
			visibility: visible;
			opacity: 1;
		}

		&--bottom {
			bottom: -235px;
			border-radius: rem(0 16 16 16);
			transition: none;
			top: unset;
			visibility: visible;
			opacity: 1;

			&.store-button {
				&__qr-wrapper {
					&--small {
						border-radius: rem(16 0 16 16);
					}
				}
			}
		}
	}

	&__qr-title {
		color: $color-grey-600;
		font-family: $font-poppins;
		font-size: rem(14);
		font-weight: $font-medium;
		line-height: normal;
		text-align: center;
	}
}

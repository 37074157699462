.collapsible {
	display: flex;
	flex-direction: column;

	&__toggle {
		cursor: pointer;
		transition: all 0.2s ease-out;

		&.active {
			.chevron {
				transform: rotate(180deg);
			}
		}
	}

	&__content {
		max-height: 0;
		overflow: hidden;
		transition: all 0.3s linear;
	}
}

%show-new-page-indicators {
	.badge-new {
		margin-left: rem(8);
		visibility: hidden;
	}

	&.new {
		.dot,
		.badge-new {
			visibility: visible;
		}
	}
}

.header {
	$parent: &;
	width: 100%;
	height: $header-height;
	display: flex;
	align-items: center;
	background: $color-grey-100;
	direction: ltr;
	@include shadow-2();

	&__container {
		position: sticky;
		top: 0;
		left: 0;
		z-index: z-index(header);
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@include device((tablet-up)) {
			height: 100%;

			@include rightToLeft {
				direction: rtl;
			}
		}

		@include device((large-desktop)) {
			padding: 0;
		}
	}

	&__logo {
		a {
			display: block;
			line-height: 1;
		}
	}

	&__right-column {
		height: 100%;
		display: flex;
		align-items: center;
	}

	&__menu {
		display: none;
		height: 100%;

		@include device((desktop-up)) {
			display: flex;
			align-items: flex-end;
			justify-items: center;
		}

		@include device((large-desktop)) {
			margin-right: rem(64);
		}
	}

	&__submenu-text {
		display: inline-flex;
		flex-direction: column;
		gap: rem(4);
	}

	&__submenu-description {
		color: $color-grey-400;
		font-size: rem($px-extra-small);
		font-weight: $font-regular;
	}

	&__menu-link {
		height: 100%;

		&:hover {
			#{$parent} {
				&__submenu {
					display: inherit;
				}

				&__chevron {
					transform: rotate(180deg);

					path {
						stroke: $color-primary-blue-600;
					}
				}
			}
		}

		& a {
			display: flex;
			align-items: center;
			height: 100%;
			text-decoration: none;
			cursor: pointer;
			font-family: $font-poppins;
			font-size: rem($px-small);
			font-weight: $font-medium;
			color: $color-dark-blue-900;
			text-wrap: nowrap;
			padding: rem(12 16);

			@include device((tablet)) {
				padding: rem(12 4);
			}

			&:hover {
				color: $color-primary-blue-600;

				path {
					stroke: $color-primary-blue-600;
				}

				svg {
					&.fill {
						path {
							stroke: unset;

							&.fill {
								fill: $color-primary-blue-600;
							}
						}
					}
				}
			}

			&.active {
				color: $color-primary-blue-500;
			}
		}

		@extend %show-new-page-indicators;
	}

	&__chevron {
		margin-left: rem(4);
	}

	&__submenu {
		display: none;
		position: absolute;
		top: rem($header-height);
		background-color: $color-grey-100;
		padding: rem($grid-padding);
		min-width: rem(290);
		z-index: z-index(header);
		@include shadow-2();

		&:hover {
			display: inherit;
		}

		a {
			margin: 0;
			padding: rem(16 32);
			font-size: rem($px-small);
			font-style: normal;
			font-weight: $font-medium;
			line-height: normal;
			text-wrap: nowrap;
			color: $color-dark-blue-900;
			font-family: $font-poppins;
			border-bottom: 1px solid $color-grey-200;
			display: flex;
			align-items: flex-start;
			gap: rem(12);

			&:last-child {
				border-bottom: none;
			}

			&.btn {
				&--qaternary {
					color: $color-primary-blue-500;

					svg {
						stroke: $color-primary-blue-500;
					}

					&:hover {
						color: $color-primary-blue-600;

						svg {
							stroke: $color-primary-blue-600;
						}
					}

					&:active,
					&.active {
						color: $color-primary-blue-700;

						svg {
							stroke: $color-primary-blue-700;
						}
					}
				}
			}
		}
	}

	&__menu-mobile {
		position: fixed;
		left: 100%;
		top: rem($header-height + $header-disclaimer-height);
		height: calc(100vh - #{$header-disclaimer-height} - #{$header-height});
		width: 100vw;
		z-index: z-index(mobileMenu);
		overflow-x: scroll;
		transition: all 0.5s ease;
		background: $color-grey-100;

		@supports (height: 100dvh) {
			height: calc(
				100dvh - #{$header-disclaimer-height} - #{$header-height}
			);
		}

		@include rightToLeft {
			direction: rtl;
		}

		&--open {
			left: 0;
		}

		.collapsible {
			&__toggle {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			&__content {
				display: grid;
			}

			a {
				display: grid;
				grid-template-columns: 25px 1fr min-content;
				font-family: $font-poppins;
				font-size: rem($px-small);
				color: $color-dark-blue-900;
				text-decoration: none;
				padding: rem(16 32);
				gap: rem(12);
				border-bottom: 1px solid $color-grey-200;
				font-style: normal;
				font-weight: $font-medium;
				line-height: normal;

				&:hover,
				&:active,
				&.active {
					color: $color-primary-blue-500;
				}

				&#{$parent} {
					&__mobile-menu-goto {
						text-align: center;
						color: $color-primary-blue-500;
						font-size: rem($px-small);
						font-style: normal;
						font-weight: $font-semibold;
						line-height: normal;
						display: flex;
					}
				}
			}

			.dot {
				top: rem(-8);
				left: rem(4);
			}

			@extend %show-new-page-indicators;
		}
	}

	&__mobile-menu-block-title {
		color: $color-dark-blue-900;
		font-family: $font-poppins;
		font-weight: $font-medium;
		padding: rem(12 16);
		border-bottom: 1px solid $color-grey-200;
		line-height: rem(24);

		&.active {
			color: $color-primary-blue-500;

			.chevron {
				&::before,
				&::after {
					background: $color-primary-blue-500;
				}
			}
		}

		&--block {
			display: block;
		}
	}

	&__menu-mobile-wrapper {
		width: 100%;
		height: 100%;
		padding: rem($grid-padding $grid-padding 36);
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	&__menu-mobile-main-column {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	&__menu-mobile-buttons {
		padding: rem(8 0);
		display: flex;
		gap: rem(8);
		margin-bottom: rem(16);

		.btn {
			min-width: unset;
			white-space: nowrap;
			line-height: 23px;
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}
	}

	&__menu-mobile-container {
		margin-top: rem(16);
		overflow: scroll;
		height: 100%;
	}

	&__language-select {
		position: relative;
		display: flex;
		align-items: center;
		gap: rem(4);
		min-height: rem(35);

		.languages-select {
			&__icon {
				position: absolute;
				pointer-events: none;

				&--next {
					right: 0;
				}
			}
		}

		label {
			display: flex;
			align-items: center;
			gap: rem(4);
		}

		select {
			font-family: $font-poppins;
			color: $color-primary-blue-500;
			font-size: rem($px-small);
			font-weight: $font-medium;
			border: none;
			background: transparent;
			padding-left: rem(28);
			min-height: inherit;
			width: 100%;
			-moz-appearance: none;
			-webkit-appearance: none;
			line-height: 2;

			@include rightToLeft {
				padding-left: unset;
				padding-right: rem(28);
			}

			&:focus {
				outline: none;
			}
		}
	}

	&__sign-up {
		display: none;

		@include device((desktop-up)) {
			display: block;
			margin: rem(0 8);

			.btn {
				padding-left: 24px;
				padding-right: 24px;
				line-height: 23px;
				min-width: unset;
			}
		}
	}

	&__mobile-menu {
		display: flex;
		align-items: center;

		@include device((desktop-up)) {
			display: none;
		}

		.btn {
			min-width: unset;
			padding-left: 24px;
			padding-right: 24px;
			line-height: 23px;
			margin-right: rem(4);
		}
	}

	&__languages {
		position: relative;
		display: none;

		@include device((desktop-up)) {
			display: block;
		}

		&--mobile {
			display: block;
			padding-top: rem(4);

			@include device((desktop-up)) {
				display: none;
			}
		}

		&:hover {
			.header {
				&__languages-chevron {
					path {
						stroke: $color-primary-blue-600;
					}
				}
			}
		}

		&.open,
		&:active {
			.header {
				&__languages-list {
					display: grid;
				}

				&__languages-chevron {
					transform: rotate(180deg);
					stroke: $color-primary-blue-700;
				}
			}
		}
	}

	&__current-lang {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: $color-primary-blue-500;
		font-family: $font-poppins;
		font-weight: $font-medium;
		font-size: rem($px-small);
		text-transform: uppercase;
		margin-left: rem(8);

		&:hover {
			color: $color-primary-blue-600;
		}
	}

	&__languages-list {
		flex-direction: column;
		padding: rem(16);
		position: absolute;
		transform: translate(31px, 20px);
		right: 50%;
		display: none;
		background: $color-grey-100;
		min-width: rem(300);
		z-index: z-index(language-menu);
		grid-template-columns: repeat(2, 1fr);
		gap: 8px;
		@include shadow-2();

		@include rightToLeft {
			left: 50%;
			right: unset;
			transform: translate(-31px, 20px);
		}
	}

	&__language-item {
		margin: 0;
		padding: rem(8 16);
		min-height: rem(56);
		font-size: rem($px-small);
		font-style: normal;
		font-weight: $font-regular;
		line-height: normal;
		text-decoration: none;
		text-wrap: nowrap;
		color: $color-dark-blue-900;
		font-family: $font-poppins;
		border-bottom: 1px solid $color-grey-200;
		display: flex;
		align-items: center;
		gap: rem(16);
		min-width: rem(250);

		input {
			&[type='radio'] {
				pointer-events: none;
			}
		}

		&:hover,
		&.active {
			text-decoration: none;
			font-weight: $font-semibold;
		}

		&:hover {
			input {
				&[type='radio'] {
					&:not(:checked) {
						outline: 8px solid $color-grey-200;
						background-color: $color-grey-200;
					}
				}
			}
		}

		&.active {
			pointer-events: none;
		}
	}

	&__language-name {
		align-items: center;
		display: flex;
		justify-content: space-between;
		flex: 1;
	}

	&__languages-chevron {
		stroke-width: 2px;
		margin-left: rem(8);
		transition: 0.2s ease-in-out;

		path {
			stroke: $color-primary-blue-500;
		}
	}

	&__hamburger {
		position: relative;
		height: rem(16);
		width: rem(22);
		margin: rem(10);
		transition: 0.35s ease all;
		cursor: pointer;

		&.active {
			span {
				&:nth-of-type(1) {
					top: 50%;
					transform: translateY(-50%) rotate(135deg);
				}
				&:nth-of-type(2) {
					left: rem(-45);
					opacity: 0;
				}
				&:nth-of-type(3) {
					top: 50%;
					transform: translateY(-50%) rotate(-135deg);
				}
			}
		}

		span {
			position: absolute;
			height: rem(2);
			width: rem(22);
			border-radius: 6px;
			background-color: $color-dark-blue-900;
			transition: 0.35s ease all;

			&:nth-of-type(1) {
				top: 0;
			}
			&:nth-of-type(2) {
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&:nth-of-type(3) {
				bottom: 0;
			}
		}
	}
}

.header-disclaimer {
	background: $color-dark-blue-900;
	width: 100%;

	.wrapper {
		display: flex;
		align-items: center;
	}

	&--mobile {
		max-height: 0;
		overflow: hidden;
		transition: all 0.1s ease-out;
		z-index: z-index(headerDisclaimerMobile);
		position: absolute;
		top: 0;
		opacity: 0;
		box-shadow:
			0 2px 4px 0 rgba(16, 24, 40, 0.16),
			0 4px 8px 0 rgba(16, 24, 40, 0.16);

		@include device((desktop-up)) {
			display: none;
		}

		.header-disclaimer {
			&__content {
				display: block;
			}
		}
	}

	&__content {
		padding: rem(8 0);
		text-align: center;
		color: $color-white;
		font-family: $font-poppins;
		font-size: rem($px-extra-small);
		font-style: normal;
		font-weight: $font-regular;
		line-height: normal;
		overflow: hidden;
		display: none;

		@include device((desktop-up)) {
			overflow: auto;
			display: block;
		}

		&--short {
			display: block;

			@include device((desktop-up)) {
				display: none;
			}
		}
	}

	&__mobile-button {
		background: $color-dark-blue-900;
		padding: rem(0 9);
		border-radius: 0 0 16px 16px;
		position: absolute;
		bottom: -29px; // button height
		z-index: z-index(headerDisclaimerButton);
		left: 50%;
		transform: translate(-50%, 0);
		transition: all 0.1s ease-out;
		clip-path: inset(0px -10px -10px -10px);
		box-shadow:
			0 2px 4px 0 rgba(16, 24, 40, 0.16),
			0 4px 8px 0 rgba(16, 24, 40, 0.16);

		@include device((desktop-up)) {
			display: none;
		}
	}

	&__svg {
		&--rotated {
			transform: rotate(180deg);
		}
	}
}

.disclaimer-active {
	.header-disclaimer {
		&__mobile-button {
			&--open {
				display: none;
			}
		}

		&--mobile {
			opacity: 1;
			max-height: 200px;
			overflow: unset;
		}
	}
}

/* TODO: remove after tests */
.env-develop {
	&.page-offer-crypto {
		.header-disclaimer {
			background: $color-grey-100;
			border-bottom: 1px solid $color-grey-200;
			box-shadow:
				0 1px 2px 0 rgba(16, 24, 40, 0.18),
				0 2px 4px 0 rgba(16, 24, 40, 0.18);

			&__content {
				color: $color-grey-600;
			}

			&__mobile-button {
				background: $color-grey-100;
				box-shadow:
					0 1px 2px 0 rgba(16, 24, 40, 0.18),
					0 2px 4px 0 rgba(16, 24, 40, 0.18);

				path {
					stroke: $color-grey-600;
				}
			}
		}
	}
}

.mobile-menu-opened {
	.header {
		&__mobile-menu {
			.btn {
				display: none;
			}
		}
	}
}

.dot {
	display: inline-block;
	position: relative;
	top: rem(-4);
	left: rem(2);
	width: rem(6);
	height: rem(6);
	border-radius: 50%;
	background-color: red;
	visibility: hidden;
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(1.5);
		opacity: 0.5;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

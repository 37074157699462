@import '../faq';

body.page-sfx-simplefx-coin {
	h2 {
		margin: rem(16 0);
	}

	.section-top {
		&__buttons {
			.btn {
				margin: rem(8 0);

				@include device((tablet-up)) {
					margin: 0;
				}
			}
		}
	}

	.section-airdrop {
		&__box-wrapper {
			margin: rem(24 0);

			@include device((tablet-up)) {
				grid-auto-flow: column;
				justify-content: center;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				grid-gap: 24px;
			}

			.box {
				border-radius: rem(24);
				padding: rem(24);
				background: $color-white;
				color: $color-dark-blue-900;
				text-align: left;
				flex-direction: column;

				@include device((tablet-up)) {
					margin: 0;
				}

				&__title {
					color: $color-dark-blue-900;
					text-align: center;
					width: 100%;

					svg {
						margin-bottom: rem(16);
					}
				}

				&__list {
					flex: 1;

					li {
						margin-bottom: rem(16);
					}
				}
			}
		}

		&__box-buttons {
			width: 100%;
		}
	}

	.sfx-coin-page {
		&__roadmap {
			padding: rem(24 0);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: fit-content;
			margin: auto;

			@include device((tablet-up)) {
				margin: rem(24 0);
				padding: rem(50 0);
				flex-direction: row;
				width: 100%;
			}
		}

		&__roadmap-info {
			@include device((desktop-up)) {
				margin: rem(0 100);
			}
		}

		&__roadmap-item {
			width: max-content;
			position: relative;
			display: flex;
			flex-direction: column;
			gap: rem(15);
			border-left: 2px solid $color-primary-blue-500;
			padding: rem(0 50 16 40);

			@include device((mobile, large-mobile)) {
				@include rightToLeft {
					border-left: 0;
					border-right: 2px solid $color-primary-blue-500;
				}
			}

			@include device((tablet-up)) {
				flex: 1;
				padding: 0;
				border-left: none;
				border-top: 2px solid $color-primary-blue-500;
			}

			svg {
				transform: rotate(-90deg);
				position: absolute;
				left: -2px;
				top: 2px;

				@include device((mobile, large-mobile)) {
					@include rightToLeft {
						left: unset;
						right: -2px;
						transform: rotate(90deg);
					}
				}

				@include device((tablet-up)) {
					position: relative;
					transform: none;
					margin-top: -3px;
					margin-left: 0;
				}
			}
		}

		&__roadmap-month,
		&__roadmap-year {
			color: $color-primary-blue-500;
			font-family: $font-poppins;
			font-weight: $font-semibold;
			line-height: 1.2;
		}

		&__roadmap-month {
			font-size: rem(24);
		}

		&__roadmap-year {
			font-size: rem(32);
		}

		&__roadmap-stage {
			font-family: $font-poppins;
			font-size: rem($px-bigger);
			font-weight: $font-semibold;
			line-height: 1.2;
		}

		&__box-list-grid {
			display: grid;
			grid-template-rows: repeat(3, 1fr);
		}

		&__features-title {
			line-height: 1;
			margin: rem(15 0 20);
		}

		&__features-bullets {
			display: flex;
			align-items: flex-start;
			justify-content: space-evenly;
			flex-direction: column;
			gap: rem(16);

			@include device((tablet-up)) {
				flex-direction: row;
				align-items: flex-end;
				gap: 0;
			}
		}

		&__tier-item {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: center;
			font-family: $font-poppins;
			font-weight: $font-bold;
			color: $color-dark-blue-900;
			gap: rem(16);

			@include device((tablet-up)) {
				flex-direction: column;
				gap: 0;
			}
		}

		&__tier-text {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			min-width: 130px;

			@include device((tablet-up)) {
				align-items: center;
				min-width: unset;
			}
		}

		&__tier-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			gap: rem(2);
			margin-bottom: rem(5);

			@include device((tablet-up)) {
				flex-direction: column;
			}

			div {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background: $color-lime-green-200;
			}
		}

		&__features-boxes {
			margin: rem(50 0 24);

			.box {
				&__title {
					margin: 0;
				}
			}
		}

		&__benefits-wrapper {
			display: grid;
			gap: rem(20);
			margin: rem(20 0 36);

			@include device((tablet-up)) {
				margin: rem(36 50 60);
			}
		}

		&__benefit-item {
			display: flex;
			gap: rem(10);
			line-height: 1.2;
		}

		&__legacy-wrapper {
			margin: rem(36 0 48);
			padding: 0;
		}

		&__conclusion-title {
			@include device((small-mobile, mobile, large-mobile)) {
				margin-top: rem(48);
			}
		}
	}

	.section-coins-multiple,
	.allocation {
		&__img-wrapper {
			img {
				@include device((small-mobile, mobile, large-mobile)) {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}

	.allocation {
		&__labels {
			display: flex;
			flex-wrap: wrap;
			gap: rem(16);
			flex-direction: column;

			@include device((tablet-up)) {
				flex-direction: row;
				justify-content: center;
			}

			> div {
				display: flex;
				gap: rem(8);
				align-items: center;
			}
		}

		&__label {
			width: rem(16);
			height: rem(16);

			&--color-1 {
				background: rgb(73, 171, 232);
			}

			&--color-2 {
				background: rgb(187, 189, 191);
			}

			&--color-3 {
				background: rgb(135, 215, 251);
			}

			&--color-4 {
				background: rgb(211, 243, 253);
			}

			&--color-5 {
				background: rgb(54, 116, 181);
			}
		}
	}

	.heading-margin {
		margin: rem(16 0 8);
	}
}

body.page-privacy-policy {
	h2 {
		margin-top: 0;
	}

	.section-white {
		margin: rem(64 0);
		padding: rem(50 16);

		@include device((tablet-up)) {
			padding: rem(50);
		}
	}

	.document {
		display: flex;
		flex-direction: column;
		padding: rem(0 8 50);

		@include device((tablet-up)) {
			padding: rem(0 50);
		}

		p {
			margin: rem(10 0);
			color: $color-grey-600;
			line-height: 1.2;

			&.document {
				&__section-title {
					font-family: $font-poppins;
					font-weight: $font-semibold;
					font-size: rem($px-big);
					margin: rem(32 0 24);
					line-height: 1.2;
					color: $color-dark-blue-900;
				}

				&__subsection-title {
					font-family: $font-poppins;
					font-weight: $font-semibold;
					font-size: rem($px-bigger);
					margin: rem(24 0);
					line-height: 1.2;
					color: $color-dark-blue-900;
				}
			}
		}

		ul {
			margin: 0;
		}

		li {
			padding: rem(5 0);
		}
	}
}

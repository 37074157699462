.section-legal-documents {
	&__wrapper {
		@include device((tablet-up)) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: rem(0 24);
			padding: rem(0 50);
		}

		svg {
			min-width: rem(24);
			width: rem(24);
			height: rem(24);
		}
	}

	&__item {
		padding: rem(8 16);
		border-bottom: 1px solid $color-grey-400;
		font-family: $font-poppins;
		line-height: 1.2;
		color: $color-dark-blue-900;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include device((tablet-up)) {
			line-height: 2;
		}
	}
}

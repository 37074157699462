.lightbox {
	display: none;
	position: fixed;
	z-index: z-index(lightbox);
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	background-color: black;

	&.active {
		display: block;
	}

	&__backdrop {
		overflow: hidden;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: z-index(lightbox);
		background-color: $color-dark-blue-900;
		opacity: 0.7;
		animation: backdropFadeIn 0.5s;
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		margin: 0 auto;
		padding: 0;
		max-width: 95%;
		height: 100%;
		animation: imageFadeIn 0.4s;
		z-index: z-index(lightboxContent);

		@include device((large-desktop)) {
			max-width: 90%;
		}

		@include device((extra-large-desktop)) {
			max-width: 80%;
		}

		img {
			width: 100%;
		}
	}

	&__close {
		color: $color-grey-400;
		font-size: rem(35);
		cursor: pointer;

		&:hover {
			color: $color-grey-500;
			text-decoration: none;
		}
	}
}

@keyframes backdropFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.7;
	}
}

@keyframes imageFadeIn {
	0% {
		opacity: 0;
		transform: translate3d(4em, 0, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

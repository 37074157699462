@import 'variables';

.custom-select {
	&__wrapper {
		position: relative;

		&.open {
			.custom-select {
				&__list {
					display: flex !important;
				}

				&__value {
					.chevron {
						transform: translateY(-50%) rotate(180deg);
					}
				}
			}
		}
	}

	&__value {
		position: relative;
		border-radius: rem(8);
		border: 1px solid $color-grey-200;
		background: $color-white;
		min-width: 100%;
		padding: rem(12 40 12 12);

		> span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		> .chevron {
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);
			transition: 0.3s ease-out all;

			&::before,
			&::after {
				background: $color-dark-blue-900;
			}
		}

		&:hover {
			color: $color-grey-600;
			border: 1px solid $color-grey-300;
		}

		&--min-width {
			min-width: rem(320);
		}

		&--transparent {
			border: 1px solid transparent;
			background: transparent;
		}
	}

	&__value,
	&__list-item {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: rem($px-small);
		line-height: 1.2;
	}

	&__list {
		display: none;
		position: absolute;
		flex-direction: column;
		width: 100%;
		background: $color-white;
		z-index: 1;
		padding: rem(16);
		max-height: rem(350);
		overflow-y: auto;
		overflow-x: hidden;
		border-radius: rem(8);
		border-bottom: 1px solid $color-grey-200;
		box-shadow:
			0 4px 8px 0 rgba(16, 24, 40, 0.14),
			0 8px 16px 0 rgba(16, 24, 40, 0.14);
		transition: 0.5s ease-out all;

		&--with-title {
			overflow-y: hidden;
		}
	}

	&__list-content {
		overflow-y: auto;
	}

	&__list-item {
		color: $color-dark-blue-900;
		display: flex;
		align-items: center;
		gap: rem(8);
		min-height: rem(40);

		&.active {
			font-weight: $font-medium;
		}

		input {
			margin: rem(10);
		}
	}

	&__list-title {
		margin-bottom: rem(16);
		font-weight: $font-semibold;
		font-family: $font-poppins;
		line-height: 2;
		text-transform: uppercase;
	}

	&__list-button {
		margin-top: rem(16);

		.btn {
			width: 100%;
		}
	}
}

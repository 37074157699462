@import '../partials/section-carousel';
@import '../partials/awards';

$itemWidth: 600px;
$timelineYearWidth: 80px;
$timelineGap: 24px;

body.page-about {
	.section-standard {
		&__content {
			@include device((tablet-up)) {
				padding-right: rem(31);
			}
		}

		&__img {
			@include device((small-mobile, mobile, large-mobile)) {
				img {
					width: 100%;
				}
			}
		}
	}

	.section-white {
		@include device((small-mobile, mobile, large-mobile)) {
			h2 {
				text-align: center;
			}

			.chips {
				margin: auto;
				display: table;
				margin-bottom: rem(16);
			}

			img {
				width: 100%;
			}
		}
	}

	.section-history {
		&__timeline {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			color: $color-primary-blue-500;
			font-size: rem(20);
			font-family: $font-poppins;
			font-weight: $font-medium;
			margin-bottom: rem(24);
			padding-bottom: rem(5);
			min-height: rem(55);

			&::after {
				content: '';
				position: absolute;
				bottom: 9px;
				height: 2px;
				width: 100%;
				background: linear-gradient(
					90deg,
					transparentize($color-primary-blue-500, 1) 0%,
					$color-primary-blue-500 48.5%,
					transparentize($color-primary-blue-500, 1) 100%
				);
			}
		}

		&__timeline-years {
			max-width: min(90vw, $timelineYearWidth * 5 + $timelineGap * 4);
			overflow: hidden;
		}

		&__timeline-years-wrapper {
			display: grid;
			gap: $timelineGap;
			grid-template-columns: repeat(auto-fit, rem(80));
			grid-auto-flow: column;
			padding: rem(15 0 4);
			transform: translateX(calc(50% - #{$timelineYearWidth * 0.5}));

			@include rightToLeft {
				transform: translateX(calc(-50% + #{$timelineYearWidth * 0.5}));
			}
		}

		&__timeline-year {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding-bottom: rem(12);
			text-align: center;
			cursor: pointer;
			user-select: none;
			width: $timelineYearWidth;

			span {
				transition: all 0.3s ease-out;
			}

			&::after {
				content: '';
				position: absolute;
				bottom: -3px;
				left: 50%;
				height: 9px;
				width: 9px;
				border-radius: 50%;
				background: $color-primary-blue-500;
				transform: translateX(-50%);
			}

			&.active {
				font-size: rem(32);
				font-weight: $font-semibold;

				span {
					transform: translateY(-8px);
				}
			}
		}

		&__year-wrapper {
		}

		&__year-title {
			font-family: $font-poppins;
			font-size: rem(20);
			font-weight: $font-semibold;
			text-align: center;
			padding: rem(24 0);
		}

		&__year-content {
			@include device((desktop-up)) {
				&--2013 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 170);
							grid-template-columns: 325px 1fr;
						}

						&:nth-child(2) {
							padding: rem(0 250);
							grid-template-columns: 1fr 255px;
						}
					}
				}

				&--2014 {
					.section-history__row {
						padding: rem(0 170);
						grid-template-columns: 350px 1fr;
					}
				}

				&--2015 {
					.section-history__row {
						grid-template-columns: 330px 1fr 265px;
					}
				}

				&--2016 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 255);
							grid-template-columns: 1fr 330px;
						}

						&:nth-child(2) {
							padding: rem(0 85);
							grid-template-columns: 1fr 490px;
						}
					}
				}

				&--2017 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 190);
							grid-template-columns: 370px 370px;
						}

						&:nth-child(2) {
							padding: rem(0 275);
						}
					}
				}

				&--2018 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 130);
							grid-template-columns: 1fr 240px;
						}

						&:nth-child(2) {
							padding: rem(0 190);
							grid-template-columns: 320px 1fr;
						}
					}
				}

				&--2019 {
					.section-history__row {
						padding: rem(0 130);
						grid-template-columns: 1fr 380px;
					}
				}

				&--2020 {
					.section-history__row {
						grid-template-columns: 1fr 500px 180px;
					}
				}

				&--2021 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 75);
							grid-template-columns: 455px 1fr;
						}

						&:nth-child(2) {
							padding: rem(0 110);
							grid-template-columns: 1fr 180px;
						}
					}
				}

				&--2022 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 100);
							grid-template-columns: 1fr 385px;
						}

						&:nth-child(2) {
							padding: rem(0 130);
							grid-template-columns: 425px 1fr;
						}
					}
				}

				&--2023 {
					.section-history__row {
						grid-template-columns: 300px 1fr 180px;
					}
				}

				&--2024 {
					.section-history__row {
						&:nth-child(1) {
							grid-template-columns: 220px 388px 1fr;
						}

						&:nth-child(2) {
							padding: rem(0 100);
							grid-template-columns: 505px 1fr;
						}
					}
				}
			}

			@include device((desktop)) {
				&--2013 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 50);
						}

						&:nth-child(2) {
							padding: rem(0 100);
						}
					}
				}

				&--2014 {
					.section-history__row {
						padding: rem(0 50);
					}
				}

				&--2016 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 100);
						}

						&:nth-child(2) {
							padding: rem(0 45);
						}
					}
				}

				&--2017 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 90);
						}

						&:nth-child(2) {
							padding: rem(0 115);
						}
					}
				}

				&--2018 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 70);
						}

						&:nth-child(2) {
							padding: rem(0 50);
						}
					}
				}

				&--2019 {
					.section-history__row {
						padding: rem(0 70);
					}
				}

				&--2020 {
					.section-history__row {
						grid-template-columns: 1fr 450px 180px;
					}
				}

				&--2021 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 50);
						}

						&:nth-child(2) {
							padding: rem(0 60);
						}
					}
				}

				&--2022 {
					.section-history__row {
						&:nth-child(1) {
							padding: rem(0 50);
						}

						&:nth-child(2) {
							padding: rem(0 70);
						}
					}
				}

				&--2024 {
					.section-history__row {
						&:nth-child(2) {
							padding: rem(0 50);
						}
					}
				}
			}

			@include device((mobile, large-mobile)) {
				&--2015 {
					.section-history__box {
						grid-template-areas:
							'extra-content'
							'text';
					}
				}

				&--2016,
				&--2018 {
					.section-history__row {
						&:nth-child(2) {
							.section-history__box {
								grid-template-areas:
									'text'
									'extra-content';
							}
						}
					}
				}

				&--2021,
				&--2023 {
					.section-history__row {
						&:nth-child(1) {
							.section-history__box {
								grid-template-areas:
									'text'
									'extra-content';
							}
						}
					}
				}

				&--2022 {
					.section-history__row {
						&:nth-child(1) {
							.section-history__box {
								grid-template-areas:
									'extra-content'
									'text';
							}
						}
					}
				}
			}

			&--2018,
			&--2023 {
				.section-history__box-extra-content {
					justify-content: center;
				}
			}

			&--2018 {
				.section-awards {
					&__title {
						font-size: rem(11) !important;
					}

					&__description {
						font-size: rem(10) !important;
						margin-bottom: rem(10);
					}
				}
			}
		}

		&__year-content,
		&__row {
			display: grid;
			gap: rem(16);
			justify-content: center;
		}

		&__box {
			padding: rem(24);
			border-radius: rem(24);
			background: $color-white;
			@include shadow-4();

			&--with-image-left,
			&--with-image-top,
			&--with-image-right,
			&--with-image-bottom {
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: auto auto;
				gap: rem(16);
			}

			&--with-image-left {
				grid-template-areas: 'extra-content text';
				grid-template-columns: auto 1fr;
			}

			&--with-image-top {
				grid-template-areas:
					'extra-content'
					'text';
			}

			&--with-image-right {
				grid-template-areas: 'text extra-content';
				grid-template-columns: 1fr auto;
			}

			&--with-image-bottom {
				grid-template-areas:
					'text'
					'extra-content';
			}

			.txid-block {
				background: $color-grey-100;
				border-radius: rem(8);
				padding: rem(5 10);
				word-break: break-all;
			}

			.btn {
				padding-left: rem(8);
				padding-right: rem(8);
			}

			.new-instruments,
			.new-payments {
				display: grid;
				grid-gap: rem(10);
				justify-content: space-between;
				align-items: center;
			}

			.new-instruments {
				grid-template-columns: repeat(auto-fit, 27px);
				grid-auto-rows: 27px;

				.instrument-logo {
					transform: scale(0.3) translate(-100%, 0);
				}
			}

			.new-payments {
				grid-template-columns: repeat(auto-fit, 101px);
			}

			.dots {
				display: grid;
				grid-template-columns: repeat(3, 5px);
				grid-gap: rem(3);
				align-content: center;
				padding: 10px;
				width: fit-content;
				height: fit-content;

				> span {
					display: block;
					width: 5px;
					height: 5px;
					background: $color-primary-blue-500;
					border-radius: 50%;
				}
			}

			.section-awards {
				&__item {
					align-self: center;
					width: rem(150);
					height: rem(130);
				}

				&__title {
					font-size: rem(16);
					padding: rem(0 25);
				}

				&__description {
					padding: rem(0 20);
					font-size: rem(12);
				}
			}
		}

		&__box-content {
			grid-area: text;
		}

		&__box-extra-content {
			display: grid;
			grid-area: extra-content;

			picture {
				display: flex;
				justify-content: center;
				align-self: center;
			}
		}
	}

	.box {
		p {
			margin-bottom: 0;
		}

		&__title {
			margin-top: 0;
		}

		&__sub-title {
			font-weight: $font-semibold;
			font-family: $font-poppins;
		}
	}

	.heading-margin {
		margin: rem(16 0);
	}

	.section-title {
		h2 {
			margin-top: rem(16);
		}
	}

	.section-carousel {
		&__description {
			font-size: rem($px-bigger);
			line-height: 1.4;

			@include device((tablet-up)) {
				font-size: rem($px-normal);
			}
		}

		&__text {
			font-weight: $font-semibold;
			font-family: $font-poppins;
			font-size: rem($px-small);
			line-height: 2;
			margin: 0;
		}

		&__main-slide {
			gap: rem(8);
		}

		&__bullets-wrapper {
			margin-top: rem(40);
		}
	}
}

@import '../row-link';

.education-links {
	h3 {
		margin: rem(16 0);
	}

	&__wrapper,
	&__buttons {
		@include device((tablet-up)) {
			padding: rem(0 50);
		}
	}

	&__wrapper {
		&--mobile {
			@include device((tablet-up)) {
				display: none;
			}
		}

		&--desktop {
			display: none;

			@include device((tablet-up)) {
				display: block;
			}
		}

		svg {
			width: rem(24);
			height: rem(24);
			min-width: rem(24);
		}
	}

	&__buttons {
		font-size: rem(14);
		font-weight: $font-semibold;
		font-family: $font-poppins;
		margin-top: rem(32);
		text-align: center;

		@include device((tablet-up)) {
			text-align: right;
		}
	}
}

.box {
	&__wrapper {
		&--margin-top {
			margin-top: rem(16);

			@include device((tablet-up)) {
				margin-top: rem(24);
			}
		}
	}
}

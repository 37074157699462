.row-link {
	color: $color-dark-blue-900;
	font-family: $font-poppins;
	line-height: 2;
	border-bottom: 1px solid $color-grey-400;
	padding: rem(8 16);
	text-decoration: none;
	font-weight: $font-regular;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: all 0.3s ease-out;

	&:hover,
	&:active {
		border-color: $color-primary-blue-500;
		box-shadow: 0 1px 0 $color-primary-blue-500;

		path {
			stroke: $color-primary-blue-500;
		}
	}

	&:hover {
		font-weight: $font-medium;
	}

	&:active {
		font-weight: $font-semibold;
		color: $color-primary-blue-500;
	}
}

body.page-cybersecurity-awareness {
	.cybersecurity {
		&__protection-hint {
			display: grid;
			grid-template-columns: 24px 1fr;
			grid-gap: rem(16);

			svg {
				width: 24px;
				height: 24px;
			}

			&:not(:last-of-type) {
				margin-bottom: rem(16);
			}
		}

		&__reporting-section-items-wrapper {
			display: grid;
			grid-gap: rem(64);
			margin: rem(40 0);

			@include device((tablet-up)) {
				grid-template-columns: repeat(3, 1fr);
			}
		}

		&__reporting-section-item {
			display: grid;
			align-content: center;
			text-align: center;
			justify-items: center;
			grid-template-rows: rem(48 + 5 * 2) 1fr;
			grid-gap: rem(16);
		}

		&__reporting-section-img {
			padding: rem(5);
			background: $color-white;
			border-radius: 8px;
			box-shadow:
				0 2px 4px 0 rgba(16, 24, 40, 0.16),
				0 4px 8px 0 rgba(16, 24, 40, 0.16);

			svg {
				width: rem(48);
				height: rem(48);
			}
		}
	}

	.section-standard {
		&__img {
			padding: rem(25 35);
			max-width: rem(150 + 35 * 2);

			img {
				max-width: 100%;
				max-height: 100%;
			}

			@include device((tablet-up)) {
				padding: rem(35 75);
				max-width: rem(222 + 75 * 2);
			}
		}
	}

	.box p {
		color: $color-dark-blue-600;
	}

	.important-note {
		color: $color-red-500;
	}

	.strong-medium strong {
		font-weight: $font-medium;
	}
}

@import '../row-link';

body.page-legal-documents {
	h2 {
		&.section-dark {
			margin: rem(16 0);

			@include device((tablet-up)) {
				margin: rem(24 0);
			}
		}
	}

	.section-white {
		margin: rem(64 0);
	}

	.legal-documents {
		&__wrapper {
			@include device((tablet-up)) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: rem(0 24);
				padding: rem(0 50);
			}
		}
	}

	.chevron {
		&::before,
		&::after {
			background: rgb(152, 162, 179);
		}
	}
}

.section-carousel {
	width: 100%;
	margin: rem(0 auto 24);
	padding: rem(48 0);
	border-radius: rem(16);
	background: $color-white;
	box-shadow:
		0px 8px 16px 2px rgba(16, 24, 40, 0.1),
		0px 16px 32px 2px rgba(16, 24, 40, 0.1);
	overflow: hidden;

	@include device((tablet-up)) {
		width: rem(752);
		padding: rem(24 0);
	}

	&__quote {
		position: absolute;
	}

	&__main-slide {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		gap: rem(8);
		min-width: 100%;
		padding: rem(0 8);

		@include device((tablet-up)) {
			padding: rem(0 24);
		}
	}

	&__description {
		position: relative;
		padding: rem(48 16 0);
		font-size: rem(20);
	}

	&__text {
		margin-top: rem(8);
	}

	&__slider-wrapper {
		display: flex;
	}

	&__bullets-wrapper {
		display: flex;
		justify-content: center;
		margin-top: rem(24);
	}

	&__bullet {
		width: rem(12);
		height: rem(12);
		margin: rem(0 4);
		transition: all 0.2s ease-in;
		border: 1px solid $color-grey-200;
		border-radius: 50%;
		cursor: pointer;

		&.active {
			background: $color-grey-200;
		}
	}

	&__img {
		height: rem(36);
		width: auto;
	}
}

@import '../partials/instruments-table';
@import '../partials/section-instruments';
@import '../partials/asset-tutorials';
@import '../partials/asset-class-common';

body.page-offer-crypto {
	@include boxesPadding();

	@include device((mobile, tablet)) {
		h1 {
			font-size: rem(33);
		}
	}
}

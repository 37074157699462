$modal-padding: 16px;
$modal-height: 400px;
$modal-width: 380px;
$input-height: 42px;
$input-margin-bottom: 15px;
$mobile-search-empty-height: 64px - 57px - 16px * 7; // 16px: padding; 64px - header; 57px: button (sign in/up) height

.header {
	&__instruments-search {
		position: relative;
	}
}

.header-instruments-search {
	display: none;
	font-family: $font-poppins;

	@include device((desktop-up)) {
		position: absolute;
		top: 53px; // Don't change to rem
		right: rem(-60);
		background: $color-grey-100;
		width: $modal-width;
		height: $modal-height;
		padding: $modal-padding;
		box-shadow:
			0 1px 2px 0 rgba(16, 24, 40, 0.18),
			0 2px 4px 0 rgba(16, 24, 40, 0.18);

		@include rightToLeft {
			left: rem(-60);
		}

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 10px;
			background: $color-grey-100;
			left: 0;
			top: -2px;
		}
	}

	&--mobile {
		display: block;
		max-height: calc(100vh - $mobile-search-empty-height);

		@supports (height: 100dvh) {
			max-height: calc(100dvh - $mobile-search-empty-height);
		}

		.form__group {
			padding: rem(0 2 20);

			svg {
				top: 20px;
			}
		}
	}

	[hidden] {
		display: none !important;
	}

	&.active {
		display: flex;
		flex-direction: column;
	}

	&__button {
		display: none;

		@include device((desktop-up)) {
			padding: rem(10);
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}

	&__active-search,
	&__inactive-search {
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__inactive-search {
		@include device((desktop-up)) {
			height: calc(
				$modal-height - $input-height - $modal-padding * 2 -
					$input-margin-bottom
			);
		}
	}

	&__active-search {
		@include device((desktop-up)) {
			width: calc($modal-width - $modal-padding * 2);

			> .custom-scroll {
				height: calc(
					$modal-height - $input-height - $modal-padding * 2 -
						$input-margin-bottom
				);

				&.no-data {
					display: flex;
					align-items: center;

					.no-search-data {
						padding: 0;
					}
				}
			}
		}
	}

	&__did-you-mean {
		padding-bottom: rem(16);
		color: $color-grey-400;
		font-family: $font-poppins;
		font-weight: $font-medium;
		font-size: rem(14);
		text-align: center;

		&--symbol {
			color: $color-primary-blue-500;
			cursor: pointer;
		}
	}

	&__input-wrapper {
		height: $input-height;
		margin-bottom: $input-margin-bottom;
	}

	&__history {
		.header-instruments-search__section-content {
			display: grid;
			grid-template-columns: repeat(3, minmax(max-content, auto));
			gap: rem(15);
			padding: rem(0 16);

			.chips {
				gap: rem(4);
				cursor: pointer;
				justify-self: flex-start;

				span {
					max-width: rem(80);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.chips-close-btn {
				position: relative;
				width: 15px;
				height: 15px;
				margin-bottom: 3px;

				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 50%;
					display: block;
					width: 10px;
					height: 2px;
					background: $color-dark-blue-900;
				}

				&::before {
					transform: translate(50%, 50%) rotate(45deg);
				}

				&::after {
					transform: translate(50%, 50%) rotate(-45deg);
				}
			}
		}
	}

	&__popular {
		.header-instruments-search__section-content {
			display: grid;

			@include device((desktop-up)) {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	&__category-name {
		color: $color-grey-500;
		padding: rem(10);
		text-wrap: nowrap;
		cursor: pointer;

		&.active {
			border-bottom: 1px solid $color-primary-blue-500;
		}
	}

	&__instrument {
		display: grid;
		grid-template-columns: 25px 1fr;
		grid-gap: rem(12);
		font-size: rem(14);
		font-weight: $font-medium;
		padding: rem(16 32);
		border-bottom: 1px solid $color-grey-200;
		color: $color-dark-blue-900;
		cursor: pointer;
	}

	&__instrument-logo {
		position: relative;

		.instrument-logo {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scale(0.27);
		}
	}

	&__section-title {
		padding: rem(10 16);
		color: $color-grey-400;
	}

	&__section-title,
	&__category-name {
		font-family: $font-poppins;
		font-weight: $font-medium;
		font-size: rem(12);
	}

	&__back-btn {
		position: absolute;
		top: 0;
		left: rem(16);
		color: $color-primary-blue-500;
		display: flex;
		align-items: center;
		gap: 4px;
		font-size: rem(14);
		font-family: $font-poppins;
		font-weight: $font-medium;
	}
}

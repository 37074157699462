body.page-status {
	h4 {
		padding: rem(16 32);
	}

	.status {
		&__wrapper {
			display: grid;
			grid-gap: rem(24);
			margin-top: rem(48);
			padding-bottom: rem(65);

			@include device((tablet-up)) {
				margin-top: rem(64);
				grid-gap: rem(16);
				grid-auto-flow: column;
				justify-content: center;
				grid-auto-columns: 1fr;
			}
		}

		&__payments-search {
			padding: rem(16 32);
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: column;
			gap: rem(12);

			h4 {
				padding: 0;
			}

			@include device((small-mobile, mobile, large-mobile)) {
				.form {
					&__group {
						width: 100%;

						input {
							width: 100%;
						}
					}
				}
			}

			@include device((tablet-up)) {
				padding: rem(4 32);
				flex-direction: row;
				align-items: center;
				gap: 0;
			}
		}

		&__box {
			display: flex;
			flex-direction: column;
			padding: rem(16 0);
			border-radius: rem(16);
			background: $color-white;
			box-shadow:
				0 12px 24px 6px rgba(16, 24, 40, 0.08),
				0 24px 48px 6px rgba(16, 24, 40, 0.08);

			@include device((tablet-up)) {
				max-height: rem(500);
			}

			&--servers {
				position: relative;
				overflow: hidden;
			}
		}

		&__row {
			border-bottom: 1px solid $color-grey-100;
			display: grid;
			grid-auto-flow: column;
			padding: rem(16 32);
			grid-auto-columns: 1fr 1fr 1fr;
			line-height: 1;
			font-size: rem($px-small);

			@include device((tablet-up)) {
				grid-auto-columns: 2fr 1fr 1fr;
			}

			&.search-display-none {
				display: none;
			}
		}

		&__header {
			border-bottom: none;
			padding: rem(8 32);
			font-size: rem($px-extra-small);
			color: $color-grey-400;
			font-family: $font-poppins;
			margin-top: rem(16);
		}

		&__column {
			position: relative;
			line-height: 1;

			&:nth-of-type(2),
			&:nth-of-type(3) {
				text-align: center;
				padding-left: rem(40);

				@include device((tablet-up)) {
				}
			}
		}

		&__small-description {
			font-size: rem($px-extra-small);
			padding-top: rem(5);
		}

		&__column {
			.feed-degraded,
			.trading-degraded {
				display: none;
			}

			&.working {
				.status {
					&--warning,
					&--error {
						display: none;
					}
				}
			}

			&.partial-outage {
				.status {
					&--green,
					&--error {
						display: none;
					}
				}
			}

			&.major-outage {
				.status {
					&--green,
					&--warning {
						display: none;
					}
				}
			}

			&--webtrader,
			&--mobile {
				&.feed-degraded {
					.feed-degraded {
						display: block;
					}
				}

				&.trading-degraded {
					.trading-degraded {
						display: block;
					}
				}
			}
		}

		&__operators-loader {
			margin: rem(8 0 8 32);
		}

		&__servers-loader {
			position: absolute;
			padding-left: rem(32);
			top: rem(80);
			bottom: 0;
			left: 0;
			right: 0;
			background: $color-white;
			z-index: 1;
		}

		&__operators-container {
			@include device((tablet-up)) {
				overflow-y: scroll;
				padding-right: rem(16);
				margin-right: rem(16);
			}
		}

		&--green,
		&--warning,
		&--error {
			position: relative;
			white-space: nowrap;

			&::after {
				content: '';
				position: absolute;
				top: rem(8);
				left: rem(-16);
				transform: translateY(-50%);
				width: rem(8);
				height: rem(8);
				border-radius: 50%;
			}
		}

		&--green {
			color: $color-lime-green-500;

			&::after {
				background: $color-lime-green-500;
			}
		}

		&--color-warning,
		&--warning {
			color: $color-orange-400;
		}

		&--warning {
			&::after {
				background: $color-orange-400;
			}
		}

		&--error {
			color: $color-orange-600;

			&::after {
				background: $color-orange-600;
			}
		}
	}
}

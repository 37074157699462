h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-poppins;
	font-weight: $font-semibold;
	letter-spacing: 0;
	font-style: normal;
	line-height: 120%;
	margin: 0;

	@include device((small-mobile, mobile, large-mobile, tablet)) {
		overflow-wrap: anywhere;
	}
}

h1 {
	font-size: rem(40);
	margin: rem(24 0);

	@include device((tablet-up)) {
		font-size: rem(48);
	}

	&.home-page {
		font-weight: $font-medium;
		letter-spacing: 0.8px;

		@include device((tablet-up)) {
			font-size: rem(72);
			letter-spacing: 1.44px;
		}
	}
}

h2 {
	font-size: rem(40);
	margin: rem(24 0);
}

h3 {
	font-size: rem(32);
}

h4 {
	font-size: rem($px-big);
}

h5 {
	font-size: rem($px-bigger);
}

ul {
	padding-left: $grid-padding;
}

a {
	color: $color-primary-blue-500;
	text-decoration: none;

	&:hover {
		color: $color-primary-blue-600;
	}

	&:active {
		color: $color-primary-blue-700;
	}
}

p {
	line-height: 140%;

	&.no-margin {
		margin: 0;
	}
}

.positive {
	color: $color-lime-green-500;
}

.negative {
	color: $color-red-600;
}

@mixin text-note-big() {
	font-family: $font-roboto;
	font-size: rem($px-small);
	font-style: normal;
	font-weight: $font-light;
	line-height: 120%;
}

@mixin text-note-small() {
	font-family: $font-roboto;
	font-size: rem($px-extra-small);
	font-style: normal;
	font-weight: $font-light;
	line-height: 120%;
}

@mixin text-label() {
	font-family: $font-roboto;
	font-size: rem($px-extra-small);
	font-style: normal;
	font-weight: $font-regular;
	line-height: 32px;
	text-transform: uppercase;
}

@mixin headers-h3() {
	font-family: $font-poppins;
	font-weight: $font-semibold;
	letter-spacing: 0;
	font-style: normal;
	line-height: 120%;
	font-size: rem(40);
}

@mixin headers-h4() {
	font-family: $font-poppins;
	font-weight: $font-semibold;
	letter-spacing: 0;
	font-style: normal;
	line-height: 120%;
	font-size: rem(32);
}

@mixin headers-h5() {
	font-family: $font-poppins;
	font-weight: $font-semibold;
	font-style: normal;
	line-height: 120%;
	font-size: rem(20);
}

.text {
	&--small {
		font-size: rem($px-small);
	}

	&--bigger {
		font-size: rem($px-bigger);
	}

	&--center {
		text-align: center;
	}

	&--semibold {
		font-weight: $font-semibold;
	}

	&--bold {
		font-weight: $font-bold;
	}

	&--ltr-only {
		direction: ltr;
	}
}

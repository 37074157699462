.section-awards {
	@include device((tablet-up)) {
		padding: rem(40 0);
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		margin-top: rem(24);
		flex-direction: column;
		gap: 32px;

		@include device((tablet)) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

		@include device((desktop-up)) {
			flex-direction: row;
			gap: 0;
			margin-top: rem(36);
		}
	}

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		width: 257px;
		height: 223px;
		background: url('/assets/home/award-bg.png') center center;
		background-size: cover;

		@include device((tablet)) {
			margin: auto;
		}
	}

	&__title {
		@include headers-h5();
		margin-bottom: rem(8);
		padding: rem(0 20);
	}

	&__description {
		line-height: 120%;
		font-size: rem($px-small);
		padding: rem(0 50);
	}
}

body.page-payment-redirect {
	.payment-redirect {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $color-white;
		z-index: z-index(special);

		&__content {
			text-align: center;
			margin: 0 auto;
			position: relative;
			width: 100%;

			@include device((tablet)) {
				margin-top: 100px;
			}

			@media all and (orientation: landscape) {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			@media only screen and (orientation: landscape) and (min-width: 800px) and (max-width: 991px) {
				margin-top: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&__text {
			color: $color-dark-blue-900;
			font-size: rem(18);
			font-weight: normal;
			text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

			@include device((tablet-up)) {
				font-size: rem(26);
			}
		}

		&__dot {
			display: inline-block;
			width: 5px;
			height: 5px;
			margin-right: rem(4);
			background: $color-dark-blue-900;
			border-radius: 50%;
			opacity: 0;

			&--first {
				animation: dots-animation 1.5s 100ms infinite ease-in-out;
			}

			&--second {
				animation: dots-animation 1.5s 400ms infinite ease-in-out;
			}

			&--third {
				animation: dots-animation 1.5s 600ms infinite ease-in-out;
			}
		}
	}
}

@keyframes dots-animation {
	0% {
		opacity: 0;
	}
	33% {
		opacity: 1;
	}
	66% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@use 'sass:math';

@import '../partials/instruments-table';
@import '../partials/pagination';
@import '../partials/section-instruments';
@import '../partials/related-articles';
@import '../partials/banner';
@import '../partials/awards';

$logoDesktopSize: 45px;

@mixin textAnimation() {
	background-image: linear-gradient(
		106deg,
		#0ea4d1 15%,
		#9eff00 55%,
		#0ea4d1 75%
	);
	background-size: 300%;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
	animation: animatedGradient 8s ease infinite;
}

body.page-home {
	.main {
		margin-bottom: 0;
	}

	.section-home {
		margin: rem(64 0);
		text-align: center;

		@include device((tablet-up)) {
			margin: rem(100 0);
		}

		&--they-wrote {
			@include device((tablet-up)) {
				padding-top: rem(50);
			}
		}

		&__heading {
			margin: rem(24 0);

			@include device((tablet-up)) {
				margin: rem(16 0);

				h2,
				h3 {
					margin: 0;
				}
			}
		}

		&__content {
			text-align: left;
			margin-top: rem(24);
		}
	}

	.section-top {
		color: $color-white;
		padding: rem(32 16 0);

		@include device((desktop-up)) {
			padding: rem(50 0 50 105);
			justify-content: space-between;

			@include rightToLeft {
				padding: rem(50 105 50 0);
			}
		}

		&__bg {
			background: $color-dark-blue-900;
			overflow: unset;

			&.section-standard {
				flex-direction: column;

				@include device((desktop-up)) {
					margin-top: rem(30);
					margin-bottom: rem(30);
					flex-direction: row;
				}
			}

			.section-standard {
				&__img {
					@include device((tablet)) {
						display: block;
					}
				}
			}
		}

		&__content {
			@include device((tablet)) {
				width: 100%;
				text-align: center;
			}
		}

		&__heading {
			color: $color-white;
			margin-bottom: rem(32);
			line-height: 1;

			@include device((tablet-up)) {
				margin-top: rem(24);
			}

			span {
				@include textAnimation();
			}
		}

		&__img {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: rem(32);
			gap: 16px;

			@include device((desktop-up)) {
				height: 100%;
				flex-direction: column;
				justify-content: flex-end;
				padding: rem(0 56 74 0);
				margin-bottom: unset;
				gap: 10px;

				@include rightToLeft {
					padding: rem(0 0 74 56);
				}

				.app-store-button,
				.google-play-button {
					height: 67px;
					width: 67px;
				}
			}
		}

		&__video-wrapper {
			padding: rem(36 0);
			text-align: center;

			@include device((tablet-up)) {
				padding: rem(36 60);
			}
		}

		.section-standard {
			&__button {
				margin-bottom: rem(16);
			}

			&__text {
				a {
					margin-bottom: 0;
					display: flex;
					line-height: 1.2;
					gap: 8px;
					color: $color-grey-100;

					@include device((tablet-up)) {
						align-items: center;
					}

					&:hover {
						color: $color-grey-25;
						text-decoration: underline;
					}

					&:active {
						color: $color-grey-300;
						text-decoration: underline;
					}
				}
			}
		}
	}

	.section-discover {
		&__boxes {
			display: flex;
			align-items: stretch;
			gap: $grid-padding;
			min-height: rem(500);
			flex-direction: column;

			@include device((desktop-up)) {
				flex-direction: row;
			}
		}

		&__left-column {
			flex: 1;
			display: flex;
		}

		&__right-column {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		&__top-row,
		&__bottom-row {
			display: flex;
			gap: 16px;
			flex: 1;
		}

		&__arrow {
			position: absolute;
			top: 24px;
			right: 24px;

			@include rightToLeft {
				svg {
					transform: scaleX(-1);
				}
			}
		}

		&__box-item {
			position: relative;
			cursor: pointer;
			display: flex;
			padding: rem(24);
			flex-direction: column;
			justify-content: right;
			align-items: flex-start;
			flex: 1 0 0;
			border-radius: rem(32);
			color: $color-white;
			box-shadow:
				0 2px 4px 0 rgba(16, 24, 40, 0.18),
				0 1px 2px 0 rgba(16, 24, 40, 0.18);
			transition: all 0.3s ease-out;

			&:hover {
				box-shadow:
					0 12px 24px 6px rgba(16, 24, 40, 0.08),
					0 24px 48px 6px rgba(16, 24, 40, 0.08);

				.section-discover {
					&__arrow {
						svg {
							stroke-width: 2px;
						}
					}
				}
			}

			&:active {
				box-shadow: none;

				.section-discover {
					&__arrow {
						svg {
							stroke-width: 1px;
						}
					}
				}
			}

			&--black {
				padding: rem(48 48 380);
				background: $color-dark-blue-800;
				overflow: hidden;
				justify-content: left;

				@include device((desktop-up)) {
					padding: rem(48 48 0);
				}

				&:hover {
					background: $color-dark-blue-900;
				}

				&:active {
					background: $color-dark-blue-800;
				}

				.section-discover {
					&__arrow {
						top: 48px;
						right: 48px;
					}

					&__box-img {
						position: absolute;
						bottom: 0;
						width: 563px;
						height: 387px;
						background: url('/assets/home/instruments-bg.png')
							center center;
						background-size: 100%;

						@include rightToLeft {
							background-image: url('/assets/home/instruments-bg-rtl.png');
						}

						@include device((small-mobile, mobile, large-mobile)) {
							left: -70px;
						}
					}

					&__box-title {
						line-height: normal;
						letter-spacing: 0.64px;
					}
				}
			}

			&--color1 {
				background: $color-red-500;

				&:hover {
					background: $color-red-600;
				}

				&:active {
					background: $color-red-500;
				}
			}

			&--color2 {
				color: $color-dark-blue-900;
				background: $color-lime-green-200;

				&:hover {
					background: $color-lime-green-300;
				}

				path {
					stroke: $color-dark-blue-900;
				}
			}

			&--color3 {
				background: linear-gradient(
					90deg,
					$color-primary-blue-700 0%,
					$color-primary-blue-400 100%
				);

				&:hover {
					background: linear-gradient(
							0deg,
							rgba(0, 0, 0, 0.2) 0%,
							rgba(0, 0, 0, 0.2) 100%
						),
						linear-gradient(90deg, #0d668e 0%, #20b2dd 100%);
				}

				&:active {
					background: linear-gradient(
						90deg,
						$color-primary-blue-700 0%,
						$color-primary-blue-400 100%
					);
				}
			}

			&--color1,
			&--color2,
			&--color3 {
				display: grid;
				gap: rem(16);
				padding-top: rem(16);

				@include device((tablet)) {
					padding-top: rem(95);
				}

				@include device((desktop-up)) {
					padding-top: rem(120);
				}

				.section-discover {
					&__box-title {
						margin-bottom: unset;
					}
					&__box-content {
						display: -webkit-box;
						overflow: hidden;
						line-clamp: 3;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
				}
			}
		}

		&__box-title {
			font-family: $font-poppins;
			font-size: rem($px-big);
			font-weight: $font-semibold;
			margin-bottom: rem(16);
			line-height: 1.2;
			margin-top: rem(48);

			@include device((desktop-up)) {
				margin-top: 0;
			}
		}

		&__symbol-data {
			padding: rem(50 0 0 270);
			color: $color-dark-blue-900;

			@include rightToLeft {
				padding: rem(50 270 0 0);
				text-align: right;
			}

			@include device((small-mobile, mobile, large-mobile, tablet)) {
				.instrument-tile {
					&__logo {
						height: $logoDesktopSize;
						width: $logoDesktopSize;

						.instrument-logo {
							transform: translate(-50%, -50%)
								scale(#{scaleLogo($logoDesktopSize)});
						}
					}

					&__name {
						font-size: rem(32);
					}

					&__price {
						font-size: rem(24);
					}

					&__change {
						font-size: rem(16);
					}
				}
			}
		}
	}

	.section-stop-dreaming {
		display: flex;
		padding: rem(64 20);
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 24px;
		background: $color-white;

		@include device((tablet-up)) {
			padding: rem(120 50);
			margin: rem(100 0);
		}

		&__title {
			@include headers-h3();
			@include textAnimation();
			font-size: rem(48);
			margin: rem(0 0 64);

			@include device((tablet-up)) {
				font-size: rem(72);
			}
		}
	}

	.section-trade-device {
		color: $color-white;
		border-radius: 24px;
		padding: rem(50 8);
		background: $gradient-02;
		overflow: hidden;

		@include device((tablet-up)) {
			padding: rem(48);
		}

		&__wrapper {
			position: relative;
			display: grid;
			grid-template-areas:
				'slides'
				'dots';
			grid-template-columns: 100%;
			align-items: center;
			width: 100%;
			gap: 8px;
			justify-content: center;

			@include device((tablet-up)) {
				gap: 24px;
			}
		}

		&__arrow {
			position: absolute;
			z-index: 1;

			svg {
				cursor: pointer;

				@include rightToLeft {
					transform: scaleX(-1);
				}

				&:hover,
				&:active {
					.section-trade-device {
						&__arrow-mask {
							display: block;
						}
					}
				}

				&:active {
					path {
						stroke-width: 0.5px;
						stroke: $color-white;
					}
				}
			}

			&--previous {
				left: 0;

				@include rightToLeft {
					right: 0;
					left: unset;
				}
			}

			&--next {
				right: 0;

				@include rightToLeft {
					left: 0;
					right: unset;
				}
			}
		}

		&__arrow-mask {
			display: none;
		}

		&__slider-wrapper {
			display: flex;
			grid-area: slides;
		}

		&__main-slide {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: rem(16);
			transition: all 0.4s linear;
			padding: rem(0 45);
			min-width: 100%;

			@include device((small-mobile, mobile, large-mobile)) {
				h3 {
					font-size: rem(24);
				}

				h5 {
					font-size: rem(16);
				}
			}

			&--first {
				.section-trade-device {
					&__content {
						@include device((tablet-up)) {
							padding-top: rem(8);
						}
					}

					&__buttons {
						margin-bottom: 0;
					}

					&__description {
						padding-top: 0;

						@include device((tablet-up)) {
							padding-top: rem(8);
						}
					}
				}
			}

			&--third {
				.section-trade-device {
					&__buttons {
						flex-direction: column;

						@include device((small-mobile, mobile, large-mobile)) {
							.btn {
								padding-right: 0;
								padding-left: 0;
							}
						}

						@include device((tablet-up)) {
							flex-direction: row;
						}
					}

					&__description {
						padding-top: rem(11);

						@include device((tablet-up)) {
							padding-top: rem(8);
						}
					}
				}
			}
		}

		&__description {
			padding-top: rem(8);
		}

		&__content {
			text-align: center;

			@include device((small-mobile, mobile, large-mobile)) {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;
			}
		}

		&__text {
			margin-bottom: rem(8);
			display: none;

			@include device((tablet-up)) {
				display: block;
			}
		}

		&__buttons {
			display: flex;
			gap: rem(16);
			margin-bottom: rem(24);
			justify-content: center;

			@include device((small-mobile, mobile, large-mobile)) {
				margin-bottom: rem(8);

				.btn {
					padding: rem(8 64);
					font-size: rem($px-small);
				}

				.app-store-button,
				.google-play-button {
					svg {
						width: 67px;
					}

					rect {
						width: 65px;
					}

					.app-store-button-text,
					.google-button-text {
						display: none;
					}
				}
			}
		}

		&__bullets-wrapper {
			margin-top: rem(24);
			display: flex;
			justify-content: center;
			grid-area: dots;
		}

		&__bullet {
			border-radius: 50%;
			width: rem(12);
			height: rem(12);
			margin: rem(0 4);
			transition: all 0.2s ease-in;
			border: 1px solid $color-grey-200;
			cursor: pointer;

			&.active {
				border-color: $color-white;
				background: $color-white;
			}
		}

		&__info {
			@include text-note-small();
			color: $color-grey-300;
			margin-bottom: rem(8);
		}

		&__img {
			@include device((small-mobile, mobile, large-mobile, tablet)) {
				img {
					width: 100%;
					height: 220px;
					object-fit: contain;
				}
			}
		}
	}

	.section-banners {
		margin-top: rem(32);

		@include device((tablet-up)) {
			margin-top: 0;
		}

		&__wrapper {
			display: flex;
			gap: rem(16);
		}

		&__pagination {
			margin-top: rem(16);
		}
	}

	.section-wrote {
		&__wrapper {
			display: grid;
			align-items: center;
			justify-content: center;
			gap: rem(8);

			@include device((tablet-up)) {
				grid-template-columns: repeat(3, 1fr);
				padding: rem(50);
			}
		}

		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem(20);
		}
	}

	.section-operators {
		margin-bottom: 0;

		@include device((tablet-up)) {
			padding-top: rem(50);
		}

		&__wrapper {
			overflow: hidden;
			position: relative;
			margin: rem(100 0);
			padding: 0;
		}

		&__content {
			display: flex;
			white-space: nowrap;
			width: fit-content;
			animation: operatorsScroll 140s linear infinite;

			&:hover {
				animation-play-state: paused;
			}
		}

		&__item {
			margin: rem(0 24);
			width: 128px;
			display: flex;
			align-items: center;
			justify-content: center;

			@include device((tablet-up)) {
				margin: rem(0 64);
			}

			img {
				width: 100%;
				filter: grayscale(100%);
			}

			&:hover {
				img {
					filter: none;
				}
			}
		}

		&__description {
			font-family: $font-poppins;
			letter-spacing: 0.32px;
			line-height: 1.1;
		}

		&__info {
			text-align: center;
			font-size: rem($px-small);
			font-weight: $font-light;
			line-height: 1.2;
			color: $color-grey-500;
			padding: rem(0 16 50 16);

			@include device((tablet-up)) {
				padding: rem(0 0 50);
			}
		}
	}

	.section-instruments {
		@include device((small-mobile, mobile, large-mobile)) {
			margin-top: 0;

			.section-home {
				margin-bottom: 0;
			}
		}

		&__left-column {
			.tab {
				&__item {
					@include device((mobile)) {
						flex: 1;
						min-width: unset;

						&--active {
							padding: rem(10);
						}
					}

					path {
						stroke: $color-white;

						&.fill {
							stroke: none;
							fill: $color-white;
						}
					}

					&:hover,
					&--active {
						path {
							stroke: $color-primary-blue-500;

							&.fill {
								stroke: none;
								fill: $color-primary-blue-500;
							}
						}

						@include device((tablet-up)) {
							height: rem(44);
						}
					}

					&--active {
						@include device((tablet-up)) {
							justify-content: left;
							min-width: 150px;
						}
					}

					&--metals {
						svg {
							width: 18px;
							height: 18px;
						}
					}
				}
			}
		}
	}
}

@keyframes operatorsScroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

@keyframes animatedGradient {
	0% {
		background-position: 0 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0 50%;
	}
}

.section-standard {
	&__note {
		@include text-note-small();
		color: $color-grey-500;
		margin-top: rem(8);
		text-align: center;
		margin-bottom: 0;
		display: none;

		@include device((tablet-up)) {
			display: block;
		}
	}

	&__info {
		@include text-note-small();
		color: $color-grey-500;
		margin-top: rem(8);
		text-align: center;
		margin-bottom: 0;

		@include device((tablet-up)) {
			display: none;
		}
	}
}

@import '../alerts';
@import '../faq';

body.page-connect-for-business {
	.section-top {
		&__img {
			padding: rem(25 35);
			max-width: rem(170 + 35 * 2);

			img {
				max-width: 100%;
				max-height: 100%;
			}

			@include device((tablet-up)) {
				padding: rem(35 75);
				max-width: rem(333 + 75 * 2);
			}
		}
	}

	.section-contact-us {
		.box {
			align-items: center;
			text-align: center;

			@include device((tablet-up)) {
				margin: rem(0 200);

				padding-left: rem(100);
				padding-right: rem(100);
			}
		}
	}

	.section-help-center {
		svg {
			min-width: rem(20);
			margin-left: rem(5);
		}

		h2,
		div,
		p {
			text-align: center;

			@include device((tablet-up)) {
				text-align: left;

				@include rightToLeft {
					text-align: right;
				}
			}
		}

		&__content {
			display: grid;

			@include device((tablet-up)) {
				grid-template-columns: 1fr minmax(max-content, auto);
			}
		}
	}

	.section-boxes {
		.box {
			display: flex;
			align-items: flex-start;

			@include device((tablet-up)) {
				max-width: rem(300);
			}

			&__wrapper {
				display: flex;
				justify-content: center;
				margin-top: rem(24);

				@include device((mobile, large-mobile)) {
					flex-direction: column;
				}
			}

			&__title {
				@include headers-h3();
				text-align: left;
			}

			&__content {
				font-family: $font-poppins;
				font-weight: $font-semibold;
				line-height: 120%;
			}
		}
	}

	.section-faq {
		&__title-wrapper {
			display: flex;
			gap: rem(8);
			align-items: center;
		}
	}

	.btn {
		&--qaternary,
		&--tertiary-on-dark {
			display: grid;
			grid-gap: rem(5);
			grid-template-columns: minmax(max-content, auto) 1fr;
		}

		&--tertiary-on-dark {
			svg {
				path {
					stroke: $color-white;
				}
			}
		}
	}
}

.alert {
	position: fixed;
	right: rem(85);
	bottom: rem(35);
	padding: rem(8 16);
	border-radius: rem(8);

	&--success {
		background: $color-lime-green-200;
	}

	&--error {
		background: $color-red-800;
		color: $color-white;
	}
}

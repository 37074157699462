@import '../faq';

body.page-faq {
	.faq {
		&__section {
			@include device((tablet-up)) {
				padding: rem(64 100);
			}

			h2 {
				margin: rem(16 0);
			}
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			gap: rem(36);
			padding: 0;
			margin-top: rem(36);
		}

		&__title-with-icon {
			display: flex;
			align-items: center;
			gap: rem(16);
		}

		&__top-group {
			&:hover,
			&.active {
				border-bottom: 1px solid $color-grey-400;
				box-shadow: none;

				.faq {
					&__group-title {
						.chevron {
							&::before,
							&::after {
								background: $color-dark-blue-900;
							}
						}

						path {
							&.stroke {
								stroke: $color-dark-blue-900;
							}

							&.fill {
								stroke: unset;
								fill: $color-dark-blue-900;
							}
						}
					}
				}
			}
		}

		&__title-group-item {
			line-height: 120%;
			font-weight: $font-medium;
			font-size: rem($px-bigger);
			padding-left: rem(8);
			padding-right: rem(8);

			@include device((tablet-up)) {
				padding-left: rem(16);
				padding-right: rem(16);
			}
		}

		&__item-group {
			&:last-child {
				border-bottom: none;
			}
		}

		&__item-title {
			&.faq__group-title {
				padding: rem(16 16 16 48);

				@include device((tablet-up)) {
					padding: rem(16 64);
				}

				&:not(.active) {
					font-weight: $font-regular;

					&:hover {
						font-weight: $font-semibold;
					}
				}
			}
		}

		&__content {
			&--group {
				&.active {
					max-height: unset !important;
				}
			}

			&--answer {
				padding: rem(0 16 0 48);

				@include device((tablet-up)) {
					padding: rem(0 64);
				}

				&.active {
					padding: rem(8 16 24 48);

					@include device((tablet-up)) {
						padding: rem(8 64 24);
					}
				}
			}
		}
	}

	.search-display-none {
		display: none;
	}
}

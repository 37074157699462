body.page-rollover {
	.section-standard {
		&__img {
			@include device((tablet-up)) {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&--calendar {
				@include device((tablet-up)) {
					align-items: flex-end;
					justify-content: right;
				}
			}
		}

		&__content {
			&--calendar {
				@include device((tablet-up)) {
					padding: rem(50 0 50 64);

					@include rightToLeft {
						padding: rem(50 64 50 0);
					}
				}
			}
		}
	}

	.section-white {
		margin: rem(64 0);

		h2 {
			margin: rem(8 0 24);
		}
	}

	.section-chart {
		@include device((small-mobile, mobile, large-mobile)) {
			.section-row {
				flex-direction: column-reverse;

				&__content {
					text-align: center;
				}
			}
		}
	}

	.section-text {
		margin: rem(64 0);
	}

	.rollover-examples {
		&__wrapper {
			padding: rem(36 16);
			display: flex;
			flex-direction: column;
			gap: rem(40);

			@include device((tablet-up)) {
				padding: rem(36);
				gap: rem(24);
			}
		}

		&__box {
			display: flex;
			gap: rem(24);
			flex-direction: column;

			@include device((tablet-up)) {
				gap: rem(64);
				flex-direction: row;
			}
		}

		&__img {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.rollover-details {
		padding: rem(36);

		&__wrapper {
			margin: rem(24 0);
			display: grid;
			gap: rem(16);

			@include device((tablet-up)) {
				margin: rem(16 0 24);
				grid-template-columns: repeat(3, 1fr);
			}
		}

		&__box {
			padding: rem(24);
		}

		&__img {
			display: flex;
			width: fit-content;
			border-radius: 8px;
			background: $color-white;
			padding: rem(8);
			box-shadow:
				0 2px 4px 0 rgba(16, 24, 40, 0.16),
				0 4px 8px 0 rgba(16, 24, 40, 0.16);
			margin: rem(0 auto 16);
		}

		&__text {
			text-align: center;
			font-size: rem($px-bigger);
			font-family: $font-poppins;
			font-weight: $font-semibold;
			line-height: 120%;
		}
	}
}

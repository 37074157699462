body.page-brand-assets {
	.section-standard {
		margin-bottom: rem(100);
	}

	.section-white {
		margin: rem(16 10 0);
	}

	.section-row {
		gap: rem(24);

		@include device((tablet-up)) {
			gap: rem(64);
		}

		&__img-wrapper {
			width: 100%;

			&--logo {
				border-radius: 8px;
				border: 1px solid $color-dark-blue-900;
				padding: rem(5 10);

				@include device((tablet-up)) {
					border-radius: 16px;
					padding: rem(20 75);
				}

				img {
					vertical-align: middle;
				}
			}
		}

		&__image {
			width: 100%;
		}

		&__buttons {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			gap: rem(16);
		}

		&__button {
			font-size: rem(14);
			padding: rem(8 0);
			width: 100%;

			@include device((tablet-up)) {
				width: rem(250);
			}
		}
	}

	.section-title {
		margin-bottom: 0;
		text-align: center;

		@include device((tablet-up)) {
			text-align: left;
		}
	}

	.section-text {
		margin: rem(24 0);
	}
}

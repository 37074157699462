.cookies-box {
	border-radius: 24px;
	background: $color-white;
	@include shadow-24();
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: rem(24 48);
	gap: rem(64);

	@include device((small-mobile, mobile, large-mobile)) {
		flex-direction: column;
		gap: rem(16);

		&__accept-btn {
			width: 100%;
		}
	}

	&[hidden] {
		display: none;
	}

	&__wrapper {
		position: fixed;
		bottom: rem(36);
		z-index: z-index(cookies);
		width: 100%;
	}

	&__content {
		line-height: 1.4;
	}
}

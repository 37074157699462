.banner {
	&__item {
		text-decoration: none;
		display: block;
		min-width: 285px;
		max-width: 285px;
		height: 220px;
		position: relative;
		overflow: hidden;
		color: $color-white;
		font-family: $font-poppins;
		border-radius: rem(16);
		background-position: top;
		background-size: cover;
		box-shadow:
			0 1px 2px 0 rgba(16, 24, 40, 0.18),
			0 2px 4px 0 rgba(16, 24, 40, 0.18);

		&:hover {
			color: $color-white;
			box-shadow:
				0 12px 24px 6px rgba(16, 24, 40, 0.08),
				0 24px 48px 6px rgba(16, 24, 40, 0.08);
		}

		&:active {
			box-shadow: none;
		}

		&--text-dark {
			color: $color-dark-blue-900;

			&:hover {
				color: $color-dark-blue-900;
			}
		}
	}

	&__text-wrapper {
		width: 150px;
		margin: rem(24 0 24 24);

		@include rightToLeft {
			position: absolute;
			left: 0;
			width: 125px;
		}
	}

	&__title {
		font-size: rem($px-big);
		font-weight: $font-bold;
		margin-bottom: rem(8);
		line-height: 1;
	}

	&__description {
		font-size: rem($px-small);
		line-height: 1.2;
	}
}

.chevron {
	position: relative;
	display: block;
	height: 16px;
	width: 16px;
	cursor: pointer;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		display: block;
		width: 8px;
		height: 1px;
		background: $color-dark-blue-900;
	}

	&::before {
		right: calc(50% - 1px);
		transform: rotate(40deg);
	}

	&::after {
		left: calc(50% - 1px);
		transform: rotate(-40deg);
	}

	&--open {
		transform: rotate(180deg);
	}

	&--left {
		transform: rotate(90deg);
	}

	&--right {
		transform: rotate(270deg);
	}

	&--white {
		&::before,
		&::after {
			background: $color-white;
		}
	}

	&--big {
		min-width: rem(24);
		max-width: rem(24);
		width: rem(24);
		height: rem(24);
	}
}

@import '../faq';
@import '../partials/section-carousel';
@import '../partials/section-features';
@import '../partials/section-legal-documents';
@import '../row-link';

body.page-affiliate {
	.section-content {
		margin: rem(64 0);

		@include device((tablet-up)) {
			margin: rem(100 0);
		}

		&__text {
			margin-bottom: rem(24);
		}
	}

	.affiliate {
		&__table {
			width: 100%;
			margin-top: rem(32);
			border-collapse: collapse;
			border-spacing: 0;

			td {
				padding: rem(5);
				border: 1px solid;
				text-align: center;
			}
		}
	}

	.section-affiliate-system {
		padding: rem(64 16);

		@include device((tablet-up)) {
			padding: rem(64 50);
		}

		&__rows {
			padding: rem(36 0);

			@include device((tablet-up)) {
				margin: rem(36 0);
				padding: rem(0 50);
			}
		}

		&__row {
			display: flex;
			gap: rem(16);

			&:last-of-type {
				.section-affiliate-system__row-description {
					margin-bottom: 0;
				}
			}
		}

		&__row-title {
			margin: rem(0);
			font-size: rem(24);
			line-height: 1.2;
		}

		&__row-description {
			margin-bottom: rem(24);
		}
	}

	.section-standard {
		position: relative;
		margin: rem(0 0 64);

		@include device((tablet-up)) {
			margin: rem(0 0 100);
		}

		&__info-boxes {
			position: absolute;
			top: rem(48);
			left: rem(22);

			@include device((tablet-up)) {
				top: rem(70);
				right: rem(70);
				left: auto;
			}
		}

		&__info-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-transform: uppercase;
			width: rem(200);
			height: rem(110);
			margin-bottom: rem(16);
			background-color: $color-white;
			border-radius: rem(16);
			box-shadow:
				0px 8px 16px 2px rgba(16, 24, 40, 0.1),
				0px 16px 32px 2px rgba(16, 24, 40, 0.1);
			font-family: $font-poppins;
			font-size: rem(12);
			line-height: 1.2;

			&--value {
				background-image: $gradient-blue-green;
				background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-text-fill-color: transparent;
				font-size: rem(55);
				font-weight: $font-semibold;
				line-height: 1;
			}
		}

		&__img {
			&--reverse {
				display: flex;
				justify-content: flex-end;
			}
		}

		&--with-chips {
			.section-standard {
				&__title {
					margin-bottom: rem(24);

					@include device((tablet-up)) {
						margin-bottom: rem(16);
					}
				}

				&__text {
					margin-bottom: rem(48);
				}
			}
		}
	}

	p {
		line-height: 1.4;
	}

	.chips {
		&--with-margin {
			margin: rem(20 0 24);

			@include device((tablet-up)) {
				margin-bottom: rem(16);
			}
		}
	}
}

body.page-help-center {
	.section-standard {
		&__content {
			&--narrow {
				max-width: rem(570);
			}
		}
	}

	.section-row {
		gap: rem(24);

		@include device((tablet-up)) {
			gap: rem(64);
		}

		&__link {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;

			.btn {
				padding: 0;
				font-size: rem(18);

				@include device((tablet-up)) {
					padding: rem(16 0);
				}

				@include device((desktop-up)) {
					padding: rem(16 64);
				}
			}
		}
	}

	.section-content {
		&__text {
			margin-bottom: rem(24);
		}
	}

	.section-white {
		&--center-mobile {
			text-align: center;

			@include device((tablet-up)) {
				text-align: left;
			}
		}
	}

	.section-features {
		&__box {
			max-width: rem(400);
			padding: 0;

			img {
				width: auto;
			}
		}

		&__box-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@include device((desktop-up)) {
				gap: rem(64);
			}
		}

		&__title {
			padding: rem(0 20);
			margin-bottom: 0;
		}

		&__subtitle {
			margin-top: rem(8);
		}
	}

	.related-articles {
		&__wrapper {
			margin-bottom: rem(24);
		}

		&__item-title {
			margin-bottom: rem(32);
		}

		&__text {
			margin-bottom: rem(16);
		}

		&__button-wrapper {
			display: flex;
			justify-content: center;
			margin-bottom: rem(100);
		}
	}

	.box {
		display: flex;
		align-items: center;
		max-width: rem(444);
		padding: rem(50 25);
		text-align: center;

		@include device((tablet-up)) {
			padding: rem(50);
		}

		&__wrapper {
			display: flex;
			justify-content: center;
			margin-top: rem(24);
		}

		&__title {
			width: 100%;
		}

		&__button {
			@include device((small-mobile, mobile)) {
				width: 100%;
			}

			.btn {
				@include device((small-mobile, mobile)) {
					padding: rem(12 0);
					max-width: rem(344);
				}
			}
		}

		&__description {
			flex: 1;
		}
	}

	.faq {
		&__button {
			display: flex;
			justify-content: flex-end;
			margin-top: rem(36);

			.btn {
				font-size: rem($px-small);
			}
		}

		&__wrapper {
			.form__group {
				margin: rem(36 0);
			}
		}
	}

	.search-display-none,
	.display-none {
		display: none;
	}
}

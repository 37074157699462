.section-features {
	&__box-wrapper {
		@include device((tablet-up)) {
			margin: rem(24 0 0);
			display: grid;
			grid-gap: rem(16);
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	&__box {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: rem(16);
		padding: rem(24);

		@include device((tablet-up)) {
			margin-bottom: rem(0);
		}
	}

	&__box-img {
		padding: rem(8);
		border-radius: 8px;
		background: $color-white;
		@include shadow-4();
	}

	&__box-content {
		text-align: center;
	}

	&__title {
		margin: rem(16 0);
		@include headers-h5();
		font-size: rem(24);

		@include device((small-mobile, mobile, large-mobile, tablet)) {
			overflow-wrap: anywhere;
		}
	}
}

.breadcrumb {
	padding: rem(0 0 16);
	margin: 0;
	display: flex;
	list-style-type: none;
	white-space: nowrap;
	font-size: rem($px-extra-small);
	font-family: $font-poppins;
	color: $color-grey-400;

	&__item {
		position: relative;
		display: inline-block;
		line-height: 18px;
		margin-right: 10px;
		padding-right: 10px;

		@include rightToLeft {
			margin-right: unset;
			padding-right: unset;
			margin-left: 10px;
			padding-left: 10px;
		}

		&::before,
		&::after {
			border-right: 2px solid $color-grey-400;
			content: '';
			display: block;
			height: 4px;
			width: 8px;
			position: absolute;
			right: 0;
			top: 6px;

			@include rightToLeft {
				left: 0;
				right: unset;
			}
		}

		&::before {
			transform: skewX(45deg);

			@include rightToLeft {
				transform: scaleX(-1) skewX(45deg);
			}
		}

		&::after {
			bottom: 8px;
			top: auto;
			transform: skewX(-45deg);

			@include rightToLeft {
				transform: scaleX(-1) skewX(-45deg);
			}
		}

		&:last-child {
			&::before,
			&::after {
				display: none;
			}
		}

		a {
			position: relative;
			text-decoration: none;
			color: $color-primary-blue-500;

			&::before {
				position: absolute;
				content: '';
				bottom: -1px;
				left: 0;
				width: 100%;
				height: rem(1);
				background: $color-primary-blue-600;
				transform: scaleX(0);
				transition: 0.2s;
			}

			&:hover {
				color: $color-primary-blue-600;

				&::before {
					transform: scaleX(1);
				}

				svg {
					stroke: $color-primary-blue-600;
				}
			}

			svg {
				stroke: $color-primary-blue-500;
			}
		}
	}
}

/* *
 * GRID
 */
@use 'sass:math';

@for $i from 1 through 24 {
	$width: math.div($i, $grid-columns) * 100%;

	.col-#{$i} {
		min-width: 100%;
		max-width: 100%;

		@include device((tablet-up)) {
			min-width: $width;
			max-width: $width;
		}
	}
}

@import '../partials/platforms';

body.page-platforms {
	h1 {
		margin-bottom: rem(8);
	}

	h2 {
		margin-bottom: rem(16);
	}

	.section-standard {
		&__img {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem(43 16 36);

			@include device((tablet-up)) {
				justify-content: right;
				padding: 0;
			}
		}
	}

	.section-top {
		&__features {
			margin: rem(48 0);
			font-weight: $font-semibold;
			font-family: $font-poppins;
			display: flex;
			flex-direction: column;
			gap: rem(8);

			div {
				line-height: 1.2;
				display: flex;
				align-items: center;

				@include device((tablet-up)) {
					line-height: 2;
				}
			}

			img {
				margin-right: rem(12);
			}
		}

		&__buttons {
			display: flex;
			gap: rem(16);
			flex-wrap: wrap;

			&.wrapped {
				display: grid;
			}

			.btn {
				&--big {
					padding-left: rem(42);
					padding-right: rem(42);
				}
			}
		}
	}

	.box {
		&__img {
			font-size: rem(48);
			font-weight: $font-semibold;
			font-family: $font-poppins;
			line-height: 1.2;
		}

		&__title {
			margin: 0;
			line-height: 1.2;
			min-height: 58px;
		}
	}

	.section-boxes {
		@include device((tablet-up)) {
			margin: rem(24 0);
		}

		&__button-wrapper {
			text-align: center;
		}
	}

	.open-trading-account {
		text-align: center;

		@include device((tablet-up)) {
			text-align: left;
			gap: rem(100);
		}

		&__button-wrapper {
			display: flex;
			align-items: flex-end;
		}
	}
}

.related-articles {
	$parent: &;
	margin: rem(20 0);

	&__wrapper {
		margin-bottom: rem(40);
		display: grid;
		grid-gap: rem(16);

		@include device((tablet-up)) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&__slider-wrapper {
		display: flex;
		gap: rem(16);
	}

	&__pagination {
		margin-top: rem(24);
	}

	&__item {
		border-radius: 8px;
		background: $color-white;
		text-decoration: none;
		display: flex;
		flex-direction: column;
		@include shadow-2();
		transition: all 0.3s ease-out;

		&.search-display-none {
			display: none;
		}

		&:hover,
		&:active {
			#{$parent} {
				&__item-title {
					text-decoration: underline;
				}
			}
		}

		&:hover {
			@include shadow-24();
		}

		&:active {
			box-shadow: none;
		}

		&[target='_blank'] {
			&:hover,
			&:active {
				@include device((small-mobile, mobile, large-mobile)) {
					text-decoration: none;
					@include shadow-2();
				}
			}
		}

		&--width {
			min-width: 285px;
			max-width: 285px;

			@include device((desktop-up)) {
				min-width: 375px;
				max-width: 375px;
			}

			#{$parent} {
				&__img {
					height: 142px;

					@include device((desktop-up)) {
						height: 188px;
					}
				}
			}
		}
	}

	&__img {
		position: relative;
		overflow: hidden;
		height: 205px;

		@include device((desktop-up)) {
			height: 230px;
		}

		img {
			border-radius: 8px 8px 0 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__video-tag {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: url('/assets/images/video-tag.png') center center no-repeat;
		background-size: 77px;
	}

	&__item-content {
		margin: 0;
		flex: 1;
		padding: rem(24);
		display: flex;
		flex-direction: column;
		gap: rem(16);
	}

	&__item-head {
		display: flex;
		flex-direction: column;
		gap: rem(8);
	}

	&__item-title {
		margin: 0;
		font-family: $font-poppins;
		color: $color-dark-blue-900;
		font-size: rem($px-bigger);
		font-style: normal;
		font-weight: $font-semibold;
		line-height: 120%;
		height: 72px;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
		display: -webkit-box;
	}

	&__date {
		color: $color-grey-600;
		font-size: rem($px-extra-small);
		font-weight: $font-light;
		line-height: 120%;
	}

	&__text {
		line-height: 120%;
		color: $color-dark-blue-900;
	}
}

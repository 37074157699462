@import '../partials/related-articles';
@import '../partials/banner';
@import '../partials/lightbox';

body.page-single-tutorial {
	@include device((tablet-up)) {
		overflow-x: hidden;
	}

	.main {
		@include device((tablet-up)) {
			overflow: unset;
		}
	}

	.single-article {
		padding: rem(24 16);
		margin: rem(0 0 100);
		flex: 1;

		@include device((small-mobile, mobile, large-mobile)) {
			margin: rem(0 0 64);

			iframe {
				width: 100%;
			}
		}

		@include device((tablet-up)) {
			padding: rem(50);
		}

		h1 {
			margin: rem(0 0 16);

			@include device((small-mobile, mobile, large-mobile)) {
				font-size: rem(32);
			}
		}

		h2,
		h3 {
			margin: rem(24 0);
		}

		blockquote {
			border-radius: rem(16);
			padding: rem(24);
			background: $color-grey-100;
			margin: 0;

			&.idea {
				background: $color-grey-100
					url('/assets/single-tutorial/idea-icon.png') no-repeat 24px
					center;
				padding-left: rem(82);
				background-size: 36px;
			}
		}

		&__date {
			font-size: rem($px-extra-small);
			color: $color-grey-600;
			margin: rem(16 0 24);
		}

		&__image {
			text-align: center;

			img {
				max-width: 100%;
				border-radius: rem(8);
			}
		}

		&__content {
			padding: rem(16);
			color: $color-grey-600;

			@include device((tablet-up)) {
				padding: rem(50);
			}

			h2,
			h3,
			h4,
			h5 {
				color: $color-dark-blue-900;
			}

			h2 {
				font-size: rem(28);

				@include device((tablet-up)) {
					font-size: rem(32);
				}
			}

			h3 {
				font-size: rem($px-big);

				@include device((tablet-up)) {
					font-size: rem(28);
				}
			}

			h4 {
				font-size: rem(20);

				@include device((tablet-up)) {
					font-size: rem($px-big);
				}
			}

			h5 {
				font-size: rem($px-bigger);
			}

			img {
				border-radius: rem(16);
				max-width: 100%;
				height: auto;
			}

			li {
				margin-bottom: rem(16);
			}

			table {
				margin: auto;
				border-collapse: collapse;

				thead,
				tr:first-child {
					td {
						background: $color-grey-100;
						font-weight: $font-semibold;
					}
				}

				td {
					border: 1px solid $color-grey-100;
					padding: rem(10);
					text-align: center;
				}
			}

			.wp-block-table {
				margin: 0;

				.wp-element-caption {
					text-align: center;
				}
			}

			.wp-block-image {
				img {
					cursor: pointer;
				}
			}

			.wp-element-caption {
				font-size: rem($px-extra-small);
			}

			.wp-block-gallery {
				&.is-layout-flex {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					justify-content: space-around;
					gap: rem(16);
					margin: 0;

					@include device((tablet-up)) {
						flex-direction: row;
					}

					figure {
						flex: 1;
					}
				}

				figure {
					margin: 0;

					figcaption {
						text-align: left;
					}
				}

				figcaption {
					flex-basis: 100%;
					flex-grow: 1;
					text-align: center;
				}
			}

			.wp-block-button {
				&.is-style-fill {
					.wp-block-button {
						&__link {
							@extend %btn;
						}
					}
				}

				&.is-style-outline {
					.wp-block-button {
						&__link {
							@extend %btn;
							@extend %btn--tertiary;
						}
					}

					&.secondary {
						.wp-block-button {
							&__link {
								@extend %btn--secondary;
								border: none;
							}
						}
					}
				}
			}

			.wp-block-pullquote {
				margin: 0;

				blockquote {
					background: url('/assets/single-tutorial/quote-cite.png')
						no-repeat top left;
					margin: rem(16 24);
					text-align: center;
					line-height: 1.4;

					p {
						margin-bottom: rem(24);
					}

					cite {
						font-size: rem($px-small);
						text-align: center;
					}
				}
			}

			.twitter-tweet {
				margin: auto;
			}

			.wp-block-embed {
				margin: auto;
				text-align: center;
			}

			.wp-block-post-template {
				padding: 0;
				margin: 0;
				list-style: none;

				li {
					padding: rem(16 24);
					border-radius: 8px;
					@include shadow-4();
					margin-bottom: rem(8);
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					align-items: flex-start;
					gap: rem(8);

					@include device((tablet-up)) {
						flex-direction: row;
						align-items: center;
						gap: rem(16);
					}

					.wp-block-post-featured-image,
					.wp-block-post-excerpt,
					.wp-block-separator {
						display: none;
					}

					.wp-block-post-featured-image {
						margin: 0;
					}

					.wp-block-post-date {
						font-size: rem($px-extra-small);
						color: $color-grey-600;
						font-weight: $font-light;
						margin: 0;
						line-height: 1.2;
					}

					.wp-block-post-title {
						margin: 0;
						line-height: 1.2;
						font-family: $font-poppins;
						font-size: rem($px-bigger);
						font-weight: $font-semibold;
						text-overflow: ellipsis;
						overflow: hidden;

						a {
							color: $color-dark-blue-900;
							margin: 0;
						}
					}
				}
			}

			.related-articles-image {
				li {
					padding: 0;
					display: flex;
					gap: 0;

					@include device((tablet-up)) {
						padding: rem(0 24 0 0);
						display: grid;
						align-items: center;
						justify-content: flex-start;
						grid-gap: rem(0 24);
						grid-template-areas:
							'image title'
							'image date';
					}

					.wp-block-post-featured-image {
						display: block;
						grid-area: image;

						a {
							display: flex;
						}

						img {
							border-radius: rem(8 0 0 8);
						}

						@include device((small-mobile, mobile, large-mobile)) {
							order: -1;

							img {
								border-radius: rem(8 8 0 0);
							}
						}

						@include device((tablet-up)) {
							max-width: 200px;
						}
					}

					.wp-block-post-date {
						grid-area: date;
						padding: rem(0 24 24);

						@include device((tablet-up)) {
							padding: rem(0 0 10);
						}
					}

					.wp-block-post-title {
						grid-area: title;
						padding: rem(24 24 8);

						@include device((tablet-up)) {
							padding: rem(10 0 0);
						}
					}
				}
			}

			[data-tooltip] {
				cursor: help;
				position: relative;
				border-bottom: 1px dashed $color-primary-blue-800;
				color: $color-primary-blue-800;

				&:before {
					content: attr(data-tooltip);
					position: absolute;
					opacity: 0;
					transition: 0.3s opacity;
					top: 20px;
					left: 20px;
					width: rem(200);
					padding: rem(24);
					display: none;
					border-radius: rem(0 16 16 16);
					background: $color-white;
					box-shadow:
						0 4px 8px 0 rgba(16, 24, 40, 0.14),
						0 8px 16px 0 rgba(16, 24, 40, 0.14);
					backdrop-filter: blur(2px);
					font-size: rem($px-small);
					color: $color-grey-600;
					line-height: 1.2;

					@include device((tablet-up)) {
						top: 0;
						left: 50%;
						width: rem(300);
						transform: translateY(-100%);
						border-radius: rem(16 16 16 0);
					}
				}

				&:hover {
					&:before {
						display: block;
						opacity: 1;
					}
				}
			}
		}

		&__related-title {
			font-family: $font-poppins;
			font-size: rem(40);
			font-weight: $font-semibold;
			letter-spacing: 0.4px;
			margin: 0;
			text-align: center;
			line-height: 1.2;
		}

		&__introduction {
			font-size: rem($px-big);
			font-weight: $font-semibold;
			line-height: 1.4;

			blockquote {
				padding: rem(16);
				border-radius: rem(8);
				font-size: rem($px-normal);
				font-weight: $font-medium;
			}
		}

		&__columns {
			position: relative;

			@include device((tablet-up)) {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-direction: row;
				gap: 41px;
			}
		}

		&__box-column {
			@include device((tablet-up)) {
				position: sticky;
				top: calc(
					#{$header-disclaimer-height} + #{$header-height} + 53px
				); // 53 - breadcrumbs
				margin-bottom: 100px;
			}
		}

		&__box-column,
		&__box-column-placeholder {
			@include device((tablet-up)) {
				min-width: rem(320);
				max-width: rem(320);
			}
		}

		&__box {
			border-radius: 24px;
			background: $color-grey-25;
			padding: rem(36 24);
			box-shadow:
				0px 12px 24px 6px rgba(16, 24, 40, 0.08),
				0px 24px 48px 6px rgba(16, 24, 40, 0.08);
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			gap: 36px;

			&--signup {
				display: grid;
				grid-gap: rem(24);
			}
		}

		&__table-of-contents {
			display: none;
			margin-bottom: rem(24);

			@include device((tablet-up)) {
				display: flex;
			}

			ul {
				margin: 0;
				padding: 0;
				text-align: left;
				list-style-type: none;

				li {
					margin-bottom: rem(36);
					position: relative;

					&:last-of-type {
						margin-bottom: 0;
					}
				}

				a {
					font-family: $font-poppins;
					color: $color-grey-600;
					text-decoration: none;
					background: url('/assets/single-tutorial/arrow.png')
						no-repeat left center;
					padding-left: rem(26);
					display: block;

					&.active,
					&:hover {
						color: $color-dark-blue-900;
						font-weight: $font-semibold;
						background: url('/assets/single-tutorial/arrow-hover.png')
							no-repeat left center;
					}
				}
			}
		}

		&__related-articles {
			margin-top: rem(64);

			@include device((tablet-up)) {
				margin-top: 0;
			}
		}

		&__pagination-wrapper {
			@include device((tablet-up)) {
				display: flex;
				gap: 41px;

				.related-articles {
					&__pagination {
						margin-top: 0;
					}
				}
			}
		}

		&__related-title-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: rem(24);
			flex: 1;

			@include device((small-mobile, mobile, large-mobile)) {
				font-size: rem(32);
				justify-content: center;

				.related-articles {
					&__pagination {
						display: none;
					}
				}
			}
		}

		&__social-icons-wrapper {
			display: grid;
			grid-gap: rem(8);
			font-size: rem($px-extra-small);
			font-weight: $font-light;
			line-height: 1.2;
		}

		&__social-icons {
			display: flex;
			gap: rem(8);
			align-items: center;
			justify-content: center;

			span {
				cursor: pointer;

				&:hover {
					rect {
						stroke: $color-grey-600;
					}
				}

				&:active {
					rect {
						stroke: $color-primary-blue-100;
					}
				}
			}
		}

		&__bo-banner {
			min-width: auto;
			max-width: none;
			width: fit-content;
			height: unset;
			position: relative;
			line-height: 1;

			&:is(div) {
				box-shadow: none;
			}

			img {
				vertical-align: top;
			}

			.banner {
				&__text-wrapper {
					position: absolute;
					top: 0;
					width: 50%;
				}
			}
		}
	}
}
